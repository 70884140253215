/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { BranchSection } from "./BranchSection";
import { loadWeeklyReportData } from "../../../store/WeeklyReport/actions";
import { setLoading } from "../../../store/Filter/actions";
import { handleExport } from "../../../utils/helper/dataExport";
import {
  formatCell,
  formatPercentage,
} from "../../../utils/helper/cellFormatter";

export const SelectedReport = (props: any) => {
  const { exportData, selectedLevel } = props;
  const type = useSelector(
    (state: any) => state["filterReducer"].selectedAreaType
  );
  const weeklyData = useSelector(
    (state: any) => state.weeklyReportReducer.weeklyData
  );
  const week = useSelector((state: any) => state.filterReducer.weekSelected);
  const dispatch = useDispatch();
  const selectedRegion = useSelector(
    (state: any) => state.filterReducer.regionSelected
  );
  const selectedDistrict = useSelector(
    (state: any) => state.filterReducer.districtSelected
  );

  const firstHeaders = useSelector(
    (state: any) => state["filterReducer"].firstHeaders
  );
  const secondHeaders = useSelector(
    (state: any) => state["filterReducer"].secondHeaders
  );
  const thirdHeaders = useSelector(
    (state: any) => state["filterReducer"].thirdHeaders
  );

  const exportHeader = [
    "Weekly Sales",
    ...firstHeaders,
    ...secondHeaders,
    ...thirdHeaders,
    type !== "All" ? "Manager" : "",
  ];

  const getExportData = () => {
    let weeklyExportData: any[] = [];

    Object.keys(weeklyData).forEach((key: any) => {
      let allTotalData: any = [];
      weeklyData[key].first.map((v: any, idx: number) => {
        const firstData = firstHeaders.map((header: any) => {
          return { [header]: formatCell(idx, v[header]) };
        });
        const secondData = weeklyData[key].second[idx]
          ? secondHeaders.map((header: any) => {
              const value = weeklyData[key].second[idx][header];
              return { [header]: formatCell(key, value) };
            })
          : [];
        const thirdData = weeklyData[key].third[idx]
          ? thirdHeaders.map((header: any) => {
              const value = weeklyData[key].third[idx][header];
              return { [header]: formatPercentage(value) };
            })
          : [];

        const managerData =
          type !== "All" && weeklyData[key]?.manager[idx]
            ? [{ Manager: weeklyData[key].manager[idx].Manager }]
            : [{ Manager: "" }];

        const totalData = [
          ...firstData,
          ...secondData,
          ...thirdData,
          ...managerData,
        ];
        allTotalData.push(totalData);
      });
      const title: string[] = [];
      const name: string[] = [];

      name.push(key);
      weeklyData[key].first.map((item: any) => {
        title.push(item.title);
      });

      weeklyExportData.push({ "Weekly Sales": key });
      title.forEach((title: string, index: number) => {
        const totalData = allTotalData[index];
        weeklyExportData.push({
          "Weekly Sales": title,
          ...Object.fromEntries(totalData.map(Object.entries).flat()),
        });
      });
    });
    return weeklyExportData;
  };

  useEffect(() => {
    if (exportData) {
      handleExport(
        exportHeader,
        getExportData(),
        "xlsx",
        type === "Region" && selectedRegion !== "All"
          ? `WeeklyReportBy${type}-${week}-${selectedRegion}`
          : type === "District" && selectedDistrict !== "All"
          ? `WeeklyReportBy${type}-${week}-${selectedDistrict}`
          : `WeeklyReportBy${type === "All" ? "Company" : type}-${week}`
      );
    }
  }, [exportData]);

  useEffect(() => {
    if (week !== "") {
      loadReportData();
    }
  }, [type, week, selectedLevel]);

  useEffect(() => {
    if (type === "Region" || type === "District") {
      loadReportData();
    }
  }, [selectedRegion, selectedDistrict]);

  const loadReportData = async () => {
    dispatch(setLoading(true));

    if (
      selectedLevel === "Company" &&
      type === "Region" &&
      selectedRegion !== "All"
    ) {
      await dispatch(
        loadWeeklyReportData(
          {
            type: "Branch",
            week: week,
          },
          selectedLevel,
          { region: selectedRegion }
        )
      );
    } else if (
      selectedLevel === "Company" &&
      type === "District" &&
      selectedDistrict !== "All"
    ) {
      await dispatch(
        loadWeeklyReportData(
          {
            type: "Branch",
            week: week,
          },
          selectedLevel,
          { district: selectedDistrict }
        )
      );
    } else {
      await dispatch(
        loadWeeklyReportData(
          {
            type: type,
            week: week,
          },
          selectedLevel
        )
      );
    }

    dispatch(setLoading(false));
  };

  return (
    <section className={"report-section relative"}>
      <div id="vertical-line"></div>
      {Object.keys(weeklyData).map((v, key) => (
        <BranchSection key={key} data={weeklyData[v]} title={v} />
      ))}
    </section>
  );
};
