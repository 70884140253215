/*
 * Copyright (C) 2023 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import logo from "../assets/images/logo.png";
import { Link } from "react-router-dom";
import "../assets/scss/Landing.scss";
import { REPORT_NAMES } from "../utils/helper/constants";

export const Landing = ({ loading, reports }: any) => {
  const adaAccess = reports.find(
    (report: any) => report.ReportName === REPORT_NAMES.ADASTAFF_INC
  );

  return loading ? (
    <>LOADING....</>
  ) : (
    <main>
      <section className={"landing-screen"}>
        <div className="logo-area">
          <img
            src={logo}
            alt=""
            style={{ aspectRatio: "2382 / 880", width: "60%" }}
          />
        </div>
        <div className="menu-area">
          <div className="menu-items">
            {reports
              .filter(
                (r: any) =>
                  r.ReportName !== REPORT_NAMES.ADASTAFF_INC &&
                  r.ReportName !== REPORT_NAMES.ASCEND_ADA_REPORTS
              )
              ?.map((v: any, key: any) => (
                <div className={"flex align-center"} key={key}>
                  <img
                    src={require(`../assets/images/${v.ReportIconName}.svg`)}
                    alt=""
                    height={30}
                    width={30}
                    style={{
                      objectFit: "contain",
                      objectPosition: "center",
                      marginRight: "4px",
                    }}
                  />
                  <Link
                    className="landing-menu"
                    to={adaAccess ? v.ReportName + "-ada" : v.ReportName}
                  >
                    {v.ReportDisplayName}
                  </Link>
                </div>
              ))}
          </div>
        </div>
      </section>
    </main>
  );
};
