/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { useEffect, useState } from "react";
import { groupByKey } from "../../../utils/group";
import {
  APIGetADABranches,
  APIGetCompanySummaryRegions,
} from "../../../api/company-summary";
import { Menu, SegmentedControl, Select, Switch } from "@mantine/core";
import { useDispatch, useSelector } from "react-redux";
import {
  setEnableTooltip,
  setShowBranchClientsRanking,
  setShowClientsRanking,
  setShowPipSummaryLines,
} from "../../../store/CompanySummary/actions";
import { APIGetAllADABranches } from "../../../api/filter";
import moment from "moment";
import { sortOptions } from "./utils/constants";

const getHierarchy = (data: any) => {
  let subAreaHierarchy = {};
  Object.keys(data).forEach((v: any) => {
    let temp = groupByKey(data[v], "Sub Area Name");
    let subHier = {};
    for (let v in temp) {
      subHier = {
        ...subHier,
        [v]: Object.values(temp[v]).map((v: any) => ({
          District: v.District,
          Region: v.Region,
          SubAreaName: v["Sub Area Name"],
          Area: v.Areaname,
        })),
      };
    }
    subAreaHierarchy = {
      ...subAreaHierarchy,
      [v]: subHier,
    };
  });
  return subAreaHierarchy;
};

export const CompanySummarySelector = ({
  sortValue,
  setSortValue,
  selectedBranch,
  setSelectedBranch,
  dataMode,
  setDataMode,
  level,
  setLevel,
  hierarchy,
  setHierarchy,
  districtList,
  setDistrictList,
  regionsList,
  setRegionsList,
  areaList,
  setAreaList,
  areaSelected,
  setAreaSelected,
  setTotalType,
  subAreaList,
  setSubAreaList,
  subAreaSelected,
  setSubAreaSelected,
  setBranchesList,
  sameMaxValue,
  setSameMaxValue,
  showHouston,
  setShowHouston,
  clientsDataMode,
  setClientsDataMode,
  clientNumber,
  setClientNumber,
  clientYear,
  setClientYear,
  wip,
  adaOnly,
  isAdaAccess,
  executive,
  sameClientRatio,
  setSameClientRatio,
}: any) => {
  const dispatch = useDispatch();
  const [rawData, setRawData] = useState([]);
  const [showAllRegions, setShowAllRegions] = useState<string | null>("");
  const [showDistricts, setShowDistricts] = useState<string | null>("");
  const [showUngroupedRegions, setShowUngroupedRegions] =
    useState<boolean>(false);
  const [regionSelected, setRegionSelected] = useState<string | null>(null);
  const [selectedSortOption, setSelectedSortOption] = useState<any>(
    sortOptions[3]
  );
  const enableTooltip = useSelector(
    (state: any) => state.companySummaryReducer.enableTooltip
  );
  const showClientsRanking = useSelector(
    (state: any) => state.companySummaryReducer.showClientsRanking
  );
  const showBranchClientsRanking = useSelector(
    (state: any) => state.companySummaryReducer.showBranchClientsRanking
  );
  const showPipSummaryLines = useSelector(
    (state: any) => state.companySummaryReducer.showPipSummaryLines
  );

  const loadRegions = async () => {
    const res: any = adaOnly
      ? await APIGetAllADABranches()
      : await APIGetCompanySummaryRegions();
    const resWithFixesSubArea = res.data.map((v: any) => ({
      ...v,
      "Sub Area Name": v["Sub Area Name"] ?? "Ungrouped",
    }));
    setRawData(resWithFixesSubArea);
    const regionTemp = Object.values(
      groupByKey(resWithFixesSubArea, "Region")
    ).map((v: any) => ({
      Region: v[0].Region,
      AreaName: v[0]["Areaname"],
      SubAreaName: v[0]["Sub Area Name"],
    }));
    const districtTemp = Object.values(
      groupByKey(resWithFixesSubArea, "District")
    ).map((v: any) => ({
      District: v[0].District,
      Region: v[0].Region,
      AreaName: v[0].Areaname,
      SubAreaName: v[0]["Sub Area Name"],
    }));
    const hierarchyTemp = groupByKey(resWithFixesSubArea, "Areaname");
    let subAreaHierarchy = getHierarchy(hierarchyTemp);
    setHierarchy(subAreaHierarchy);
    const areaTemp: any =
      Object.keys(hierarchyTemp).filter(
        (d: any) => d !== "null" && d !== null
      ) ?? [];
    setAreaList(areaTemp);
    setSubAreaList(
      Object.keys(groupByKey(resWithFixesSubArea, "Sub Area Name"))
    );
    setRegionsList(regionTemp ?? []);
    setDistrictList(districtTemp ?? []);
    setBranchesList(res.data.map((v: any) => v.Branch));
  };

  useEffect(() => {
    loadRegions();
  }, []);

  useEffect(() => {
    if (sortValue) {
      setSelectedSortOption(sortOptions.find((s) => s.value === sortValue));
    }
  }, [sortValue]);

  useEffect(() => {
    if (level === "Company" && selectedBranch === "Company") setCompanyLevel();
  }, [level, selectedBranch]);

  const setBranch = (level: string, branch: any) => {
    setLevel(level);
    setTotalType(branch.Title === "Subarea" ? "VP" : branch.Title);
    setSelectedBranch(branch.Location);
    dispatch(setShowClientsRanking(false));
    dispatch(setShowBranchClientsRanking(false));
  };

  const setAreaLevel = (branch: string) => {
    setSubAreaSelected(null);
    setRegionSelected(null);
    setShowUngroupedRegions(true);
    setShowAllRegions(null);
    setShowDistricts(null);
    setAreaSelected(branch);
    setLevel("Area");
    setBranch("Area", { Location: branch, Title: "Area" });
    setSubAreaList(
      Object.keys(hierarchy[branch]).filter((v) => v !== "Ungrouped")
    );
    setRegionsList(
      Object.values(
        groupByKey(Object.values(hierarchy[branch]).flat(), "Region")
      )
        .filter((v: any) => v[0].SubAreaName === "Ungrouped")
        .map((v: any) => ({
          Region: v[0].Region,
          AreaName: v[0].Area,
          SubAreaName: v[0].SubAreaName,
        })) ?? []
    );
    setDistrictList(
      Object.values(
        groupByKey(Object.values(hierarchy[branch]).flat(), "District")
      )
        .filter((v: any) => v[0].SubAreaName === "Ungrouped")
        .map((v: any) => ({
          District: v[0].District,
          Region: v[0].Region,
          AreaName: v[0].Area,
          SubAreaName: v[0].SubAreaName,
        })) ?? []
    );
    setBranchesList(
      rawData.filter((v) => v["Areaname"] === branch).map((v: any) => v.Branch)
    );
  };

  const setSubareaLevel = (branch: string) => {
    setSubAreaSelected(branch);
    setRegionSelected(null);
    setShowDistricts(null);
    setShowUngroupedRegions(false);
    setShowAllRegions(branch);
    setBranch("Subarea", { Location: branch, Title: "Subarea" });
    setRegionsList(
      Object.values(
        groupByKey(
          rawData.filter((v: any) => v["Sub Area Name"] === branch),
          "Region"
        )
      ).map((v: any) => ({
        Region: v[0].Region,
        AreaName: v[0].Areaname,
        SubAreaName: v[0]["Sub Area Name"],
      })) ?? []
    );
    setDistrictList(
      Object.values(
        groupByKey(
          rawData.filter((v: any) => v["Sub Area Name"] === branch),
          "District"
        )
      )
        .filter((v: any) => v[0].SubAreaName === "Ungrouped")
        .map((v: any) => ({
          District: v[0].District,
          Region: v[0].Region,
          AreaName: v[0].Areaname,
          SubAreaName: v[0]["Sub Area Name"],
        })) ?? []
    );
    setBranchesList(
      rawData
        .filter((v) => v["Sub Area Name"] === branch)
        .map((v: any) => v.Branch)
    );
  };

  const setRegionLevel = (branch: any) => {
    setRegionSelected(branch.Region);
    setSubAreaSelected(branch.SubAreaName);
    setLevel("Region");
    setDistrictList(
      Object.values(
        groupByKey(
          rawData.filter((v: any) => v.Region === branch.Region),
          "District"
        )
      ).map((v: any) => ({
        District: v[0].District,
        Region: v[0].Region,
        AreaName: v[0].Areaname,
        SubAreaName: v[0]["Sub Area Name"],
      })) ?? []
    );
    setBranch("Region", { Location: branch.Region, Title: "Region" });
    setBranchesList(
      rawData
        .filter((v) => v["Region"] === branch.Region)
        .map((v: any) => v.Branch)
    );
  };

  const setDistrictLevel = (branch: any) => {
    setLevel("District");
    const districtTemp: any = rawData.find(
      (v: any) => v.District === branch.District
    );
    setRegionSelected(districtTemp ? districtTemp.Region : "");
    setSubAreaSelected(districtTemp ? districtTemp["Sub Area Name"] : "");
    setBranch("District", { Location: branch.District, Title: "Area Branch" });
    setBranchesList(
      rawData
        .filter((v) => v["District"] === branch.District)
        .map((v: any) => v.Branch)
    );
  };

  const setCompanyLevel = () => {
    setShowAllRegions("Company");
    setShowDistricts("Company");
    setSubAreaSelected(null);
    setRegionSelected(null);
    setShowUngroupedRegions(false);
    setLevel("Company");
    setBranch("Company", { Location: "Company", Title: "Company" });
    setSubAreaList(Object.keys(groupByKey(rawData, "Sub Area Name")));
    setRegionsList(
      Object.values(groupByKey(rawData, "Region")).map((v: any) => ({
        Region: v[0].Region,
        AreaName: v[0].Areaname,
        SubAreaName: v[0]["Sub Area Name"],
      }))
    );
    setDistrictList(
      Object.values(groupByKey(rawData, "District")).map((v: any) => ({
        District: v[0].District,
        Region: v[0].Region,
        AreaName: v[0].Area,
        SubAreaName: v[0].SubAreaName,
      })) ?? []
    );
    setBranchesList(rawData.map((v: any) => v.Branch));
  };

  const setCompanyLevelADA = async () => {
    setShowAllRegions(null);
    setShowDistricts(null);
    setSubAreaSelected(null);
    setRegionSelected(null);
    setShowUngroupedRegions(false);
    setLevel("ADA");
    setBranch("ADA", { Location: "ADA_Branch", Title: "ADASTAFF, Inc." });
    setSubAreaList([]);
    setRegionsList([]);
    setDistrictList([]);
    const adaBranchList = await APIGetAllADABranches();
    setBranchesList(
      adaBranchList.data.map((d: { Branch: string }) => d.Branch)
    );
  };

  const setSelectedOption = (e: any) => {
    setSortValue(e.target.value);
  };

  const setShowPipSummary = (event: any) => {
    dispatch(setShowPipSummaryLines(event.target.checked));
  };
  const years = Array.from({ length: 4 }, (_, i) =>
    (moment().year() - i).toString()
  );

  // useEffect(() => {
  //   if (dataMode !== "Revenue") dispatch(setShowPipSummaryLines(false));
  // }, [dataMode]);

  return (
    <div className="branches-section relative">
      <div className="absolute top-0 left-[10px]">
        <div className="flex items-center gap-1 border border-[#01B8AA] p-1 xl:py-2 xl:pl-2 xl:pr-10 text-xs w-[190px] lg:w-full">
          <i className="material-icons">info</i>
          <span>The charts are sorted {selectedSortOption.infoValue}</span>
        </div>
        {showPipSummaryLines && (
          <div className="flex flex-col gap-1 text-sm font-bold absolute mr-5 mt-2 xl:mt-5">
            {/* <div className="flex items-center w-52 justify-between">
              New Branch: <span className="h-5 w-20 bg-tertiary"></span>
            </div>
            <div className="flex items-center w-52 justify-between">
              Legacy Branch: <span className="h-5 w-20 bg-tertiary"></span>
            </div> */}
            <div className="flex items-center gap-2 2xl:w-52 text-xs justify-between">
              90 Days On PIP:{" "}
              <span className="h-5 w-10 2xl:w-20 bg-[#5696c3]"></span>
            </div>
          </div>
        )}
      </div>

      {!(showClientsRanking || showBranchClientsRanking) && (
        <div className="absolute top-48 xl:top-0 right-0  sort-by-box  flex flex-row xl:flex-col xl:gap-1 gap-4 items-start xl:items-end justify-end w-full xl:max-2xl:w-[22%] 2xl:max-w-[30%]  ">
          <div className="w-[250px] xl:text-right flex flex-col gap-1 justify-end items-end">
            <div className="text-xs font-bold">Sort By:</div>
            <select
              name="sortBy"
              className="text-xs px-2 py-1 w-full"
              onChange={setSelectedOption}
              value={sortValue}
            >
              {sortOptions.map((option, i) => {
                return (
                  <option key={i} value={option.value}>
                    {option.name}
                  </option>
                );
              })}
            </select>
            <div>
              <div className="data-mode-selector-box">
                <div
                  className={`data-mode-selector cursor-pointer hover:brightness-75 ${
                    dataMode === "Revenue" ? "active" : ""
                  }`}
                  onClick={() => setDataMode("Revenue")}
                >
                  Revenue
                </div>
                <div
                  className={`data-mode-selector min-w-[70px] ${
                    dataMode === "GrossProfit" ? "active" : ""
                  } ${
                    sameMaxValue
                      ? "cursor-text brightness-90"
                      : "cursor-pointer hover:brightness-75"
                  }`}
                  onClick={() => {
                    !sameMaxValue && setDataMode("GrossProfit");
                  }}
                >
                  Gross Profit
                </div>
                {/* {!(wip && executive) && (
                <div
                  className={`data-mode-selector ${
                    dataMode === "NetIncome" ? "active" : ""
                  }`}
                  onClick={() => setDataMode("NetIncome")}
                >
                  Net Income
                </div>
              )} */}
                {/* {wip && executive && ( */}
                {sameMaxValue ? (
                  <div
                    className={`data-mode-selector min-w-[50px] brightness-90`}
                  >
                    Income
                  </div>
                ) : (
                  <Menu position="bottom-end">
                    <Menu.Target>
                      <div
                        className={`data-mode-selector cursor-pointer hover:brightness-75 ${
                          dataMode === "NetIncome" ||
                          dataMode === "OperatingIncome"
                            ? "active"
                            : ""
                        }`}
                      >
                        {dataMode === "NetIncome" ||
                        dataMode === "OperatingIncome"
                          ? dataMode
                          : "Income"}
                      </div>
                    </Menu.Target>
                    <Menu.Dropdown>
                      <Menu.Item
                        className={
                          dataMode === "NetIncome" ? "active-income" : ""
                        }
                        onClick={() =>
                          !sameMaxValue && setDataMode("NetIncome")
                        }
                      >
                        Net Income
                      </Menu.Item>
                      <Menu.Item
                        className={
                          dataMode === "OperatingIncome" ? "active-income" : ""
                        }
                        onClick={() =>
                          !sameMaxValue && setDataMode("OperatingIncome")
                        }
                      >
                        Operating Income
                      </Menu.Item>
                    </Menu.Dropdown>
                  </Menu>
                )}

                {/* )} */}
              </div>
            </div>
          </div>

          <div className="branch-legend-area  self-end flex-wrap w-full ">
            <div className="legend-box">
              <div className="color-box revenue"></div>
              <div className="name-box">Projected Revenue</div>
            </div>
            <div className="legend-box">
              <div className="color-box oi"></div>
              <div className="name-box">OI%</div>
            </div>
            <div className="legend-box">
              <div className="color-box trailing"></div>
              <div className="name-box">
                {sortValue === "TWGPRank"
                  ? "Trailing 4 Weeks GP"
                  : "Trailing 4 Weeks Revenue"}
              </div>
            </div>
            {/* <div className="legend-box">
              <div className="color-box ppm"></div>
              <div className="name-box">PPM</div>
            </div> */}
            <div className="legend-box">
              <div className="color-box percentage"></div>
              <div className="name-box">Week Over Week %</div>
            </div>
            <div className="legend-box">
              <div className="color-box percentage2"></div>
              <div className="name-box">% Over Last Year</div>
            </div>
          </div>
          <div className="mt-1 flex justify-end gap-2">
            <Switch
              size="xs"
              label="Show Tooltip for Chart"
              checked={enableTooltip}
              onChange={(event: any) =>
                dispatch(setEnableTooltip(event.currentTarget.checked))
              }
            />
            <Switch
              size="xs"
              label="Set same aspect ratio"
              checked={sameMaxValue}
              onChange={(event: any) =>
                setSameMaxValue(event.currentTarget.checked)
              }
              disabled={dataMode !== "Revenue"}
            />
          </div>
          <div className="mt-2 flex justify-end gap-3">
            {level === "Company" && (
              <Switch
                size="xs"
                label="Show Houston CPI/PSG"
                checked={showHouston}
                onChange={(event: any) =>
                  setShowHouston(event.currentTarget.checked)
                }
              />
            )}
            {executive && dataMode === "Revenue" && (
              <Switch
                size="xs"
                label="Show PIP Summary Lines"
                checked={showPipSummaryLines}
                onChange={setShowPipSummary}
              />
            )}
          </div>
        </div>
      )}
      {(showClientsRanking || showBranchClientsRanking) && (
        <div className="absolute top-0 right-0  sort-by-box flex flex-col gap-2 items-end">
          <div className="w-20">
            <Select
              size="xs"
              value={clientYear}
              onChange={setClientYear}
              data={years}
            />
          </div>
          <div>
            <SegmentedControl
              color="blue"
              size="xs"
              value={clientNumber}
              onChange={setClientNumber}
              data={
                selectedBranch === "Company"
                  ? [
                      { label: "Top 5", value: "5" },
                      { label: "Top 10", value: "10" },
                      { label: "Top 20", value: "20" },
                    ]
                  : [
                      { label: "Top 5", value: "5" },
                      { label: "Top 10", value: "10" },
                    ]
              }
            />
          </div>
          <div>
            <SegmentedControl
              color="cyan"
              size="xs"
              value={clientsDataMode}
              onChange={(value) => {
                setDataMode(value);
                setClientsDataMode(value);
              }}
              data={[
                { label: "Gross Profit", value: "GrossProfit" },
                { label: "Revenue", value: "Revenue" },
              ]}
              // disabled={showPipSummaryLines}
            />
          </div>
          {executive && (
            <Switch
              size="xs"
              label="Set same aspect ratio"
              checked={sameClientRatio}
              onChange={(event: any) =>
                setSameClientRatio(event.currentTarget.checked)
              }
            />
          )}
          <div className="flex items-center border-[1px] h-7 border-stone-400  gap-1 py-1 px-2">
            {" "}
            <i className="material-icons text-[1.2rem]">star_border</i>
            <span className="text-[0.5rem] lg:text-[0.7rem] font-semibold">
              VMS Clients
            </span>
          </div>
        </div>
      )}

      <div
        className={`top-hierarchy flex justify-center ${
          isAdaAccess || executive || wip ? "-ml-[5.9rem]" : ""
        }`}
      >
        {(isAdaAccess || adaOnly || executive || wip) && (
          <div
            className={`branch-menu ${
              selectedBranch === "ADA_Branch" ? "active" : ""
            }`}
            onClick={() => setCompanyLevelADA()}
          >
            ADASTAFF, Inc.
          </div>
        )}

        {!adaOnly && (
          <div
            className={`branch-menu ${
              selectedBranch === "Company" ? "active" : ""
            }`}
            onClick={() => setCompanyLevel()}
          >
            Ascend Staffing
          </div>
        )}
      </div>
      {!adaOnly && (
        <>
          <div className="mid-hierarchy flex justify-center">
            {level !== "ADA" &&
              areaList.map(
                (v: any, key: number) =>
                  v && (
                    <div
                      key={key}
                      className={`branch-menu ${
                        areaSelected === v.Location ? "bg-gray " : ""
                      } ${selectedBranch === v ? "active" : ""}`}
                      onClick={() => {
                        setAreaLevel(v);
                      }}
                    >
                      {v}
                    </div>
                  )
              )}
          </div>
          {subAreaList.length > 0 && (
            <div className="mid-hierarchy flex justify-center">
              {level !== "ADA" &&
                subAreaList.map(
                  (v: any, key: number) =>
                    v &&
                    v !== "Ungrouped" && (
                      <div
                        key={key}
                        className={`branch-menu ${
                          subAreaSelected === v ? "bg-gray-500 " : ""
                        } ${selectedBranch === v ? "active" : ""}`}
                        onClick={() => {
                          setSubareaLevel(v);
                        }}
                      >
                        {v}
                      </div>
                    )
                )}
              {showUngroupedRegions &&
                regionsList
                  .filter((region: any) => region.SubAreaName === "Ungrouped")
                  .map(
                    (v: any, key: any) =>
                      v.Region && (
                        <div
                          key={key}
                          className={`branch-menu ${
                            selectedBranch === v.Region ? "active" : ""
                          }`}
                          onClick={() => {
                            setRegionLevel(v);
                            setShowDistricts(null);
                            setShowAllRegions(null);
                          }}
                        >
                          {v.Region}
                        </div>
                      )
                  )}
            </div>
          )}
          <div className="low-hierarchy flex justify-center">
            {showAllRegions &&
              regionsList
                .filter(
                  (region: any) =>
                    region.SubAreaName !== "Ungrouped" &&
                    (!subAreaSelected || region.SubAreaName === subAreaSelected)
                )

                .map(
                  (v: any, key: any) =>
                    v.Region && (
                      <div
                        key={key}
                        className={`branch-menu ${
                          selectedBranch === v.Region ? "active" : ""
                        }`}
                        onClick={() => {
                          setRegionLevel(v);
                          setShowDistricts(v.Region);
                          setShowUngroupedRegions(false);
                        }}
                      >
                        {v.Region}
                      </div>
                    )
                )}
            {showAllRegions &&
              regionsList
                .filter(
                  (region: any) =>
                    region.SubAreaName === "Ungrouped" &&
                    (!subAreaSelected || region.SubAreaName === subAreaSelected)
                )

                .map(
                  (v: any, key: any) =>
                    v.Region && (
                      <div
                        key={key}
                        className={`branch-menu ${
                          selectedBranch === v.Region ? "active" : ""
                        }`}
                        onClick={() => {
                          setRegionLevel(v);
                          setShowUngroupedRegions(
                            v.SubAreaName === "Ungrouped" ? true : false
                          );
                          setShowAllRegions(
                            v.SubAreaName === "Ungrouped" ? null : v.Region
                          );
                        }}
                      >
                        {v.Region}
                      </div>
                    )
                )}
          </div>
          <div className="lowest-hierarchy flex justify-center">
            {showDistricts &&
              districtList
                .filter((v: any) =>
                  regionSelected ? v.Region === regionSelected : true
                )
                .map(
                  (v: any, key: any) =>
                    v.District && (
                      <div
                        key={key}
                        className={`branch-menu ${
                          selectedBranch === v.District ? "active" : ""
                        }`}
                        onClick={() => {
                          setDistrictLevel(v);
                        }}
                      >
                        {v.District}
                      </div>
                    )
                )}
          </div>
        </>
      )}
    </div>
  );
};
