import { TABLE_MONTH_HEADERS } from "../../../utils/mock-data/financial-report";
import React, { useEffect, useState } from "react";
import {
  getFixedValue,
  getMonthWiseSumOfData,
  getNetSalesMultiplyQtrPerc,
  getPerOverLastYear,
  getSupportCenter,
  getYTDCalcPerc,
} from "../goalSetting/utils/tableFormulae";
import {
  CATEGORIES,
  INPUT_ROWS_WIP,
  ITEMS,
  OFFICE_ROWS_WIP,
  quarterMonths,
  quarterPercKey,
  quarterSumKey,
  SALES_ROWS_WIP,
  TEMP_ROWS_WIP,
  TotalFiveWeeks,
} from "../goalSetting/utils/constants";
import { notifications } from "@mantine/notifications";
import { TaxRateType } from "../goalSetting/utils/types";
import TableComponentWip from "./TableComponentWip";

const GoalSettingTableWip = (props: any) => {
  const {
    tableHeaders,
    tableData,
    filteredGoalData,
    branchSelected,
    setFilteredGoalData,
    level,
    calcData,
    setCalcData,
    calcDataInputRows,
    setCalcDataInputRows,
    getSumCalculation,
    activeCell,
    setActiveCell,
    highlight,
    setHighlight,
    handleSaveAll,
    isSaving,
    saveAllTimeout,
    setHasUnsavedChanges,
    topPercValue,
    user,
  } = props;
  const [editedData, setEditedData] = useState<any>(filteredGoalData);
  // console.log(calcData, "calc");
  // console.log(calcDataInputRows, "calcDataInputRows");

  useEffect(() => {
    setEditedData(filteredGoalData);
  }, [branchSelected, filteredGoalData]);

  // Get the YTD of "Net Sales"
  const netSalesRow = filteredGoalData.find(
    (data: any) => data.items.trim() === ITEMS.NETSALES
  );

  const salesPayrollRow = filteredGoalData.find(
    (d: any) => d.items === ITEMS.SALESPAYROLLBASE
  );
  const salesCommissionRow = filteredGoalData.find(
    (d: any) => d.items === ITEMS.SALESCOMMISSION
  );

  const underIncomeModelRow = filteredGoalData.find(
    (d: any) => d.items === ITEMS.UNDERINCOMEMODEL
  );
  const netSalesYTD = getMonthWiseSumOfData(netSalesRow, TABLE_MONTH_HEADERS());

  // Get Net Sales of each quarter for percentage (qtr data/qtr data of net sales)
  const netSalesQuarters = {
    Q1: getMonthWiseSumOfData(netSalesRow, quarterMonths.Q1),
    Q2: getMonthWiseSumOfData(netSalesRow, quarterMonths.Q2),
    Q3: getMonthWiseSumOfData(netSalesRow, quarterMonths.Q3),
    Q4: getMonthWiseSumOfData(netSalesRow, quarterMonths.Q4),
  };

  const handleInputChange = (
    branch: string,
    category: string,
    item: string,
    field: string,
    value: string | number
  ) => {
    const findEditedRow = editedData.find(
      (d: any) =>
        d.Branch === branch && d.items === item && d.Categories === category
    );
    const updatedRow = { ...findEditedRow, [field]: value };
    setEditedData((prev: any) => {
      const existingIndex = prev.findIndex(
        (d: any) =>
          d.Branch === branch && d.items === item && d.Categories === category
      );
      if (existingIndex !== -1) {
        const updatedData = [...prev];
        updatedData[existingIndex] = updatedRow;
        return updatedData;
      } else {
        return [...prev, updatedRow];
      }
    });
  };

  const handleChangeRowData = async (
    branch: string,
    category: string,
    item: string
  ) => {
    const editedRow = editedData.find(
      (d: any) =>
        d.Branch === branch && d.items === item && d.Categories === category
    );
    const originalRow = filteredGoalData.find(
      (d: any) =>
        d.Branch === branch && d.items === item && d.Categories === category
    );
    if (!editedRow || !originalRow) {
      console.error(
        "No matching row found in either editedData or filteredGoalData"
      );
    }
    const changes: any = {};
    for (const key in editedRow) {
      if (editedRow[key] !== originalRow[key]) {
        changes[key] = editedRow[key] === "" ? 0 : editedRow[key];
      }
    }
    if (Object.keys(changes).length === 0) {
      // console.log("No changes made to the row");
    }
    const updatedData = {
      branch: branch,
      categories: category,
      items: item,
      changes,
      user: user,
    };
    //API call where the  row data is updated
    try {
      if (updatedData && Object.entries(updatedData.changes).length !== 0) {
        // const res: any = await APIUpdateTableRow(updatedData);
        setHasUnsavedChanges(true); // Data is calculated but not saved in DB
        // if (res.status === "success") {
        setFilteredGoalData(editedData);
        if (!isSaving) {
          if (saveAllTimeout.current) {
            clearTimeout(saveAllTimeout.current);
          }
          saveAllTimeout.current = setTimeout(() => {
            handleSaveAll();
            setHasUnsavedChanges(false);
          }, 10000);
        }
        // }
      }
    } catch (err) {
      setEditedData(filteredGoalData);
      notifications.show({
        title: "Error",
        message: "Failed to update data",
        color: "red",
      });
    } finally {
      setActiveCell({ branch: null, category: null, item: null, header: null });
    }
  };

  const getTotalTempExp = (
    netSalesData: any,
    header: any,
    taxRate: TaxRateType | any
  ) => {
    const rows = TEMP_ROWS_WIP;
    // Sum the values for each row
    const total = rows.reduce((sum, row) => {
      const currentRow = filteredGoalData.find(
        (d: any) => d.items === row && d.Categories === CATEGORIES.TEMP
      );

      const quarterKey = Object.keys(quarterMonths).find((quarter: any) =>
        quarterMonths[quarter].includes(header)
      );
      const percentageKey = quarterPercKey[quarterKey ?? 5];
      const qtrPercData = currentRow ? currentRow[percentageKey] : 0;
      const ytdCalcPerc = getYTDCalcPerc(
        currentRow?.["FinancialsYTD"],
        topPercValue,
        row
      );
      const value =
        functionMap[row](
          netSalesData,
          ytdCalcPerc,
          header,
          null,
          null,
          null,
          taxRate
        ) || 0;
      return sum + value;
    }, 0);
    return total;
  };

  const getGrossProfit = (
    netSalesData: any,
    header: any,
    taxRate: TaxRateType | any
  ) => {
    const totalTemp = getTotalTempExp(netSalesData, header, taxRate);
    const GP = parseFloat(netSalesData) - totalTemp;
    return isNaN(GP) ? 0 : GP;
  };

  const getTotalSalesExp = (
    netSalesData: any,
    header: any,
    taxRate: TaxRateType | any
  ) => {
    const rows = SALES_ROWS_WIP;
    const salesPayrollRow = filteredGoalData.find(
      (d: any) => d.items === ITEMS.SALESPAYROLLBASE
    );
    const salesCommissionRow = filteredGoalData.find(
      (d: any) => d.items === ITEMS.SALESCOMMISSION
    );
    const salesPayrollValue = salesPayrollRow[header];
    const salesCommissionValue = salesCommissionRow[header];
    const total = rows.reduce((sum, row) => {
      const currentRow = filteredGoalData.find(
        (d: any) => d.items === row && d.Categories === CATEGORIES.SALES
      );
      const quarterKey = Object.keys(quarterMonths).find((quarter: any) =>
        quarterMonths[quarter].includes(header)
      );
      const percentageKey = quarterPercKey[quarterKey ?? 5];
      const qtrPercData = currentRow ? currentRow[percentageKey] : 0;

      const ytdCalcPerc = getYTDCalcPerc(
        currentRow?.["FinancialsYTD"],
        topPercValue,
        row
      );
      const value =
        functionMap[row](
          netSalesData,
          ytdCalcPerc,
          header,
          salesPayrollValue,
          salesCommissionValue,
          null,
          taxRate
        ) || 0;
      return sum + value;
    }, 0);
    const sumResult = total + parseFloat(salesPayrollValue);
    return isNaN(sumResult) ? 0 : sumResult;
  };

  const getOfficeSupportPayroll = (netSalesData: any, header: any) => {
    const officesuppPayrollRow = filteredGoalData.find(
      (d: any) => d.items === ITEMS.OFFICE_SUPP_PAYROLL
    );
    const quarterKey = Object.keys(quarterMonths).find((quarter: any) =>
      quarterMonths[quarter].includes(header)
    );
    const percentageKey = quarterPercKey[quarterKey ?? 5];
    const qtrPercData = officesuppPayrollRow
      ? officesuppPayrollRow[percentageKey]
      : 0;

    const ytdCalcPerc = getYTDCalcPerc(
      officesuppPayrollRow["FinancialsYTD"],
      topPercValue,
      ITEMS.OFFICE_SUPP_PAYROLL
    );

    const result = netSalesData * (ytdCalcPerc / 100);
    return isNaN(result) ? 0 : result;
  };

  const calculateWithIterations = (
    netSalesData: any,
    header: any,
    salesPayrollValue: any,
    salesCommissionValue: any,
    grossProfit: any,
    taxRate: TaxRateType
  ) => {
    const initialBonus = 0;
    let prevBonus = initialBonus;
    let operatingIncome = 0;
    let netIncome = 0;
    let incomeModelPerc = 0;
    let officeBonus = 0;
    let officePayrollTax = 0;
    let totalOfficeExp = 0;
    let officeSupportPayroll: any = 0;
    let totalOperatingExp = 0;
    let i = 0;
    let maxIterations = 50;
    let difference = 0.01;

    while (i < maxIterations) {
      const officeSupportPayroll = getOfficeSupportPayroll(
        netSalesData,
        header
      );
      const officePayrollRow = filteredGoalData.find(
        (d: any) =>
          d.items === ITEMS.OFFICEPAYROLL && d.Categories === CATEGORIES.OFFICE
      );

      const ytdCalcPerc = getYTDCalcPerc(
        officePayrollRow?.["FinancialsYTD"],
        topPercValue,
        ITEMS.OFFICEPAYROLL
      );
      const officePayroll = getNetSalesMultiplyQtrPerc(
        netSalesData,
        ytdCalcPerc.toString()
      );

      officePayrollTax =
        (officePayroll + prevBonus + officeSupportPayroll) *
        taxRate.officeTaxRate;

      totalOfficeExp = getTotalOfficeExp(
        netSalesData,
        header,
        salesPayrollValue,
        salesCommissionValue,
        taxRate,
        officeBonus ?? 0,
        officePayrollTax ?? 0
      );

      const totalSalesExp = getTotalSalesExp(netSalesData, header, taxRate);
      totalOperatingExp = totalOfficeExp + totalSalesExp;

      const supportCenter = getSupportCenter(netSalesData);
      const staffingManager: any = getStaffingManager(
        netSalesData,
        header,
        taxRate
      );

      operatingIncome = grossProfit - totalOperatingExp;
      netIncome = operatingIncome - supportCenter;

      const incomeModel = getIncomeModel(netSalesData, header);
      incomeModelPerc = (operatingIncome / incomeModel) * 100;

      const staffValue = 0.025 * staffingManager;
      const leftHandSide =
        operatingIncome > 0 ? operatingIncome * (0.06 + staffValue + 0.04) : 0;
      const rightHandside = netIncome > 0 ? netIncome * 0.04 : 0;
      officeBonus = leftHandSide + rightHandside;

      if (Math.abs(officeBonus - prevBonus) < difference) {
        break;
      }
      prevBonus = officeBonus;
      i++;
    }
    return {
      operatingIncome,
      officeBonus,
      netIncome,
      incomeModelPerc,
      totalOfficeExp,
      totalOperatingExp,
      officeSupportPayroll,
      officePayrollTax,
    };
  };

  const getTotalOfficeExp = (
    netSalesData: any,
    header: any,
    salesPayrollValue: any,
    salesCommissionValue: any,
    taxRate: TaxRateType | any,
    officeBonus: any,
    officePayrollTax: any
  ) => {
    const rows = OFFICE_ROWS_WIP;
    // const officePayrollRow = filteredGoalData.find(
    //   (d: any) => d.items === "Office Payroll"
    // );

    // const officePayrollValue = officePayrollRow[header];
    const total = rows.reduce((sum, row) => {
      const currentRow = filteredGoalData.find(
        (d: any) => d.items === row && d.Categories === CATEGORIES.OFFICE
      );
      const quarterKey = Object.keys(quarterMonths).find((quarter: any) =>
        quarterMonths[quarter].includes(header)
      );
      const percentageKey = quarterPercKey[quarterKey ?? 5];
      const qtrPercData = currentRow ? currentRow[percentageKey] : 0;
      const quarterValueKey = quarterSumKey[quarterKey ?? 5];
      const qtrSumData = currentRow ? currentRow[quarterValueKey] : 0;

      const ytdCalcPerc = getYTDCalcPerc(
        currentRow?.["FinancialsYTD"],
        topPercValue,
        row
      );
      const value =
        functionMap[row](
          netSalesData,
          ytdCalcPerc,
          header,
          salesPayrollValue,
          salesCommissionValue,
          qtrSumData,
          taxRate
        ) || 0;
      return sum + value;
    }, 0);

    return (
      total +
      // parseFloat(officePayrollValue) +
      parseFloat(officeBonus) +
      parseFloat(officePayrollTax)
    );
  };

  const getStaffingManager = (
    netSalesData: string,
    header: any,
    taxRate: TaxRateType | any
  ) => {
    const totalWeekNo = TotalFiveWeeks.includes(header) ? 5 : 4;
    const GPByTotalWeek =
      getGrossProfit(netSalesData, header, taxRate) / totalWeekNo;
    const sqrtPart1 = Math.sqrt(GPByTotalWeek * (1 / 6676));
    const sqrtPart2 = Math.sqrt(1 / 6676);
    const res = sqrtPart1 === 0 ? 0 : sqrtPart1 - sqrtPart2;
    return res;
  };

  const getPayrollTaxes = (
    netSalesData: string,
    header?: string,
    taxRate?: TaxRateType
  ) => {
    const quarterKey = Object.keys(quarterMonths).find((quarter: any) =>
      quarterMonths[quarter].includes(header)
    );
    const tempPayrollRow = filteredGoalData.find(
      (d: any) => d.items === ITEMS.TEMPORARY_PAYROLL
    );
    const percentageKey = quarterPercKey[quarterKey ?? 5];
    const qtrPercData = tempPayrollRow[percentageKey];
    const ytdCalcPerc = getYTDCalcPerc(
      tempPayrollRow["FinancialsYTD"],
      topPercValue,
      ITEMS.TEMPORARY_PAYROLL
    );

    const tempPayrollData = getNetSalesMultiplyQtrPerc(
      netSalesData,
      ytdCalcPerc.toString()
    );
    const result =
      parseFloat(tempPayrollData.toString()) * (taxRate?.totalTaxRate ?? 0);
    return isNaN(result) ? 0 : result;
  };

  const getIncomeModel = (netSalesData: string, header: any) => {
    const staticValue = underIncomeModelRow[header];
    const result =
      parseFloat(netSalesData) === 0
        ? 0
        : (parseFloat(netSalesData) * 0.1236 - 9820 * staticValue) * 0.9;
    return result;
  };

  const getGPtoOI = (netSalesData: string, header: any, taxRate: any) => {
    const gp = getGrossProfit(netSalesData, header, taxRate);
    return gp * 0.7325 - 10500 * 0.75;
  };
  const getInitailData = (item: string, header: any) => {
    const currentRow = filteredGoalData.find((d: any) => d.items === item);
    return currentRow[header];
  };

  const getSalesTaxWip = (
    netSalesData: string,
    header: any,
    taxRate?: TaxRateType
  ) => {
    const salesPayrollValue = getInitailData(ITEMS.SALESPAYROLLBASE, header);
    const salesCommissionRow = filteredGoalData.find(
      (d: any) =>
        d.items === ITEMS.SALESCOMMISSION && d.Categories === CATEGORIES.SALES
    );

    const ytdCalcPerc = getYTDCalcPerc(
      salesCommissionRow?.["FinancialsYTD"],
      topPercValue,
      ITEMS.SALESCOMMISSION
    );
    const salesCommissionValue = getNetSalesMultiplyQtrPerc(
      netSalesData,
      ytdCalcPerc.toString()
    );
    const result =
      (parseFloat(salesPayrollValue) + salesCommissionValue) *
      (taxRate?.salesTaxRate ?? 0);
    return isNaN(result) ? 0 : result;
  };

  const functionMap: {
    [key: string]: (
      netSalesData?: any,
      ytdCalcPerc?: any,
      header?: string,
      salesPayrollValue?: any,
      salesCommissionValue?: any,
      qtrSumData?: any,
      taxRate?: TaxRateType
    ) => any;
  } = {
    "Temporary Payroll": getNetSalesMultiplyQtrPerc,
    "Temp Payroll Taxes": (netSalesData, _, header, ...rest) =>
      getPayrollTaxes(netSalesData, header, rest[3] as TaxRateType),
    "Temporary Medical Exp": getNetSalesMultiplyQtrPerc,
    "Temporary ACA Expense": getNetSalesMultiplyQtrPerc,
    "Temp Misc Expense": getNetSalesMultiplyQtrPerc,
    "Total Temp Expenses": (netSalesData, _, header, ...rest) =>
      getTotalTempExp(netSalesData, header, rest[3] as TaxRateType),
    "Gross Profit": (netSalesData, _, header, ...rest) =>
      getGrossProfit(netSalesData, header, rest[3] as TaxRateType),
    "Sales Payroll- Base": (netSalesData, ytdCalcPerc, header) =>
      getInitailData(ITEMS.SALESPAYROLLBASE, header),
    "Sales Commission": getNetSalesMultiplyQtrPerc,
    "Sales P/R Tax": (netSalesData, _, header, ...rest) =>
      getSalesTaxWip(netSalesData, header, rest[3] as TaxRateType),
    "Business Development": getNetSalesMultiplyQtrPerc,
    "Travel & Auto Expense": getNetSalesMultiplyQtrPerc,
    "Total Sales Expenses": (netSalesData, _, header, ...rest) =>
      getTotalSalesExp(netSalesData, header, rest[3] as TaxRateType),
    "Office Payroll": getNetSalesMultiplyQtrPerc,
    "Office Bonus": (
      netSalesData: string,
      qtrPercData: string,
      header: any,
      salesPayrollValue: string,
      salesCommissionValue: string,
      qtrSumData: string,
      taxRate: TaxRateType | any
    ) =>
      calculateWithIterations(
        netSalesData,
        header,
        salesPayrollValue,
        salesCommissionValue,
        getGrossProfit(netSalesData, header, taxRate),
        taxRate
      ).officeBonus,
    "Office Support Payroll": (netSalesData, qtrPercData, header) =>
      getOfficeSupportPayroll(netSalesData, header),
    "Office Payroll Tax": (
      netSalesData: any,
      qtrPercData: any,
      header: any,
      salesPayrollValue: any,
      salesCommissionValue: any,
      qtrSumData: any,
      taxRate: TaxRateType | any
    ) =>
      calculateWithIterations(
        netSalesData,
        header,
        salesPayrollValue,
        salesCommissionValue,
        getGrossProfit(netSalesData, header, taxRate),
        taxRate
      ).officePayrollTax,
    "Office Supplies": getNetSalesMultiplyQtrPerc,
    "Forms & Printing": getNetSalesMultiplyQtrPerc,
    Telephone: getNetSalesMultiplyQtrPerc,
    "Postage/Delivery": getNetSalesMultiplyQtrPerc,
    Rent: getNetSalesMultiplyQtrPerc,
    Utilities: getNetSalesMultiplyQtrPerc,
    Recruiting: getNetSalesMultiplyQtrPerc,
    "Bank Charges": getNetSalesMultiplyQtrPerc,
    Janitorial: getNetSalesMultiplyQtrPerc,
    "Health & Life Insurance": getNetSalesMultiplyQtrPerc,
    "Dues & Subscription": getNetSalesMultiplyQtrPerc,
    "Tax & Licenses": getNetSalesMultiplyQtrPerc,
    "R & M - Auto / Equip / Build": getNetSalesMultiplyQtrPerc,
    Insurance: getNetSalesMultiplyQtrPerc,
    "Legal Fees": getNetSalesMultiplyQtrPerc,
    "Accounting Fees": getNetSalesMultiplyQtrPerc,
    "Support Services": getNetSalesMultiplyQtrPerc,
    "Outside Services": getNetSalesMultiplyQtrPerc,
    "Computer Services": getNetSalesMultiplyQtrPerc,
    Advertising: getNetSalesMultiplyQtrPerc,
    "Training & Seminars": getNetSalesMultiplyQtrPerc,
    Depreciation: getNetSalesMultiplyQtrPerc,
    "Gifts & Entertainment": getNetSalesMultiplyQtrPerc,
    Travel: getNetSalesMultiplyQtrPerc,
    "Interest Expense": getNetSalesMultiplyQtrPerc,
    "Bad Debts": getNetSalesMultiplyQtrPerc,
    Miscellaneous: getNetSalesMultiplyQtrPerc,
    "Total Office Expenses": (
      netSalesData: string,
      ytdCalcPerc: string,
      header: any,
      salesPayrollValue: string,
      salesCommissionValue: string,
      qtrSumData: string,
      taxRate: TaxRateType | any
    ) =>
      calculateWithIterations(
        netSalesData,
        header,
        salesPayrollValue,
        salesCommissionValue,
        getGrossProfit(netSalesData, header, taxRate),
        taxRate
      ).totalOfficeExp,
    "Total Operating Expenses": (
      netSalesData: string,
      ytdCalcPerc: string,
      header: any,
      salesPayrollValue: string,
      salesCommissionValue: string,
      qtrSumData: string,
      taxRate: TaxRateType | any
    ) =>
      calculateWithIterations(
        netSalesData,
        header,
        salesPayrollValue,
        salesCommissionValue,
        getGrossProfit(netSalesData, header, taxRate),
        taxRate
      ).totalOperatingExp,
    "Operating Income (Loss)": (
      netSalesData: string,
      ytdCalcPerc: string,
      header: any,
      salesPayrollValue: string,
      salesCommissionValue: string,
      qtrSumData: string,
      taxRate: TaxRateType | any
    ) =>
      calculateWithIterations(
        netSalesData,
        header,
        salesPayrollValue,
        salesCommissionValue,
        getGrossProfit(netSalesData, header, taxRate),
        taxRate
      ).operatingIncome,
    "Suport Center Fee": (netSalesData) => getSupportCenter(netSalesData),
    "Net Income(Loss)": (
      netSalesData: string,
      ytdCalcPerc: string,
      header: any,
      salesPayrollValue: string,
      salesCommissionValue: string,
      qtrSumData: string,
      taxRate: TaxRateType | any
    ) =>
      calculateWithIterations(
        netSalesData,
        header,
        salesPayrollValue,
        salesCommissionValue,
        getGrossProfit(netSalesData, header, taxRate),
        taxRate
      ).netIncome,
    "Income Model": (netSalesData: string, ytdCalcPerc: string, header) =>
      getIncomeModel(netSalesData, header),
    "Income Model %": (
      netSalesData: string,
      ytdCalcPerc: string,
      header: any,
      salesPayrollValue: string,
      salesCommissionValue: string,
      qtrSumData: string,
      taxRate: TaxRateType | any
    ) =>
      calculateWithIterations(
        netSalesData,
        header,
        salesPayrollValue,
        salesCommissionValue,
        getGrossProfit(netSalesData, header, taxRate),
        taxRate
      ).incomeModelPerc,
    "Staffing Managers (#)": (netSalesData: string, _, header, ...rest) =>
      getStaffingManager(netSalesData, header, rest[3] as TaxRateType),
    "GP to Operating Income": (netSalesData: string, _, header, ...rest) =>
      getGPtoOI(netSalesData, header, rest[3] as TaxRateType),
  };

  const getCalculatedValue = (
    data: any,
    netSalesData: string,
    ytdCalcPerc: string,
    header: string,
    salesPayrollValue: string,
    salesCommissionValue: string,
    qtrSumData: string,
    taxRate: TaxRateType
  ) => {
    const items = data.items;
    return functionMap[items]
      ? functionMap[items](
          netSalesData,
          ytdCalcPerc,
          header,
          salesPayrollValue,
          salesCommissionValue,
          qtrSumData,
          taxRate
        )
      : null;
  };

  useEffect(() => {
    const dataWithoutInput = filteredGoalData.filter(
      (d: any) => !INPUT_ROWS_WIP.includes(d.items.trim())
    );
    const calculatedValues = dataWithoutInput.map((data: any) => {
      const currentRow = filteredGoalData.find(
        (d: any) => d.items === data.items && d.Categories === data.Categories
      );
      const totalTaxRate = data.Total;
      const salesTaxRate = data.Sales;
      const officeTaxRate = data.Office;
      const lastyeargoal = data.lastyeargoal ?? 0;

      const taxRate = {
        totalTaxRate,
        salesTaxRate,
        officeTaxRate,
      };
      let totalSum = 0;
      let Q1Sum = 0;
      let Q2Sum = 0;
      let Q3Sum = 0;
      let Q4Sum = 0;

      //Get calculated values for jan to dec for all rows except input rows
      return TABLE_MONTH_HEADERS().reduce((acc, header) => {
        const quarterKey = Object.keys(quarterMonths).find((quarter: any) =>
          quarterMonths[quarter].includes(header)
        );
        const netSalesData = netSalesRow[header];
        const percentageKey = quarterPercKey[quarterKey ?? 5];
        const qtrPercData = currentRow[percentageKey];
        const ytdCalcPerc = getYTDCalcPerc(
          currentRow?.["FinancialsYTD"],
          topPercValue,
          currentRow?.items
        );

        const quarterValueKey = quarterSumKey[quarterKey ?? 5];
        const qtrSumData = currentRow[quarterValueKey];
        const salesPayrollValue = salesPayrollRow?.[header];
        const salesCommissionValue = salesCommissionRow?.[header];
        const calculatedValue = getCalculatedValue(
          data,
          netSalesData,
          ytdCalcPerc.toString(),
          header,
          salesPayrollValue,
          salesCommissionValue,
          qtrSumData,
          taxRate
        );
        totalSum += parseFloat(calculatedValue) || 0;
        if (quarterMonths.Q1.includes(header)) {
          Q1Sum += parseFloat(calculatedValue) || 0;
        } else if (quarterMonths.Q2.includes(header)) {
          Q2Sum += parseFloat(calculatedValue) || 0;
        } else if (quarterMonths.Q3.includes(header)) {
          Q3Sum += parseFloat(calculatedValue) || 0;
        } else if (quarterMonths.Q4.includes(header)) {
          Q4Sum += parseFloat(calculatedValue) || 0;
        }

        return {
          ...acc,
          YTD: totalSum,
          "YTD %": getFixedValue((totalSum / netSalesYTD) * 100),
          YTD_Calc_perc: ytdCalcPerc,
          YTD_Amount_dollar: (ytdCalcPerc / 100) * netSalesYTD,
          Peroverlastyear: getPerOverLastYear(totalSum, lastyeargoal),
          // "1st Quarter": getFixedValue(Q1Sum),
          // "2nd Quarter": getFixedValue(Q2Sum),
          // "3rd Quarter": getFixedValue(Q3Sum),
          // "4th Quarter": getFixedValue(Q4Sum),
          // "1st %": getFixedValue((Q1Sum / netSalesQuarters["Q1"]) * 100),
          // "2nd %": getFixedValue((Q2Sum / netSalesQuarters["Q2"]) * 100),
          // "3rd %": getFixedValue((Q3Sum / netSalesQuarters["Q3"]) * 100),
          // "4th %": getFixedValue((Q4Sum / netSalesQuarters["Q4"]) * 100),
          [header]: calculatedValue,
          Branch: data.Branch,
          items: data.items,
          Categories: data.Categories,
          sorting: data.sorting,
        };
      }, {});
    });
    setCalcData(calculatedValues);

    //Calculation for input rows
    const inputData = filteredGoalData.filter((d: any) =>
      INPUT_ROWS_WIP.includes(d.items)
    );
    setCalcDataInputRows(getSumCalculation(inputData));
  }, [
    filteredGoalData,
    netSalesRow,
    salesPayrollRow,
    salesCommissionRow,
    topPercValue,
  ]);

  const handleHilight = (
    branch: string,
    category: string,
    item: string,
    header: string
  ) => {
    setHighlight({
      branch: branch,
      category: category,
      item: item,
      header: header,
    });
  };

  const getHighlightStyle = (
    branch: string,
    category: string,
    item: string,
    header: string
  ) =>
    highlight.branch === branch &&
    highlight.category === category &&
    highlight.item === item &&
    highlight.header === header
      ? { border: "2px solid #0284db" }
      : {};

  return (
    <div className="overflow-auto border-[1px] w-full">
      <TableComponentWip
        tableHeaders={tableHeaders}
        tableData={tableData}
        activeCell={activeCell}
        setActiveCell={setActiveCell}
        branchSelected={branchSelected}
        level={level}
        editedData={editedData}
        calcDataInputRows={calcDataInputRows}
        calcData={calcData}
        handleChangeRowData={handleChangeRowData}
        highlight={highlight}
        setHighlight={setHighlight}
        getHighlightStyle={getHighlightStyle}
        handleHilight={handleHilight}
        handleInputChange={handleInputChange}
      />
    </div>
  );
};

export default React.memo(GoalSettingTableWip);
