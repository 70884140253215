import { useSelector } from "react-redux";

export const useLabelFormatter = () => {
  const currentYear = useSelector(
    (state: any) => state.filterReducer.currentYear
  );

  return (label: string, targetYear?: string, chartType?: string) => {
    if (label === "Actual Goal") {
      return `${targetYear || currentYear} Goal`;
    } else if (
      label === "Current Year" ||
      label === "This Year Gross Profit" ||
      label === "This Year Revenue"
    ) {
      return currentYear.toString();
    } else if (
      label === "Last Year" ||
      label === "Last Year Gross Profit" ||
      label === "Last Year Revenue"
    ) {
      return (+currentYear - 1).toString();
    } else if (
      label === "Previous Year" ||
      label === "Prior Year Gross Profit" ||
      label === "Prior Year Revenue"
    ) {
      return (+currentYear - 2).toString();
    } else if (label === "Three Years Ago") {
      return (+currentYear - 3).toString();
    } else if (label === "Four Years Ago") {
      return (+currentYear - 4).toString();
    } else if (label.includes("12 Months")) {
      return "12 Months #";
    } else {
      return label;
    }
  };
};
