/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { Table } from "@mantine/core";
import {
  formatAmount,
  formatPercentage,
  sortByKey,
} from "../../../utils/helper/cellFormatter";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../store/Filter/actions";
import TopClientsChart from "./TopClientsChart";
import { handleExport } from "../../../utils/helper/dataExport";
import { TopClientsHeaders } from "../arAging/utils/displayColumns";
import {
  APIFetchMonthlyTopClientsChartADA,
  APIFetchMonthlyTopClientsChartWip,
  APIFetchQuarterlyTopClientsChartADA,
  APIFetchQuarterlyTopClientsChartWip,
  APIFetchWeeklyTopClientsChartADA,
  APIFetchWeeklyTopClientsChartWip,
} from "../../../api/top-clients-wip";
import { ContentLoader } from "../../common/Loader";
import { NON_SORTING_HEADERS } from "./utils/constants";

export const TopClientsTable = ({
  data,
  selectedDatamode,
  selectedTimeline,
  exportData,
  selectedLevel,
  mergeClient,
}: any) => {
  const headers: any = TopClientsHeaders;

  const dispatch = useDispatch();
  const [tableHeaders, setTableHeaders] = useState<any>(
    headers[selectedTimeline]
  );
  const [topClientsData, setTopClientsData] = useState<any>([]);
  const [showClientChart, setShowClientChart] = useState(false);
  const [activeClient, setActiveClient] = useState<any>({
    cleintName: null,
    branch: null,
  });
  const [selectedSortOption, setSelectedSortOption] = useState<any>();
  const [chartData, setChartData] = useState<any>([]);
  const [chartLoading, setChartLoading] = useState<boolean>(false);

  useEffect(() => {
    document.title = "Top Clients";
  }, []);

  // const topClientsWithClientId = (data: any, index: any) => {
  //   return {
  //     ...Object.fromEntries(Object.entries(data).slice(0, 1)),
  //     "Client ID": organizationid[index] ? organizationid[index] : "",
  //     ...Object.fromEntries(Object.entries(data).slice(1)),
  //   };
  // };
  // const organizationid = data.map((d: any) => d.OrganizationID);

  useEffect(() => {
    if (exportData) {
      handleExport(
        mergeClient
          ? tableHeaders
              .filter((h: any) => h.label !== "Branch")
              .map((h: any) => h.label)
          : tableHeaders.map((h: any) => h.label),
        formatTableData(data, "export"),
        "xlsx",
        "TopClients"
      );
    }
  }, [exportData]);

  const getCellFormatting = () => {
    if (
      selectedTimeline === "Rolling12Months" ||
      selectedTimeline === "PreviousRolling12Months" ||
      selectedTimeline === "PreviousRolling24Months"
    ) {
      return {
        amount: [7, 8, 9, 10, 12, 14],
        percentage: [11, 13, 15],
        rankSlot: 16,
      };
    } else {
      return {
        amount: [7, 8, 9, 10, 11, 13, 15],
        percentage: [12, 14, 16],
        rankSlot: 17,
      };
    }
  };

  useEffect(() => {
    const headerData = headers[selectedTimeline];
    setTableHeaders(headerData);
  }, [selectedTimeline]);

  useEffect(() => {
    dispatch(setLoading(true));
    const d = sortByKey(data, {
      type: selectedDatamode === "GrossProfit" ? "GPThisRank" : "RevThisRank",
    });
    setTopClientsData(formatTableData(d));
    dispatch(setLoading(false));
  }, [data, selectedDatamode]);

  // useEffect(() => {
  //   const c = groupByKey(chartData, "branch");
  //   const charts: any = {};
  //   Object.keys(c).forEach((k) => {
  //     charts[k] = groupByKey(c[k], "ClientName");
  //   });
  //   setClientsChartData(charts);
  // }, [chartData]);

  const formatTableData = (data: any, type = "table") => {
    return data.map((d: any) => {
      const obj: any = {};
      if (type === "table") {
        tableHeaders.forEach((header: any, index: any) => {
          obj[header.label] = getCellFormatting().amount.includes(index)
            ? formatAmount(d[header[selectedDatamode]])
            : getCellFormatting().percentage.includes(index)
            ? formatPercentage(d[header[selectedDatamode]])
            : d[header[selectedDatamode]];
        });
        if (d.Label) {
          obj.Label = d.Label;
        }
      } else if (type === "export") {
        //Remove branch column for merge Client export in excel
        const updatedTableHeaders = mergeClient
          ? tableHeaders.filter((d: any) => d.label !== "Branch")
          : tableHeaders;
        updatedTableHeaders.forEach((header: any, index: any) => {
          obj[header.label] = getCellFormatting().percentage.includes(
            mergeClient ? index + 1 : index
          )
            ? d[header[selectedDatamode]] * 100
            : d[header[selectedDatamode]];
        });
      }

      return obj;
    });
  };

  const showChart = (chartDetails: any, close?: boolean) => {
    if (
      chartDetails.clientName === activeClient.clientName &&
      chartDetails.branch === activeClient.branch
    ) {
      // close();
      setShowClientChart(false);
      setActiveClient({ clientName: null, branch: null });
    } else {
      // open();
      setShowClientChart(!close);
      setActiveClient(chartDetails);
    }
  };

  const chartApiList: any =
    selectedLevel === "Company"
      ? {
          Weekly: APIFetchWeeklyTopClientsChartWip,
          Monthly: APIFetchMonthlyTopClientsChartWip,
          Quarterly: APIFetchQuarterlyTopClientsChartWip,
        }
      : {
          Weekly: APIFetchWeeklyTopClientsChartADA,
          Monthly: APIFetchMonthlyTopClientsChartADA,
          Quarterly: APIFetchQuarterlyTopClientsChartADA,
        };

  const handleChartData = async (clientName: string, branchName: string) => {
    try {
      setChartLoading(true);
      const res = await chartApiList[selectedTimeline]?.(
        clientName,
        branchName,
        mergeClient
      );
      setChartData(res.data);
    } catch (error) {
      console.error("Error fetching chart data:", error);
    } finally {
      setChartLoading(false);
    }
  };
  useEffect(() => {
    if (
      activeClient.clientName &&
      ["Weekly", "Monthly", "Quarterly"].includes(selectedTimeline)
    ) {
      handleChartData(activeClient.clientName, activeClient.branch);
    }
  }, [selectedTimeline]);

  // useEffect(() => {
  //   console.log(selectedSortOption);
  //   const data = sortByKey(topClientsData, {
  //     type: selectedSortOption?.[selectedDatamode],
  //     isReverse: selectedSortOption?.isReverse,
  //   });
  //   setTopClientsData(data);
  // }, [selectedSortOption]);

  const changeSorting = (header: any) => {
    if (!NON_SORTING_HEADERS.includes(header.label))
      setSelectedSortOption({
        ...header,
        isReverse:
          header.label === selectedSortOption?.label
            ? !selectedSortOption?.isReverse
            : true,
      });
    const d = sortByKey(data, {
      type: header?.[selectedDatamode],
      isReverse:
        header.label === selectedSortOption?.label
          ? !selectedSortOption?.isReverse
          : true,
    });
    setTopClientsData(formatTableData(d));
  };

  const getIcon = (header: any) => {
    return selectedSortOption?.label !== header.label
      ? "unfold_more"
      : selectedSortOption?.isReverse
      ? "expand_more"
      : "expand_less";
  };

  return (
    <div className="flex overflow-auto">
      <Table verticalSpacing={1} className="border-collapse">
        <thead className="sticky top-0 bg-white z-20">
          <tr className="h-16">
            {headers[selectedTimeline]?.map((header: any) => {
              return header.hidden ? (
                ""
              ) : (
                <th
                  key={header.label}
                  style={{
                    textAlign: "center",
                    fontSize: "11px",
                    background: header.color ?? "#ddd",
                    padding: 0,
                  }}
                  className={`${
                    NON_SORTING_HEADERS.includes(header.label)
                      ? ""
                      : "cursor-pointer"
                  }`}
                  onClick={() => changeSorting(header)}
                >
                  <div className="flex flex-1 items-center justify-center h-20 xl:h-16 border-b-2 border-[#4c4c4c] px-2 lg:max-2xl:text-[0.6rem] 2xl:text-[0.7rem] text-[0.5rem]">
                    {header.label === "Branch" && mergeClient
                      ? null
                      : header.label}
                    {NON_SORTING_HEADERS.includes(header.label) ? (
                      <></>
                    ) : (
                      <i className="material-icons">{getIcon(header)}</i>
                    )}
                  </div>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {topClientsData?.map((d: any, i: any) => {
            return (
              <tr key={i} className="relative">
                {tableHeaders?.map((header: any, index: number) => {
                  const currentClient = {
                    clientName: d["Client Name"],
                    branch: d["Branch"],
                  };
                  return header.hidden ? (
                    ""
                  ) : (
                    <td
                      key={index}
                      className={`z-0 ${
                        header.label === "Client Name" ? "cursor-pointer" : ""
                      } ${
                        activeClient.clientName === currentClient.clientName &&
                        activeClient.branch === currentClient.branch
                          ? "bg-gray-400"
                          : ""
                      } ${
                        getCellFormatting().amount.includes(index) ||
                        getCellFormatting().percentage.includes(index)
                          ? "text-right"
                          : "text-center"
                      }`}
                      style={{
                        fontSize: "11px",
                        background:
                          activeClient.clientName ===
                            currentClient.clientName &&
                          activeClient.branch === currentClient.branch
                            ? "#bbb"
                            : header.color ?? "",
                        // border: "0.0625rem solid #dee2e6",
                      }}
                      onClick={() => {
                        if (header.label === "Client Name") {
                          showChart({
                            clientName: d[header.label],
                            branch:
                              d[
                                headers[selectedTimeline].find(
                                  (h: any) => h.label === "Branch"
                                )?.label
                              ],
                          });
                          handleChartData(
                            currentClient.clientName,
                            currentClient.branch
                          );
                        }
                      }}
                    >
                      {header.label === "Rank Slot" ? (
                        <span className="flex items-center">
                          {d[header.label] > 0 ? (
                            <i className="material-icons text-sm font-semibold text-green-600">
                              arrow_upward
                            </i>
                          ) : d[header.label] < 0 ? (
                            <i className="material-icons text-sm font-semibold text-red-600">
                              arrow_downward
                            </i>
                          ) : (
                            <span className="w-3 bg-orange-400 h-1"></span>
                          )}
                          <span className="flex-1">{d[header.label]}</span>
                        </span>
                      ) : header.label === "Client Name" ? (
                        <div className="flex items-center justify-between ">
                          <div className="flex-grow text-center">
                            {d[header.label]}
                          </div>
                          {d.Label === "VMS" ? (
                            <i className="material-icons text-[1rem] self-end">
                              star_border
                            </i>
                          ) : null}
                        </div>
                      ) : (
                        <div>{d[header.label]}</div>
                      )}
                      {["Weekly", "Monthly", "Quarterly"].includes(
                        selectedTimeline
                      ) &&
                      header.label === "Client Name" &&
                      showClientChart &&
                      activeClient.clientName === currentClient.clientName &&
                      activeClient.branch === currentClient.branch ? (
                        <div className="absolute bg-white border border-gray-300 left-[20%] w-[60rem] p-5 z-10">
                          <div className="flex justify-between">
                            <span className="flex gap-2 justify-center text-sm">
                              <span>{activeClient.clientName}</span>
                              <span>-</span>
                              <span>{activeClient.branch}</span>
                            </span>
                            <span
                              className="cursor-pointer"
                              onClick={() =>
                                showChart(
                                  { clientName: null, branch: null },
                                  true
                                )
                              }
                            >
                              <i className="material-icons">close</i>
                            </span>
                          </div>
                          <div className="relative">
                            <ContentLoader loading={chartLoading} />
                            <TopClientsChart
                              dataMode={selectedDatamode}
                              chartData={
                                // clientsChartData?.[activeClient.branch]?.[
                                //   activeClient.clientName
                                // ]
                                chartData
                              }
                              selectedTimeline={selectedTimeline}
                            />
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
          <tr className="py-5 font-medium text-xs bg-[#ddd] h-8 sticky bottom-0">
            <td colSpan={tableHeaders.length}>
              Total number of clients :{" "}
              {topClientsData ? topClientsData.length : 0}
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};
