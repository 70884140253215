import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { TABLE_MONTH_HEADERS } from "../../../utils/mock-data/financial-report";
import { dottedLine, formatPercChange } from "../../../utils/helper/charts";
import { getMonthData } from "../companySummary/utils/weekdata";
import { formatAmount } from "../../../utils/helper/cellFormatter";
import { Loader } from "@mantine/core";
import { useLabelFormatter } from "../../../utils/hooks/useLabelFormatter";

const ChartArea = (props: any) => {
  const { colors, revenueData, yearChartData, branchSelected } = props;
  const labelFormatter = useLabelFormatter();

  const tempChartValues = {
    [`${labelFormatter("Previous Year")}`]: [],
    [`${labelFormatter("Last Year")}`]: [],
    [`${labelFormatter("Current Year")}`]: [],
  };

  const [chartOptions, setChartOptions] = useState<any>({
    chart: {
      type: "line",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      selection: {
        enabled: false,
      },
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    colors: colors.map((c: any) => c.color),

    markers: {
      size: 1,
      strokeWidth: 0,
      hover: {
        sizeOffset: 6,
      },
    },
    series: [],
    stroke: {
      dashArray: [0, 0, 0, 2],
      width: 2,
      curve: "straight",
      colors: colors.map((c: any) => c.color),
    },
    legend: {
      show: false,
    },
    yaxis: [
      {
        labels: {
          style: {
            colors: "#000000",
            fontSize: "11px",
          },
          formatter: (v: any) => formatAmount(v),
        },
        max: branchSelected.name === "St. George" ? 500000 : undefined,
      },
    ],
    xaxis: {
      labels: {
        style: {
          fontSize: "11px",
        },
      },
      categories: TABLE_MONTH_HEADERS(),
    },
    tooltip: {
      enabled: true,
      enabledOnSeries: [0, 1, 2, 3],
      followCursor: !!props.isTopClientsReport,
      fixed: {
        enabled: true,
        position: "topRight",
        offsetX: 0,
        offsetY: 0,
      },
    },
  });

  useEffect(() => {
    const chartGoalData = TABLE_MONTH_HEADERS().map((month: string) =>
      revenueData[month] === "" ? null : revenueData[month]
    );
    const maxValue = branchSelected.name === "St. George" ? 500000 : undefined;
    setChartOptions((prev: any) => ({
      ...prev,
      yaxis: [
        {
          ...prev.yaxis[0], // Keep other properties of yaxis
          max: maxValue, // Set the max value dynamically
        },
      ],
      series: [
        ...Object.keys(tempChartValues).map((v, key) => {
          return {
            name: labelFormatter(v),
            data: [...getMonthData(yearChartData[v])],
          };
        }),
        {
          name: "2025 Goal",
          data: chartGoalData,
        },
      ],
    }));
  }, [revenueData, yearChartData, branchSelected]);

  return (
    <ReactApexChart
      options={chartOptions}
      series={chartOptions.series}
      type="line"
      height={"90%"}
    />
  );
};

const GoalSettingChart = (props: any) => {
  const labelFormatter = useLabelFormatter();

  const {
    branchSelected,
    revenueData,
    yearChartData,
    chartHeader,
    chartHeaderLoading,
  } = props;

  const colors = [
    { label: "Previous Year", color: "#1875B5" },
    { label: "Last Year", color: "#FF7F0E" },
    { label: "Current Year", color: "#29A229" },
    { label: "2025 Goal", color: "#A66999" },
  ];

  const chartHeaderData = [
    {
      label: "2025 Goal",
      value: formatAmount(chartHeader.goal),
      bgColor: "bg-[#e1d5e7]",
      borderColor: "border-[#93829a]",
    },
    {
      label: "2024 Total",
      value: formatAmount(chartHeader.totalRev),
      bgColor: "bg-[#ace3ac]",
      borderColor: "border-[#76b576]",
    },
    {
      label: "Expected Growth %",
      value: formatPercChange(chartHeader.growthPerc, true),
      bgColor: "",
      borderColor: "border-black",
    },
  ];

  return (
    <div className="flex flex-col">
      <div className="flex justify-between items-end">
        <div className="sm:w-[35%] w-0"></div>
        <div className="bg-tertiary text-white py-1 px-4 min-w-[150px] h-8 text-center rounded-sm ">
          {branchSelected.name === "All" ? "Company" : branchSelected.name}
        </div>
        <div className="sm:w-[35%] flex justify-end gap-1">
          {chartHeaderData.map((item: any, index: number) => (
            <div
              key={index}
              className="w-[30%] text-xs flex flex-col justify-center items-center "
            >
              <span className="text-stone-700 text-[0.7rem]">{item.label}</span>
              <div
                className={`relative flex justify-center items-center ${item.bgColor} h-8 w-full border-[1px] ${item.borderColor}`}
                title={item.label}
              >
                {chartHeaderLoading ? (
                  <Loader size="xs" color="gray" />
                ) : (
                  <div>{item.value}</div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="border-[1px] border-stone-400 w-[80vw] h-[75vh]">
        <div className="chart-legend-area">
          <div className="colors-area">
            {colors?.map((v, key) => (
              <div className="color-box" key={key}>
                <div
                  className={
                    dottedLine(v.label)
                      ? `w-5 border-dotted border-y-2 border-x-0 border-[${v.color}]`
                      : "color-circle"
                  }
                  style={{ backgroundColor: `${v.color}` }}
                ></div>
                <div className={"color-name text-xs"}>
                  {labelFormatter(v.label)}
                </div>
              </div>
            ))}
          </div>
        </div>
        <ChartArea
          colors={colors}
          revenueData={revenueData}
          yearChartData={yearChartData}
          branchSelected={branchSelected}
        />
      </div>
    </div>
  );
};

export default GoalSettingChart;
