/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { GetRequest, PostRequest } from "../plugins/https";

export const APIGetAllCorporations = () => {
  return GetRequest("/filter/corporations");
};

export const APIGetInvoiceDateData = (date: any, branch: any) => {
  return PostRequest("/ar-aging/invoice-date", { date, branch });
};

export const APIGetDueDateData = (date: any, branch: any) => {
  return PostRequest("/ar-aging/due-date", { date, branch });
};

export const APIGetAllInvoiceDateData = (date: any) => {
  return PostRequest("/ar-aging/invoice-date/all", { date });
};

export const APIGetAllDueDateData = (date: any) => {
  return PostRequest("/ar-aging/due-date/all", { date });
};

export const APIGetClientComments = (branch: string) => {
  return GetRequest("/ar-aging/comments/" + branch);
};

//AR-VIEW
export const APIGetAllClerks = () => {
  return GetRequest("/ar-aging/ar-clerks");
};

export const APIGetAllPaymentTerms = () => {
  return GetRequest("/ar-aging/payment-terms");
};

export const APIGetAllClients = (arClerk: string, paymentTerm: string) => {
  return GetRequest(
    `/ar-aging/clients?arClerk=${arClerk}&paymentTerm=${paymentTerm}`
  );
};
export const APIGetARAssignmentByInvoiceDate = (
  date: any,
  arClerk: string,
  paymentTerm: string,
  customer: string
) => {
  return PostRequest("/ar-aging/assignments-by-date", {
    date,
    arClerk,
    paymentTerm,
    customer,
  });
};

export const APIGetArComments = (
  date: any,
  arClerk: string,
  paymentTerm: string,
  customer: string
) => {
  return GetRequest(`/ar-aging/ar-comments`, {
    params: { date, arClerk, paymentTerm, customer },
  });
};

export const APIGetDSO = (
  date: any,
  arClerk: string,
  paymentTerm: string,
  customer: string
) => {
  return GetRequest(`/ar-aging/dso`, {
    params: { date, arClerk, paymentTerm, customer },
  });
};

//ADA Level Data
export const APIGetAllADACorporations = () => {
  return GetRequest("/ar-aging/ada-corporations");
};

export const APIGetADAInvoiceDateData = (date: any, branch?: any) => {
  return PostRequest("/ar-aging/invoice-date-ada", { date, branch });
};

export const APIGetADADueDateData = (date: any, branch?: any) => {
  return PostRequest("/ar-aging/due-date-ada", { date, branch });
};
export const APIGetClientCommentsADA = (branch: string) => {
  return GetRequest("/ar-aging/ada-comments/" + branch);
};
