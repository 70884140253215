import { HoverCard, Table } from "@mantine/core";
import { useSelector } from "react-redux";

const RedeploymentTable = (props: any) => {
  const {
    sortConfig,
    setSortConfig,
    getIcon,
    sortedData,
    maxEmployeesReAssigned,
    maxEmployeesOffAssignment,
    maxRedeployedPercentage,
    tableData,
    totalData,
    headers,
  } = props;
  const loading = useSelector((state: any) => state.filterReducer.loading);

  return (
    <Table
      fontSize="xs"
      verticalSpacing={1}
      horizontalSpacing={1}
      withColumnBorders
      className="bg-white"
    >
      <thead className="sticky top-0 shadow-sm h-8 z-20 bg-tertiary">
        <tr>
          {headers.map((header: any) => {
            return (
              <th
                key={header.value}
                style={{ color: "#FFFFFF", paddingLeft: "5px" }}
                className="min-w-[120px] md:min-w-[200px]"
              >
                <div className="flex justify-between items-center">
                  {header.label}
                  <i
                    className="material-icons cursor-pointer"
                    style={{ fontSize: "20px" }}
                    onClick={() =>
                      setSortConfig({
                        key: header.label,
                        direction:
                          sortConfig === null || sortConfig.direction === "desc"
                            ? "asc"
                            : "desc",
                      })
                    }
                  >
                    {getIcon(header.label)}
                  </i>
                </div>
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody>
        {!loading &&
          (sortedData.length > 0 ? (
            sortedData.map((data: any, index: number) => {
              return (
                <tr key={index} className="hover:bg-stone-100">
                  <td className="w-[100px]" style={{ paddingLeft: "5px" }}>
                    {data.Branch}
                  </td>
                  <td className="text-center cursor-pointer w-[400px] min-w-[100px]">
                    <HoverCard
                      width={200}
                      shadow="sm"
                      offset={0}
                      withArrow
                      position="bottom-end"
                    >
                      <HoverCard.Target>
                        <div>
                          <div
                            className="bg-[#a8d5e0] relative h-5"
                            style={{
                              width:
                                maxEmployeesOffAssignment === 0
                                  ? 0
                                  : (data[
                                      "# of Employees that came off Assignment"
                                    ] /
                                      maxEmployeesOffAssignment) *
                                    350,
                            }}
                          >
                            <div className="absolute left-10 text-xs">
                              {data[
                                "# of Employees that came off Assignment"
                              ] === 0
                                ? "None"
                                : data[
                                    "# of Employees that came off Assignment"
                                  ]}
                            </div>
                          </div>
                        </div>
                      </HoverCard.Target>
                      {data.EndReason.length === 1 &&
                        data.EndReason.includes("No Longer In Use") && (
                          <HoverCard.Dropdown>
                            {data.data.map((d: any, key: number) => {
                              return (
                                <div
                                  className="flex justify-between items-center "
                                  key={key}
                                >
                                  <div>{d.Reason ? d.Reason : d.EndReason}</div>
                                  <div>
                                    {
                                      d[
                                        "# of Employees that came off Assignment"
                                      ]
                                    }
                                  </div>
                                </div>
                              );
                            })}
                          </HoverCard.Dropdown>
                        )}
                    </HoverCard>
                  </td>
                  <td className="text-center w-[400px] min-w-[100px]">
                    <div
                      className="bg-[#a8d5e0] relative h-5"
                      style={{
                        width:
                          maxEmployeesReAssigned === 0
                            ? 0
                            : (data["# of Employees that were Re-Assigned"] /
                                maxEmployeesReAssigned) *
                              350,
                      }}
                    >
                      <div className="absolute left-10 text-xs">
                        {data["# of Employees that were Re-Assigned"] === 0
                          ? "None"
                          : data["# of Employees that were Re-Assigned"]}
                      </div>
                    </div>
                  </td>
                  <td className="text-center w-[300px] min-w-[80px]">
                    <div
                      className="bg-[#a8d5e0] relative h-5 -z-1"
                      style={{
                        width:
                          maxRedeployedPercentage === 0
                            ? 0
                            : (data["Redeployed %"] / maxRedeployedPercentage) *
                              250,
                      }}
                    >
                      <div className="absolute left-10 text-xs">
                        {data["Redeployed %"] === null
                          ? "None"
                          : (data["Redeployed %"] === 0
                              ? data["Redeployed %"]
                              : data["Redeployed %"]?.toFixed(2)) + "%"}
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })
          ) : (
            <td colSpan={4}>
              <div className="py-40 flex items-center justify-center text-sm">
                No data available
              </div>
            </td>
          ))}
      </tbody>
      {!loading && tableData.length > 0 ? (
        <tfoot className="sticky h-8 bottom-0 bg-stone-100">
          <tr>
            <th>Total</th>
            <th style={{ textAlign: "center" }}>{totalData.AssignedTotal}</th>
            <th style={{ textAlign: "center" }}>{totalData.ReassignedTotal}</th>
            <th style={{ textAlign: "center" }}>-</th>
          </tr>
        </tfoot>
      ) : (
        <></>
      )}
    </Table>
  );
};

export default RedeploymentTable;
