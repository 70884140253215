/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { useSelector } from "react-redux";
import {
  formatCell,
  formatPercentage,
} from "../../../utils/helper/cellFormatter";

export const BranchSection = (props: any) => {
  const headers = useSelector((state: any) => state.filterReducer.firstHeaders);
  const type = useSelector(
    (state: any) => state.filterReducer.selectedAreaType
  );
  return (
    <div>
      <div className="text-bold branch-section-top w-[120px]">
        {props.title}
      </div>
      {props.data.first.map((v: any, key: number) => (
        <div className={"table-row"} key={key}>
          <div className="data-area header-section">
            <div className={`table-col title`}>{v.title}</div>
            {headers.map((date: any, ind: number) => {
              return (
                <div className={`table-col text-center`} key={ind}>
                  {formatCell(key, v[date])}
                </div>
              );
            })}
          </div>
          <div className="projection-area header-section">
            {props.data.second && props.data.second[key]
              ? Object.values(props.data.second[key]).map(
                  (dat: any, key2: number) => (
                    <div
                      className={`table-col text-center ${
                        key2 === 0 ? "title" : ""
                      }`}
                      key={key2}
                    >
                      {formatCell(key, dat)}
                    </div>
                  )
                )
              : ""}
          </div>
          <div className="rate-area header-section">
            {props.data.third && props.data.third[key]
              ? Object.values(props.data.third[key]).map(
                  (dat: any, key3: number) => (
                    <div
                      className={`table-col text-center ${
                        key3 === 0 ? "title" : ""
                      }`}
                      key={key3}
                    >
                      {formatPercentage(dat)}
                    </div>
                  )
                )
              : ""}
          </div>
          {type !== "All" && (
            <div className="manager-area header-section">
              <div className="full-width text-center text-blue">
                {props?.data?.manager[key]
                  ? props.data.manager[key].Manager
                  : ""}
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};
