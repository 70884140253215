import { Switch } from "@mantine/core";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import {
  APIGPWithHistorical,
  APIGPWithoutHistorical,
  APIGrossMarginWithHistorical,
  APIGrossMarginWithoutHistorical,
  APIGrowthPercWithHistorical,
  APIGrowthPercWithoutHistorical,
  APIRevWithHistorical,
  APIRevWithoutHistorical,
} from "../../../api/company-summary";
import { groupByKey } from "../../../utils/group";
import { getWeekData } from "./utils/weekdata";
import {
  formatAmount,
  formatPercentValue,
} from "../../../utils/helper/cellFormatter";
import { ContentLoader } from "../../common/Loader";
import { DATAMODE } from "./utils/constants";

const RegionComparisonChart = (props: any) => {
  const { dataMode } = props;
  const [compDataMode, setCompDataMode] = useState(dataMode);
  const [showHistorical, setShowHistorical] = useState(false);
  const [showLastYear, setShowLastYear] = useState(false);
  const [responseData, setResponseData] = useState<any>([]);
  const [weekLabels, setWeekLabels] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setCompDataMode(dataMode);
  }, [dataMode]);

  const fetchComparisonData = async () => {
    setLoading(true);
    const apiFunctions: any = {
      Revenue: {
        true: APIRevWithHistorical,
        false: APIRevWithoutHistorical,
      },
      GrossProfit: {
        true: APIGPWithHistorical,
        false: APIGPWithoutHistorical,
      },
      "Gross Margin": {
        true: APIGrossMarginWithHistorical,
        false: APIGrossMarginWithoutHistorical,
      },
      "Growth %": {
        true: APIGrowthPercWithHistorical,
        false: APIGrowthPercWithoutHistorical,
      },
    };
    const apiFunction = apiFunctions[compDataMode]?.[showHistorical.toString()];
    const res = await apiFunction();
    setWeekLabels(Object.keys(groupByKey(res.data, "WeekNumber")));
    setResponseData(groupByKey(res.data, "Region"));
    setLoading(false);
  };

  const chartColors = [
    { label: "AZ NM Eric", color: "#A8337D" },
    { label: "David B", color: "#734EC2" },
    { label: "KS IL IA GA OK Brian", color: "#2496FD" },
    { label: "TX Inez", color: "#E6703C" },
    { label: "UT CO Mark", color: "#DAB812" },
    { label: "UT WA Madeline", color: "#32a852" },
  ];

  useEffect(() => {
    fetchComparisonData();
  }, [compDataMode, showHistorical]);

  const modeKey: any = {
    Revenue: ["ThisYearRevenue", "LastYearRevenue"],
    GrossProfit: ["ThisYearGrossProfit", "LastYearGrossProfit"],
    "Gross Margin": ["ThisGrossMargin", "LastGrossMargin"],
    "Growth %": ["GP% Growth"],
  };

  const ComparisonChart = () => {
    const [chartOptions, setChartOptions] = useState<any>({
      chart: {
        type: "line",
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
        selection: {
          enabled: false,
        },
      },
      colors: chartColors.flatMap((v) =>
        modeKey[compDataMode].length > 1 && showLastYear
          ? [v.color, v.color]
          : [v.color]
      ),
      markers: {
        size: 1.5,
        strokeWidth: 0,
        hover: {
          sizeOffset: 5,
        },
      },
      tooltip: {
        enabled: true,
        shared: true,
        intersect: false,
        x: {
          show: true,
        },
      },
      series: [
        ...Object.keys(responseData).flatMap((v) => {
          if (compDataMode === DATAMODE.GROWTHPERC || !showLastYear) {
            return [
              {
                name: `${v} `,
                data: [
                  ...getWeekData(responseData[v], modeKey[compDataMode][0]),
                ],
              },
            ];
          }
          return [
            {
              name: `${v} (TY)`,
              data: [...getWeekData(responseData[v], modeKey[compDataMode][0])],
            },

            {
              name: `${v} (LY)`,
              data: [...getWeekData(responseData[v], modeKey[compDataMode][1])],
            },
          ];
        }),
      ],
      stroke: {
        dashArray:
          modeKey[compDataMode].length > 1 && showLastYear
            ? [0, 2, 0, 2, 0, 2, 0, 2, 0, 2, 0, 2, 0, 2]
            : 0,
        width: 1,
        curve: "straight",
        colors: chartColors.flatMap((v) =>
          modeKey[compDataMode].length > 1 && showLastYear
            ? [v.color, v.color]
            : [v.color]
        ),
      },
      legend: {
        show: false,
      },
      yaxis: [
        {
          labels: {
            style: {
              colors: "#000000",
              fontSize: "7px",
            },
            formatter: (v: any) =>
              compDataMode === DATAMODE.GROSSMARGIN ||
              compDataMode === DATAMODE.GROWTHPERC
                ? formatPercentValue(v)
                : formatAmount(v),
          },
          title: {
            text: "Bill Amount",
          },
        },
      ],
      xaxis: {
        labels: {
          style: {
            fontSize: "9px",
          },
        },
        categories: weekLabels,
      },
      annotations: {
        yaxis:
          compDataMode === DATAMODE.GROWTHPERC
            ? [
                {
                  y: 0,
                  borderColor: "#e81405",
                  strokeDashArray: 0,
                  borderWidth: 2,
                },
              ]
            : [],
      },
    });
    return (
      <ReactApexChart
        options={chartOptions}
        series={chartOptions.series}
        type={"line"}
        height={270}
      />
    );
  };

  const dataModeList = [
    { label: "Revenue", value: "Revenue" },
    { label: "Gross Profit", value: "GrossProfit" },
    { label: "Gross Margin", value: "Gross Margin" },
    { label: "Growth %", value: "Growth %" },
  ];

  return (
    <div className="flex flex-col gap-1">
      <div className="w-full  flex">
        <div className="w-[40%] "></div>
        <div className="w-[20%] flex justify-center items-end ">
          <div className="bg-tertiary text-white w-[200px] text-center font-semibold text-sm p-1">
            {compDataMode === "Revenue" ? "Sales" : compDataMode} by Region
          </div>
        </div>
        <div className="w-[40%] flex justify-end items-center gap-5">
          {" "}
          {compDataMode !== DATAMODE.GROWTHPERC && (
            <Switch
              size="xs"
              label="Show Last Year Data"
              checked={showLastYear}
              onChange={(event: any) =>
                setShowLastYear(event.currentTarget.checked)
              }
            />
          )}
          <Switch
            size="xs"
            label="Show Historical Data"
            checked={showHistorical}
            onChange={(event: any) =>
              setShowHistorical(event.currentTarget.checked)
            }
          />
          <div className="data-mode-selector-box ">
            {dataModeList.map((d: any, key: number) => {
              return (
                <div
                  key={key}
                  className={`data-mode-selector cursor-pointer hover:brightness-75
            ${compDataMode === d.value ? "active" : ""}
        `}
                  onClick={() => setCompDataMode(d.value)}
                >
                  {d.label}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="relative bg-white border-[1px] border-[#BBBCBC]">
        <div className="chart-area">
          <div className="chart-legend-area">
            <div className="colors-area">
              {chartColors.map((v, key) => (
                <div key={v.label + key}>
                  <div className="color-box">
                    <div
                      className="color-circle"
                      style={{ backgroundColor: `${v.color}` }}
                    ></div>
                    <div className={"color-name"}>
                      {v.label + " (This Year)"}
                    </div>
                  </div>
                  <div className="color-box">
                    <div
                      className={`w-4 border-dotted border-y-2 border-x-0 border-[${v.color}]`}
                      style={{ backgroundColor: `${v.color}` }}
                    ></div>
                    <div className={"color-name"}>
                      {v.label + " (Last Year)"}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <ContentLoader loading={loading} />
        <ComparisonChart />
      </div>
    </div>
  );
};

export default RegionComparisonChart;
