/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

export const TABLE_HEADERS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
  "YTD",
  "Cur %",
  "YTD %",
  // 'Curr',
];

export const TABLE_MONTH_HEADERS = () => {
  return [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
};

export const TABLE_OTHER_HEADERS = [
  "YTD",
  "Cur %",
  "YTD %",
  // 'Curr',
];

export const HIGHLIGHTED_PARTICULARS = [
  "Net Sales",
  "Total Temp Expenses",
  "Gross Profit",
  "Total Sales Expenses",
  "Total G&A Expenses",
  "Total Operating Expenses",
  "Total Office Expenses",
  "Operating Income/(Loss)",
  "Net Income/(Loss)",
];

export const MONTHS: Record<
  number,
  { monthNumber: number; monthName: string }
> = {
  0: { monthNumber: 1, monthName: "January" },
  1: { monthNumber: 2, monthName: "February" },
  2: { monthNumber: 3, monthName: "March" },
  3: { monthNumber: 4, monthName: "April" },
  4: { monthNumber: 5, monthName: "May" },
  5: { monthNumber: 6, monthName: "June" },
  6: { monthNumber: 7, monthName: "July" },
  7: { monthNumber: 8, monthName: "August" },
  8: { monthNumber: 9, monthName: "September" },
  9: { monthNumber: 10, monthName: "October" },
  10: { monthNumber: 11, monthName: "November" },
  11: { monthNumber: 12, monthName: "December" },
};

export const LEDGERS_HEADERS: Record<string, string> = {
  batch_date: "Batch Date",
  entry_date: "Entry Date",
  document: "Document",
  description: "Memo/Description",
  department: "Department",
  locationid: "Location",
  vendor: "Vendor Name",
  symbol: "JNL",
  debit: "Debit",
  credit: "Credit",
  balance: "Balance",
};

export const SORT_VALUES: Record<string, string> = {
  None: "None",
  Revenue: "Net Sales",
  GP: "Gross Profit",
  "Net Income": "Net Income/(Loss)",
};
