/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { convertDaysIntoDuration } from "./utils/weekdata";
import { sortByKeyCompanySummary } from "../../../utils/helper/cellFormatter";
import SummaryChartBranch from "./SummaryChartBranch";
import { HOUSTON_BRANCHES } from "../../../utils/helper/constants";

export const CompanyBranchCharts = ({
  branchesData,
  adaBranchData,
  dataMode,
  revenueData,
  branchesList,
  sortValue,
  labels,
  setTotalType,
  setSelectedBranch,
  level,
  setLevel,
  sameMaxValue,
  showHouston,
  pipMilestonesData,
  branchesPipData,
  splitBranchesData,
  mergedBranchNames,
  splitBranchRevenueData,
  executive,
  wip,
  showGoalData,
  chartInfo,
}: any) => {
  const getRevenue = (val: any) => {
    return val?.length > 0 ? val[0] : null;
  };

  const getMilestonesData = (location: any) => {
    return (
      [...pipMilestonesData]?.find((d: any) => d.Location === location) ?? null
    );
  };

  const getBranchChartInfo = (branch: string | null) => {
    const branchChartInfo = {
      totalRev:
        chartInfo.totalRev?.find((d: any) => d.Branch === branch)?.[
          "2024 Total"
        ] ?? 0,
      goal:
        chartInfo.growthPerc?.find((d: any) => d.Branch === branch)?.[
          "2025 Goal"
        ] ?? 0,
      growthPerc:
        chartInfo.growthPerc?.find((d: any) => d.Branch === branch)?.[
          "Expected Growth %"
        ] ?? 0,
    };
    return branchChartInfo;
  };

  return (
    <section className="cs-branch-charts">
      {/*Branch level*/}
      <div className={"w-full cs-branch-charts flex wrap"}>
        {Object.keys(branchesData).length > 0
          ? sortByKeyCompanySummary(
              Object.values(revenueData)
                .flat()
                .filter((v: any) => !HOUSTON_BRANCHES.includes(v.Branch)),
              sortValue
            ).map((v: any, key) => {
              const data: any = branchesData[v?.Branch];
              if (
                !!branchesData[v?.Branch] &&
                branchesList.includes(v?.Branch)
              ) {
                const managerArr: any =
                  Object.values(data).length > 0 ? Object.values(data)[0] : [];
                const salesManagerDays = convertDaysIntoDuration(
                  managerArr[0]?.BranchManagerDays ?? ""
                );
                const branchManagerDays = convertDaysIntoDuration(
                  managerArr[0]?.SalesManagerDays ?? ""
                );

                const mergedBranch =
                  mergedBranchNames.filter(
                    (branch: string) => branch === v?.Branch
                  )[0] ?? null;

                return (
                  <div className={"summary-chart-box"} key={v.Branch + key}>
                    <SummaryChartBranch
                      name={{ label: v?.Branch ?? "" }}
                      data={data}
                      manager={managerArr[0]?.BranchManager ?? "VACANT"}
                      dataMode={dataMode}
                      branchManagerDays={salesManagerDays}
                      labels={labels}
                      salesManagerDays={branchManagerDays}
                      sortValue={sortValue}
                      salesManager={managerArr[0]?.SalesManager ?? "VACANT"}
                      jobTitle={
                        managerArr[0]?.BranchManagerJobTitle ?? "Branch Manager"
                      }
                      revenue={getRevenue(revenueData[v?.Branch])}
                      setTotalType={setTotalType}
                      setSelectedBranch={setSelectedBranch}
                      setLevel={setLevel}
                      location={{
                        name: v?.Branch,
                        type: "Branch",
                        level: "Branch",
                      }}
                      regionManager={managerArr[0]?.RegionManager ?? ""}
                      districtManager={managerArr[0]?.DistrictManager ?? ""}
                      sameMaxValue={sameMaxValue}
                      pipMilestonesData={getMilestonesData(v?.Branch)}
                      pipData={branchesPipData?.[v?.Branch] ?? null}
                      mergedBranch={mergedBranch}
                      splitBranchesData={splitBranchesData[v.Branch] ?? null}
                      splitBranchRevenueData={splitBranchRevenueData ?? null}
                      executive={executive}
                      wip={wip}
                      showGoalData={showGoalData}
                      branchChartInfo={getBranchChartInfo(v?.Branch)}
                    />
                  </div>
                );
              } else {
                return "";
              }
            })
          : ""}
      </div>

      {/*Branch level- Houston branches*/}
      {showHouston && level === "Company" && (
        <div
          className={
            "w-full cs-branch-charts flex wrap border-t-8 border-[#888F8E] pt-10"
          }
        >
          {Object.keys(branchesData).length > 0
            ? sortByKeyCompanySummary(
                Object.values(revenueData)
                  .flat()
                  .filter((v: any) => HOUSTON_BRANCHES.includes(v.Branch)),
                sortValue
              ).map((v: any, key) => {
                const data: any = branchesData[v?.Branch];
                if (
                  !!branchesData[v?.Branch] &&
                  branchesList.includes(v?.Branch)
                ) {
                  const managerArr: any =
                    Object.values(data).length > 0
                      ? Object.values(data)[0]
                      : [];
                  const salesManagerDays = convertDaysIntoDuration(
                    managerArr[0]?.BranchManagerDays ?? ""
                  );
                  const branchManagerDays = convertDaysIntoDuration(
                    managerArr[0]?.SalesManagerDays ?? ""
                  );
                  const mergedBranch =
                    mergedBranchNames.filter(
                      (branch: string) => branch === v?.Branch
                    )[0] ?? null;

                  return (
                    <div className={"summary-chart-box"} key={v.Branch + key}>
                      <SummaryChartBranch
                        name={{ label: v?.Branch ?? "" }}
                        data={data}
                        manager={managerArr[0]?.BranchManager ?? "VACANT"}
                        dataMode={dataMode}
                        branchManagerDays={salesManagerDays}
                        labels={labels}
                        salesManagerDays={branchManagerDays}
                        sortValue={sortValue}
                        salesManager={managerArr[0]?.SalesManager ?? "VACANT"}
                        jobTitle={
                          managerArr[0]?.BranchManagerJobTitle ??
                          "Branch Manager"
                        }
                        revenue={getRevenue(revenueData[v?.Branch])}
                        setTotalType={setTotalType}
                        setSelectedBranch={setSelectedBranch}
                        setLevel={setLevel}
                        location={{
                          name: v?.Branch,
                          type: "Branch",
                          level: "Branch",
                        }}
                        regionManager={managerArr[0]?.RegionManager ?? ""}
                        districtManager={managerArr[0]?.DistrictManager ?? ""}
                        sameMaxValue={sameMaxValue}
                        pipMilestonesData={getMilestonesData(v?.Branch)}
                        pipData={branchesPipData?.[v?.Branch] ?? null}
                        mergedBranch={mergedBranch}
                        splitBranchesData={splitBranchesData[v.Branch] ?? null}
                        splitBranchRevenueData={splitBranchRevenueData ?? null}
                        executive={executive}
                        wip={wip}
                        showGoalData={showGoalData}
                        branchChartInfo={getBranchChartInfo(v?.Branch)}
                      />
                    </div>
                  );
                } else {
                  return "";
                }
              })
            : ""}
        </div>
      )}

      {/*Branch level- ADA branches*/}
      <div className={"w-full cs-branch-charts flex wrap"}>
        {Object.keys(adaBranchData).length > 0
          ? sortByKeyCompanySummary(
              Object.values(revenueData).flat(),
              sortValue
            ).map((v: any, key) => {
              const data: any = adaBranchData[v?.Branch];
              if (
                !!adaBranchData[v?.Branch] &&
                branchesList.includes(v?.Branch)
              ) {
                const managerArr: any =
                  Object.values(data).length > 0 ? Object.values(data)[0] : [];
                const salesManagerDays = convertDaysIntoDuration(
                  managerArr[0]?.BranchManagerDays ?? ""
                );
                const branchManagerDays = convertDaysIntoDuration(
                  managerArr[0]?.SalesManagerDays ?? ""
                );
                return (
                  <div
                    className={"summary-chart-box ada-branch-style "}
                    key={v.Branch + key}
                  >
                    <SummaryChartBranch
                      name={{ label: v?.Branch ?? "" }}
                      data={data}
                      manager={managerArr[0]?.BranchManager ?? "VACANT"}
                      dataMode={dataMode}
                      branchManagerDays={salesManagerDays}
                      labels={labels}
                      salesManagerDays={branchManagerDays}
                      sortValue={sortValue}
                      salesManager={managerArr[0]?.SalesManager ?? "VACANT"}
                      jobTitle={
                        managerArr[0]?.BranchManagerJobTitle ?? "Branch Manager"
                      }
                      revenue={getRevenue(revenueData[v?.Branch])}
                      setTotalType={setTotalType}
                      setSelectedBranch={setSelectedBranch}
                      setLevel={setLevel}
                      location={{
                        name: v?.Branch,
                        type: "ADA_Branch",
                        level: "ADA",
                      }}
                      regionManager={managerArr[0]?.RegionManager ?? ""}
                      districtManager={managerArr[0]?.DistrictManager ?? ""}
                      sameMaxValue={sameMaxValue}
                      pipMilestonesData={getMilestonesData(v?.Branch)}
                      pipData={branchesPipData?.[v?.Branch] ?? null}
                      executive={executive}
                      wip={wip}
                      showGoalData={showGoalData}
                      branchChartInfo={getBranchChartInfo(v?.Branch)}
                    />
                  </div>
                );
              } else {
                return "";
              }
            })
          : ""}
      </div>
    </section>
  );
};
