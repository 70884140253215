/*
 * Copyright (C) 2023 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import "../assets/scss/DailyNumber.scss";
import { useEffect, useState } from "react";
import { DailyNumberSelector } from "../components/modules/dailyNumber/DailyNumberBranchSelector";
import { CompanySummarySidebar } from "../components/modules/companySummary/CompanySummarySidebar";
import { DailyNumberRegionTable } from "../components/modules/dailyNumber/DailyNumberRegionTable";
import DailyNumberAreaChart from "../components/modules/dailyNumber/DailyNumberAreaChart";
import DailyNumberRegionChart from "../components/modules/dailyNumber/DailyNumberRegionChart";
import { DailyNumberBranchTable } from "../components/modules/dailyNumber/DailyNumberBranchTable";
import {
  APIGetAreaCalendarWeekData,
  APIGetCompanyCalendarWeekData,
  APIGetAreaTrend,
  APIGetRegionCalendarWeekData,
  APIGetVPCalendarWeekData,
  APIGetCompanyTrend,
  APIGetRegionTrend,
  APIGetVpTrend,
  APIGetDistrictCalendarWeekData,
  APIGetDistrictTrend,
  APIGetADACalendarWeekData,
  APIGetADATrend,
} from "../api/dailyNumber";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { getLocationOrder } from "../store/Filter/actions";
import { Select, Switch } from "@mantine/core";
import HomeButton from "../components/common/HomeButton";

export const DailyNumberReport = (props: any) => {
  const [calendarLoading, setCalendarLoading] = useState(false);
  const [areaTrendLoading, setAreaTrendLoading] = useState(false);
  const [regionTrendLoading, setRegionTrendLoading] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState(
    props.adaOnly ? "ADA_Branch" : "Company"
  );
  const [calendarWeekData, setCalendarWeekData] = useState([]);
  const [dailyBranches, setDailyBranches] = useState([]);
  const [areaTrend, setAreaTrend] = useState([]);
  const [regionTrend, setRegionTrend] = useState([]);
  const currentYear = useSelector(
    (state: any) => state.filterReducer.currentYear
  ).toString();
  const [year, setYear] = useState(currentYear);
  const [level, setLevel] = useState(props.adaOnly ? "ADA" : "Company");
  const [totalType, setTotalType] = useState(
    props.adaOnly ? "ADA Company" : "Company"
  );
  const [sortOrder, setSortOrder] = useState([] as any);
  const [rawHeaders, setRawHeaders] = useState([] as any);
  const [showCheckCuts, setShowCheckCuts] = useState(false);
  const [weekDaysSelected, setWeekDaysSelected] = useState([] as string[]);
  const dispatch = useDispatch();

  useEffect(() => {
    document.title = "Daily Numbers Report";
  }, []);

  const years = Array.from({ length: 4 }, (_, i) =>
    (moment().year() - i).toString()
  );

  useEffect(() => {
    const interval = setInterval(() => {
      loadData().then((_) => {});
    }, 900000);
    return () => {
      clearInterval(interval);
    };
  }, [level, selectedBranch]);

  useEffect(() => {
    const interval = setInterval(() => {
      getCompanyTable().then((_) => {});
    }, 900000);
    return () => {
      clearInterval(interval);
    };
  }, [level, selectedBranch]);

  useEffect(() => {
    loadLocationOder();
    loadData().then((_) => {});
  }, []);

  useEffect(() => {
    getCompanyTable().then((_) => {});
  }, [year, selectedBranch]);

  const loadData = async () => {
    if (level === "Company") {
      getAreaTrend();
    }
    getRegionTrend();
  };

  useEffect(() => {
    const interval = setInterval(() => {
      getRegionTrend().then((_) => {});
    }, 900000);
    return () => {
      clearInterval(interval);
    };
  }, [level, selectedBranch]);

  useEffect(() => {
    loadData();
  }, [selectedBranch]);

  const loadLocationOder = async () => {
    dispatch(await getLocationOrder());
  };

  const order = useSelector((state: any) => state.filterReducer.locationOrder);

  useEffect(() => {
    setSortOrder(order);
  }, [order]);

  const getCompanyTable = async () => {
    setCalendarLoading(true);
    const startDate = moment(year).startOf("year").format("YYYY-MM-DD");
    let res: any = { data: [] };
    if (level === "Company") {
      res = await APIGetCompanyCalendarWeekData({ startDate });
    } else if (level === "Area") {
      res = await APIGetAreaCalendarWeekData({
        startDate,
        area: selectedBranch,
      });
    } else if (level === "Subarea") {
      res = await APIGetVPCalendarWeekData({
        startDate,
        subarea: selectedBranch,
      });
    } else if (level === "Region") {
      res = await APIGetRegionCalendarWeekData({
        startDate,
        region: selectedBranch,
      });
    } else if (level === "District") {
      res = await APIGetDistrictCalendarWeekData({
        startDate,
        district: selectedBranch,
      });
    } else if (level === "ADA") {
      res = await APIGetADACalendarWeekData({ startDate });
    }
    setCalendarWeekData(res.data);
    const headers = Object.keys(res.data[0])?.filter(
      (v: any) =>
        ![
          "CalendarWeekCaption",
          "CalendarWeekKey",
          "CalendarYear",
          "StartDate",
          "MidnightDailyNumber",
          "DayOfTheWeek",
        ].includes(v) && v
    );
    setRawHeaders(headers);
    setCalendarLoading(false);
  };

  const getAreaTrend = async () => {
    setAreaTrendLoading(true);
    const res: any = await APIGetCompanyTrend();
    setAreaTrend(res.data);
    setAreaTrendLoading(false);
  };

  const getRegionTrend = async () => {
    setRegionTrendLoading(true);
    let res: any = {};
    if (level === "District") {
      res = await APIGetDistrictTrend(selectedBranch);
      setRegionTrend(res.data);
    } else if (level === "Region") {
      res = await APIGetRegionTrend(selectedBranch);
      setRegionTrend(res.data);
    } else if (level === "Subarea") {
      res = await APIGetVpTrend(selectedBranch);
      setRegionTrend(res.data);
    } else if (level === "ADA") {
      res = await APIGetADATrend();
      setRegionTrend(res.data);
    } else {
      res = await APIGetAreaTrend(level === "Company" ? "all" : selectedBranch);
      setRegionTrend(res.data);
    }
    setRegionTrendLoading(false);
  };

  const resetFilters = () => {
    setSelectedBranch("Company");
    setYear(moment().format("YYYY"));
    setLevel("Company");
    setTotalType("Company");
    setShowCheckCuts(false);
    setWeekDaysSelected([]);
  };

  return (
    <main className={"screen daily-number-report flex"}>
      <HomeButton adaOnly={props.adaOnly} resetFilters={resetFilters} />
      <div className="dn-sidebar-section">
        <CompanySummarySidebar adaOnly={props.adaOnly} />
      </div>
      <section className="main-section flex-grow ">
        <div className={"dn-header"}>
          <div className={"first-col w-0 xl:w-[40%] 2xl:w-[20%]"}></div>
          <div className={"second-col w-full 2xl:w-[60%]"}>
            <div className="flex align-center dn-title-area ">
              <div className="w-third"></div>
              <div className="dn-title text-center">Daily Number</div>
              <div className="w-third flex justify-end"></div>
            </div>
            <DailyNumberSelector
              selectedBranch={selectedBranch}
              setSelectedBranch={setSelectedBranch}
              setLevel={setLevel}
              level={level}
              totalType={totalType}
              setTotalType={setTotalType}
              adaOnly={props.adaOnly}
              isAscendAdaAccess={props.isAscendAdaAccess}
            />
          </div>
          <div
            className={
              "third-col w-full 2xl:w-[20%] flex flex-row 2xl:flex-col gap-5 justify-end items-end pr-1 2xl:justify-end 2xl:items-end"
            }
          >
            <Select
              className="w-32"
              size="xs"
              label="Select Year"
              data={years}
              value={year}
              onChange={(value: string) => setYear(value)}
            />
            <Switch
              size="xs"
              label="Show Check Cuts"
              checked={showCheckCuts}
              onChange={(event: any) =>
                setShowCheckCuts(event.currentTarget.checked)
              }
            />
          </div>
        </div>
        <DailyNumberRegionTable
          data={calendarWeekData ?? []}
          loading={calendarLoading}
          level={level}
          totalType={totalType}
          selectedBranch={selectedBranch}
          sortOrder={sortOrder}
          rawHeaders={rawHeaders}
          showCheckCuts={showCheckCuts}
          weekDaysSelected={weekDaysSelected}
          setWeekDaysSelected={setWeekDaysSelected}
        />
        {level === "Company" && (
          <DailyNumberAreaChart data={areaTrend} loading={areaTrendLoading} />
        )}
        <DailyNumberRegionChart
          data={regionTrend}
          loading={regionTrendLoading}
          level={level}
        />
        <DailyNumberBranchTable
          level={level}
          totalType={totalType}
          selectedBranch={selectedBranch}
          dailyBranches={dailyBranches}
          sortOrder={sortOrder}
          rawHeaders={rawHeaders}
        />
      </section>
    </main>
  );
};
