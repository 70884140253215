/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { Filters } from "../components/Filters";
import { TableHeaders } from "../components/modules/weeklyReport/TableHeaders";
import { SelectedReport } from "../components/modules/weeklyReport/SelectedReport";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getCalendarWeeks } from "../store/Filter/actions";
import { Loader } from "../components/common/Loader";
import "../assets/scss/CustomReport.scss";
import { CompanySummarySidebar } from "../components/modules/companySummary/CompanySummarySidebar";

export const WeeklyReport = (props: any) => {
  const [exportToggle, setExportToggle] = useState();
  const [selectedLevel, setSelectedLevel] = useState(
    props.adaOnly ? "ADA" : "Company"
  );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCalendarWeeks());
  }, []);

  useEffect(() => {
    document.title = "Weekly Report";
  });

  return (
    <main
      className={"dashboard screen custom-report  w-[100vw] overflow-hidden"}
    >
      <Loader />
      <div className={"sidebar-section"}>
        <CompanySummarySidebar />
      </div>
      <div className={"main-content  "}>
        <div className="content-header ">
          <Filters
            exportData={setExportToggle}
            selectedLevel={selectedLevel}
            adaOnly={props.adaOnly}
          />
        </div>
        <div className="table-area overflow-auto pr-4">
          <TableHeaders />
          <SelectedReport
            exportData={exportToggle}
            selectedLevel={selectedLevel}
          />
        </div>
      </div>
    </main>
  );
};
