/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { GetRequest } from "../plugins/https";

export const APIFetchFunnelChartData = (branch: any) => {
  return GetRequest(`/sales-activity/funnel-chart`, { params: { branch } });
};

export const APIFetchBarChartDataCompanyLevel = () => {
  return GetRequest(`/sales-activity/bar-chart`);
};
export const APIFetchBarChartDataAreaLevel = (area: any) => {
  return GetRequest(`/sales-activity/area/bar-chart`, { params: { area } });
};
export const APIFetchBarChartDataSubareaLevel = (subarea: any) => {
  return GetRequest(`/sales-activity/subarea/bar-chart`, {
    params: { subarea },
  });
};
export const APIFetchBarChartDataRegionLevel = (region: any) => {
  return GetRequest(`/sales-activity/region/bar-chart`, { params: { region } });
};
export const APIFetchBarChartDataDistrictLevel = (district: any) => {
  return GetRequest(`/sales-activity/district/bar-chart`, {
    params: { district },
  });
};
export const APIFetchBarChartDataBranchLevel = (branch: any) => {
  return GetRequest(`/sales-activity/branch/bar-chart`, { params: { branch } });
};
export const APIFetchBarChartDataClientLevel = (client: any) => {
  return GetRequest(`/sales-activity/client/bar-chart`, { params: { client } });
};
