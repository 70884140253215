export const quarterMonths: any = {
  Q1: ["January", "February", "March"],
  Q2: ["April", "May", "June"],
  Q3: ["July", "August", "September"],
  Q4: ["October", "November", "December"],
};

export const quarterPercKey: any = {
  Q1: "1st %",
  Q2: "2nd %",
  Q3: "3rd %",
  Q4: "4th %",
};

export const quarterSumKey: any = {
  Q1: "1st Quarter",
  Q2: "2nd Quarter",
  Q3: "3rd Quarter",
  Q4: "4th Quarter",
};

export const QUARTERLIST = [
  { quarter: "1st Quarter", quarterPercentage: "1st %", name: "Q1" },
  { quarter: "2nd Quarter", quarterPercentage: "2nd %", name: "Q2" },
  { quarter: "3rd Quarter", quarterPercentage: "3rd %", name: "Q3" },
  { quarter: "4th Quarter", quarterPercentage: "4th %", name: "Q4" },
];

//enums contains latest name labels for items used in wip but not in main report
export enum ITEMS {
  NETSALES = "Net Sales",
  TEMPORARY_PAYROLL = "Temporary Payroll",
  TEMPPAYROLL_TAXES = "Temp Payroll Taxes",
  TEMP_MED_EXP = "Temporary Medical Exp", //changed
  TEMP_ACA_EXP = "Temporary ACA Expense", //changed
  TEMP_MISC_EXP = "Temp Misc Expense", //changed
  TOTAL_TEMP_EXP = "Total Temp Expenses",
  GROSSPROFIT = "Gross Profit",

  SALESPAYROLLBASE = "Sales Payroll- Base", //changed
  SALESCOMMISSION = "Sales Commission",
  SALESPRTAX = "Sales P/R Tax",
  BUSINESSDEV = "Business Development",
  TRAVEL_AUTO_EXP = "Travel & Auto Expense", //changed
  TOTAL_SALES_EXP = "Total Sales Expenses",

  OFFICEPAYROLL = "Office Payroll",
  OFFICE_SUPP_PAYROLL = "Office Support Payroll",
  OFFICESUPP = "Office Supplies",
  FORMS_PRINTING = "Forms & Printing",
  TELEPHONE = "Telephone",
  POSTAGE_DELIVERY = "Postage/Delivery",
  RENT = "Rent",
  UTILITIES = "Utilities",
  RECRUITING = "Recruiting",
  BANKCHARGES = "Bank Charges",
  JANITORIAL = "Janitorial",
  HEALTH_LIFE_INS = "Health & Life Insurance",
  DUES_SUBSCRIPTION = "Dues & Subscription", //changed
  TAX_LICENSES = "Tax & Licenses",
  REPAIR_MAINTENANCE = "R & M - Auto / Equip / Build", //changed
  INSURANCE = "Insurance",
  LEGALFEES = "Legal Fees",
  ACC_FEES = "Accounting Fees",
  SUPP_SERVICES = "Support Services",
  OUTSIDE_SERVICES = "Outside Services",
  COMPUTER_SERVICES = "Computer Services",
  ADVERTISING = "Advertising",
  TRAINING_SEMINARS = "Training & Seminars",
  DEPRECIATION = "Depreciation", //changed
  GIFTS_ENTERTAINMENT = "Gifts & Entertainment",
  TRAVEL = "Travel", //changed
  INTEREST_EXPENSE = "Interest Expense",
  BADDEBTS = "Bad Debts",
  MISCELLANEOUS = "Miscellaneous",
  TOTAL_OFFICE_EXP = "Total Office Expenses",
  TOTAL_OP_EXP = "Total Operating Expenses",

  OPERATINGINCOME = "Operating Income (Loss)",
  SUPP_CENTER_FEE = "Suport Center Fee",
  NETINCOME_LOSS = "Net Income(Loss)",

  INCOMEMODEL = "Income Model",
  UNDERINCOMEMODEL = "SM Income Calc",
  INCOMEMODELPERC = "Income Model %",
  STAFFINGMANAGER = "Staffing Managers (#)",
  ACQUISITION_EXPENSE = "Acquisition Expense",
}

export enum CATEGORIES {
  TEMP = "Temporary Expenses",
  SALES = "Sales Expenses",
  OFFICE = "Office Expenses",
  INCOME = "Income",
  OTHERS = "Others",
}

export const INPUT_ROWS = [
  "Net Sales",
  "Sales Payroll-Base",
  "Sales Commission",
  "Office Payroll",
  "SM Income Calc",
];

export const INPUT_ROWS_WIP = [ITEMS.NETSALES, ITEMS.UNDERINCOMEMODEL];

export const TEMP_ROWS = [
  "Temporary Payroll",
  "Temp Payroll Taxes",
  "Temporary Medical Exp",
  "Temporary ACA",
  "Temp Mis Expenses",
];
export const SALES_ROWS = [
  "Sales P/R Tax",
  "Business Development",
  "Travel & Auto Expenses",
];
export const OFFICE_ROWS = [
  "Office Support Payroll",
  "Office Supplies",
  "Forms & Printing",
  "Telephone",
  "Postage/Delivery",
  "Rent",
  "Utilities",
  "Recruiting",
  "Bank Charges",
  "Janitorial",
  "Health & Life Insurance",
  "Dues & Subscriptions",
  "Tax & Licenses",
  "Repairs & Maintenance",
  "Insurance",
  "Legal Fees",
  "Accounting Fees",
  "Support Services",
  "Outside Services",
  "Computer Services",
  "Advertising",
  "Training & Seminars",
  "Depreciation/Amortization",
  "Gifts & Entertainment",
  "Travel & Auto Expenses",
  "Interest Expense",
  "Bad Debts",
  "Miscellaneous",
];

export const TEMP_ROWS_WIP = [
  ITEMS.TEMPORARY_PAYROLL,
  ITEMS.TEMPPAYROLL_TAXES,
  ITEMS.TEMP_MED_EXP,
  ITEMS.TEMP_ACA_EXP,
  ITEMS.TEMP_MISC_EXP,
];
export const SALES_ROWS_WIP = [
  ITEMS.SALESCOMMISSION,
  ITEMS.SALESPRTAX,
  ITEMS.BUSINESSDEV,
  ITEMS.TRAVEL_AUTO_EXP,
];

export const OFFICE_ROWS_WIP = [
  ITEMS.OFFICEPAYROLL,
  ITEMS.OFFICE_SUPP_PAYROLL,
  ITEMS.OFFICESUPP,
  ITEMS.FORMS_PRINTING,
  ITEMS.TELEPHONE,
  ITEMS.POSTAGE_DELIVERY,
  ITEMS.RENT,
  ITEMS.UTILITIES,
  ITEMS.RECRUITING,
  ITEMS.BANKCHARGES,
  ITEMS.JANITORIAL,
  ITEMS.HEALTH_LIFE_INS,
  ITEMS.DUES_SUBSCRIPTION,
  ITEMS.TAX_LICENSES,
  ITEMS.REPAIR_MAINTENANCE,
  ITEMS.INSURANCE,
  ITEMS.LEGALFEES,
  ITEMS.ACC_FEES,
  ITEMS.SUPP_SERVICES,
  ITEMS.OUTSIDE_SERVICES,
  ITEMS.COMPUTER_SERVICES,
  ITEMS.ADVERTISING,
  ITEMS.TRAINING_SEMINARS,
  ITEMS.DEPRECIATION,
  ITEMS.GIFTS_ENTERTAINMENT,
  ITEMS.TRAVEL,
  ITEMS.INTEREST_EXPENSE,
  ITEMS.BADDEBTS,
  ITEMS.MISCELLANEOUS,
];

export const HIGHLIGHTED_PARTICULARS = [
  "Net Sales",
  "Total Temp Expenses",
  "Gross Profit",
  "Total Sales Expenses",
  "Total Operating Expenses",
  "Total Office Expenses",
  "Net Income(Loss)",
];

export const NULL_CELLS = [
  ITEMS.STAFFINGMANAGER,
  ITEMS.INCOMEMODELPERC,
  ITEMS.UNDERINCOMEMODEL,
];

export const YTDCALC_NULL_CELLS = [
  ITEMS.TEMPPAYROLL_TAXES,
  ITEMS.TOTAL_TEMP_EXP,
  ITEMS.GROSSPROFIT,
  ITEMS.SALESPAYROLLBASE,
  ITEMS.SALESPRTAX,
  ITEMS.TOTAL_SALES_EXP,
  ITEMS.TOTAL_OFFICE_EXP,
  ITEMS.TOTAL_OP_EXP,
  ITEMS.OPERATINGINCOME,
  ITEMS.SUPP_CENTER_FEE,
  ITEMS.NETINCOME_LOSS,
  ITEMS.INCOMEMODEL,
  ITEMS.STAFFINGMANAGER,
  ITEMS.INCOMEMODELPERC,
  ITEMS.UNDERINCOMEMODEL,
];

export const NULL_ITEMS = [
  "GP to Operating Income",
  ITEMS.ACQUISITION_EXPENSE,
  ITEMS.UNDERINCOMEMODEL,
];

export const ITEMS_WITH_REDUCTION = [
  ITEMS.TEMPORARY_PAYROLL,
  ITEMS.TEMP_MED_EXP,
  ITEMS.TEMP_ACA_EXP,
  ITEMS.TEMP_MISC_EXP,
];

export const TotalFiveWeeks = ["March", "June", "September", "December"];

export const hierarchy = [
  "Company",
  "Areaname",
  "Sub Area name",
  "Region",
  "District",
  "Branch",
];

export enum LEVELS {
  COMPANY = "Company",
  AREA = "Areaname",
  SUBAREA = "Sub Area name",
  REGION = "Region",
  DISTRICT = "District",
  BRANCH = "Branch",
}

export const PerOverLYRows = [ITEMS.NETSALES, ITEMS.GROSSPROFIT];

export const YTD_HEADERS = ["YTD", "YTD %", "% Over LY"];
export const QUARTER_HEADERS = [
  "1st QTR",
  "1st %",
  "",
  "2nd QTR",
  "2nd %",
  "",
  "3rd QTR",
  "3rd %",
  "",
  "4th QTR",
  "4th %",
];
