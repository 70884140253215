/*
 * Copyright (C) 2023 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import moment from "moment";

export const chartDataValidator = (keys: any, arr: any) => {
  return [];
};

export const labelFormatter = (
  label: string,
  targetYear?: string,
  chartType?: string
) => {
  const currentYear = moment(new Date()).add("weeks", "-2").year();
  if (label === "Actual Goal") {
    return `${targetYear || currentYear} Goal`;
  } else if (
    label === "Current Year" ||
    label === "This Year Gross Profit" ||
    label === "This Year Revenue"
  ) {
    return currentYear;
  } else if (
    label === "Last Year" ||
    label === "Last Year Gross Profit" ||
    label === "Last Year Revenue"
  ) {
    return (+currentYear - 1).toString();
  } else if (
    label === "Previous Year" ||
    label === "Prior Year Gross Profit" ||
    label === "Prior Year Revenue"
  ) {
    return (+currentYear - 2).toString();
  } else if (label === "12 Months") {
    return "12 Months #";
  } else {
    return label;
  }
};

export const dottedLine = (label: string) => {
  return (
    label === "Actual Goal" ||
    label === "Goal Revenue" ||
    label === "12 Months" ||
    label === "% to Plan PIP" ||
    label === "% to Last Year PIP"
  );
};

export const formatPercChange = (value: any, isDecimal?: boolean) => {
  return (
    <div
      className={`${
        value > 0 ? "text-green-600" : value < 0 ? "text-red-500" : "text-black"
      } font-medium flex items-center justify-center`}
    >
      {isDecimal ? (
        <div>{value === null ? 0 : (value * 100)?.toFixed(2)}%</div>
      ) : (
        <div>{value === null ? 0 : value?.toFixed(2)}%</div>
      )}
      <i className="material-icons text-xs ">
        {value > 0 ? "arrow_upward" : value < 0 ? "arrow_downward" : ""}
      </i>
    </div>
  );
};
