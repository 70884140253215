/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { useEffect, useState } from "react";
import { TopClientsFilter } from "../components/modules/topClients/TopClientsFilter";
import { TopClientsTable } from "../components/modules/topClients/TopClientsTable";
import { Loader } from "../components/common/Loader";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../store/Filter/actions";
import {
  APIFetchMonthlyTopClientsWip,
  APIFetchQuarterlyTopClientsWip,
  APIFetchRolling12MonthsTopClientsWip,
  APIFetchRolling30DaysTopClientsWip,
  APIFetchRolling3MonthsTopClientsWip,
  APIFetchRolling90DaysTopClientsWip,
  APIFetchWeeklyTopClientsWip,
  APIFetchYTDThisYearTopClientsWip,
} from "../api/top-clients-wip";
import HomeButton from "../components/common/HomeButton";

export const TopClients = (props: any) => {
  const dispatch = useDispatch();
  const currentYear = useSelector(
    (state: any) => state.filterReducer.currentYear
  ).toString();
  const [selectedTimeline, setSelectedTimeline] = useState("Weekly");
  const [selectedYear, setSelectedYear] = useState<string>(currentYear);
  const [selectedWeek, setSelectedWeek] = useState<any>();
  const [selectedMonth, setSelectedMonth] = useState<any>();
  const [selectedQuarter, setSelectedQuarter] = useState<any>();
  const [selectedArea, setSelectedArea] = useState("All");
  const [selectedSubarea, setSelectedSubarea] = useState("All");
  const [selectedRegion, setSelectedRegion] = useState("All");
  const [selectedDistrict, setSelectedDistrict] = useState("All");
  const [selectedBranch, setSelectedBranch] = useState("All");
  const [topClientsData, setTopClientsData] = useState<any>([]);
  const [selectedDatamode, setSelectedDatamode] = useState("GrossProfit");
  const [selectedYearMonth, setSelectedYearMonth] = useState<any>({});
  const [exportToggle, setExportToggle] = useState();
  const [acquisitionClient, setAcquisitionClient] = useState(false);
  const [mergeClient, setMergeClient] = useState(false);
  const [selectedReferenceNote, setSelectedReferenceNote] =
    useState<any>("All");
  const [selectedLevel, _setSelectedLevel] = useState(
    props.adaOnly ? "ADA" : "Company"
  );

  const apis: any = {
    Rolling3Months: APIFetchRolling3MonthsTopClientsWip,
    Rolling12Months: APIFetchRolling12MonthsTopClientsWip,
    PreviousRolling3Months: APIFetchRolling3MonthsTopClientsWip,
    PreviousRolling12Months: APIFetchRolling12MonthsTopClientsWip,
    PreviousRolling24Months: APIFetchRolling12MonthsTopClientsWip,
  };

  useEffect(() => {
    setSelectedYear(
      selectedTimeline === "YTDLastYear" ||
        selectedTimeline === "LastYearRolling30Days" ||
        selectedTimeline === "LastYearRolling90Days"
        ? (+currentYear - 1).toString()
        : currentYear
    );
  }, [selectedTimeline]);

  useEffect(() => {
    fetchTopClientsData();
  }, [
    selectedArea,
    selectedSubarea,
    selectedRegion,
    selectedDistrict,
    selectedBranch,
    selectedYear,
    selectedWeek,
    selectedMonth,
    selectedQuarter,
    selectedYearMonth,
    acquisitionClient,
    selectedReferenceNote,
    selectedTimeline,
    selectedLevel,
    mergeClient,
  ]);

  useEffect(() => {
    setSelectedReferenceNote("All");
  }, [acquisitionClient]);

  const fetchTopClientsData = async () => {
    if (!selectedTimeline || !selectedYear) return;
    switch (selectedTimeline) {
      case "Weekly":
        if (selectedWeek) {
          dispatch(setLoading(true));

          const { data } = await APIFetchWeeklyTopClientsWip(
            selectedWeek,
            getSelectedReferenceNote(),
            getSelectedLocation(),
            selectedLevel,
            mergeClient
          );
          data.forEach((d: any) => {
            d.GPRankSlot = d.GPPreviousRank - d.GPThisRank;
            d.RevRankSlot = d.RevPreviousRank - d.RevThisRank;
          });
          setTopClientsData([...data]);
          dispatch(setLoading(false));
        }
        break;
      case "Monthly":
        if (selectedMonth) {
          dispatch(setLoading(true));
          const { data } = await APIFetchMonthlyTopClientsWip(
            selectedYear + selectedMonth,
            getSelectedReferenceNote(),
            getSelectedLocation(),
            selectedLevel,
            mergeClient
          );
          data.forEach((d: any) => {
            d.GPRankSlot = d.GPPreviousRank - d.GPThisRank;
            d.RevRankSlot = d.RevPreviousRank - d.RevThisRank;
          });
          setTopClientsData([...data]);
          dispatch(setLoading(false));
        }
        break;
      case "Quarterly":
        if (selectedQuarter) {
          dispatch(setLoading(true));
          const { data } = await APIFetchQuarterlyTopClientsWip(
            selectedYear + selectedQuarter,
            getSelectedReferenceNote(),
            getSelectedLocation(),
            selectedLevel,
            mergeClient
          );
          data.forEach((d: any) => {
            d.GPRankSlot = d.GPPreviousRank - d.GPThisRank;
            d.RevRankSlot = d.RevPreviousRank - d.RevThisRank;
          });
          setTopClientsData([...data]);
          dispatch(setLoading(false));
        }
        break;
      case "Rolling30Days":
      case "LastYearRolling30Days":
        if (selectedWeek) {
          dispatch(setLoading(true));
          const { data } = await APIFetchRolling30DaysTopClientsWip(
            selectedWeek,
            getSelectedReferenceNote(),
            getSelectedLocation(),
            selectedLevel,
            mergeClient
          );
          data.forEach((d: any) => {
            d.GPRankSlot = d.GPPreviousRank - d.GPThisRank;
            d.RevRankSlot = d.RevPreviousRank - d.RevThisRank;
          });
          setTopClientsData([...data]);
          dispatch(setLoading(false));
        }
        break;
      case "Rolling90Days":
      case "LastYearRolling90Days":
        if (selectedWeek) {
          dispatch(setLoading(true));
          const { data } = await APIFetchRolling90DaysTopClientsWip(
            selectedWeek,
            getSelectedReferenceNote(),
            getSelectedLocation(),
            selectedLevel,
            mergeClient
          );
          data.forEach((d: any) => {
            d.GPRankSlot = d.GPPreviousRank - d.GPThisRank;
            d.RevRankSlot = d.RevPreviousRank - d.RevThisRank;
          });
          setTopClientsData([...data]);
          dispatch(setLoading(false));
        }
        break;
      case "YTD":
      case "YTDLastYear":
        if (selectedWeek) {
          dispatch(setLoading(true));
          const { data } = await APIFetchYTDThisYearTopClientsWip(
            selectedWeek,
            getSelectedReferenceNote(),
            getSelectedLocation(),
            selectedLevel,
            mergeClient
          );
          data.forEach((d: any) => {
            d.GPRankSlot = d.GPPreviousRank - d.GPThisRank;
            d.RevRankSlot = d.RevPreviousRank - d.RevThisRank;
          });
          setTopClientsData([...data]);
          dispatch(setLoading(false));
        }
        break;

      default:
        if (selectedYearMonth[selectedTimeline]) {
          dispatch(setLoading(true));
          const apiList = apis;
          const { data } = await apiList[selectedTimeline](
            selectedYearMonth[selectedTimeline].year,
            selectedYearMonth[selectedTimeline].month,
            getSelectedReferenceNote(),
            getSelectedLocation(),
            selectedLevel,
            mergeClient
          );
          data.forEach((d: any) => {
            d.GPRankSlot = d.GPPreviousRank - d.GPThisRank;
            d.RevRankSlot = d.RevPreviousRank - d.RevThisRank;
          });
          setTopClientsData([...data]);
          dispatch(setLoading(false));
        }
        break;
    }
  };

  const getSelectedLocation = () => {
    return mergeClient
      ? ""
      : selectedBranch === "All"
      ? selectedDistrict === "All"
        ? selectedRegion === "All"
          ? selectedSubarea === "All"
            ? selectedArea === "All"
              ? "All"
              : selectedArea
            : selectedSubarea
          : selectedRegion
        : selectedDistrict
      : selectedBranch;
  };

  const getSelectedReferenceNote = () => {
    return acquisitionClient ? selectedReferenceNote : "";
  };

  return (
    <div className="flex flex-col overflow-hidden h-[100dvh] gap-2 p-5 pt-6">
      <Loader />
      <HomeButton adaOnly={props.adaOnly} />
      <TopClientsFilter
        selectedLevel={selectedLevel}
        selectedYear={selectedYear}
        setSelectedYear={setSelectedYear}
        selectedWeek={selectedWeek}
        setSelectedWeek={setSelectedWeek}
        selectedMonth={selectedMonth}
        setSelectedMonth={setSelectedMonth}
        selectedQuarter={selectedQuarter}
        setSelectedQuarter={setSelectedQuarter}
        selectedArea={selectedArea}
        setSelectedArea={setSelectedArea}
        selectedSubarea={selectedSubarea}
        setSelectedSubarea={setSelectedSubarea}
        selectedRegion={selectedRegion}
        setSelectedRegion={setSelectedRegion}
        selectedDistrict={selectedDistrict}
        setSelectedDistrict={setSelectedDistrict}
        selectedBranch={selectedBranch}
        setSelectedBranch={setSelectedBranch}
        selectedTimeline={selectedTimeline}
        setSelectedTimeline={setSelectedTimeline}
        selectedDatamode={selectedDatamode}
        setSelectedDatamode={setSelectedDatamode}
        selectedYearMonth={selectedYearMonth}
        setSelectedYearMonth={setSelectedYearMonth}
        exportData={setExportToggle}
        wip={props.wip}
        acquisitionClient={acquisitionClient}
        setAcquisitionClient={setAcquisitionClient}
        mergeClient={mergeClient}
        setMergeClient={setMergeClient}
        selectedReferenceNote={selectedReferenceNote}
        setSelectedReferenceNote={setSelectedReferenceNote}
      />
      <TopClientsTable
        data={topClientsData}
        selectedDatamode={selectedDatamode}
        selectedTimeline={selectedTimeline}
        exportData={exportToggle}
        wip={props.wip}
        selectedLevel={selectedLevel}
        mergeClient={mergeClient}
      />
    </div>
  );
};
