import SalesTable from "./SalesTable";
import { groupByKey } from "../../../utils/group";
import GPTable from "./GPTable";

const SalesManagerTables = (props: any) => {
  const {
    filteredGpData,
    selectedYear,
    setSelectedYear,
    branchesList,
    areaSelected,
    subAreaSelected,
    regionSelected,
    districtSelected,
    selectedBranch,
    setSelectedBranch,
  } = props;

  const month: any = {
    January: "1",
    February: "2",
    March: "3",
    April: "4",
    May: "5",
    June: "6",
    July: "7",
    August: "8",
    September: "9",
    October: "10",
    November: "11",
    December: "12",
  };

  const monthName = Object.keys(groupByKey(filteredGpData, "MonthName"));
  const sortedMonth = monthName.sort((a: any, b: any) => month[a] - month[b]);

  return (
    <div className="mx-16 flex flex-col h-[82vh] gap-6">
      <GPTable
        filteredGpData={filteredGpData}
        selectedYear={selectedYear}
        setSelectedYear={setSelectedYear}
        months={sortedMonth}
      />
      {/* <GrossProfit13WeeksTable
        areaSelected={areaSelected}
        subAreaSelected={subAreaSelected}
        regionSelected={regionSelected}
      /> */}
      <SalesTable
        branchesList={branchesList}
        areaSelected={areaSelected}
        subAreaSelected={subAreaSelected}
        regionSelected={regionSelected}
        districtSelected={districtSelected}
        selectedBranch={selectedBranch}
        setSelectedBranch={setSelectedBranch}
      />
    </div>
  );
};

export default SalesManagerTables;
