import { useIsAuthenticated } from "@azure/msal-react";
import { Forbidden } from "../pages/Forbidden";
import AuthenticatedLayout from "../layouts/AuthenticatedLayout";

interface ProtectedRouteType {
  loading: boolean;
  hasAccess: boolean;
  children: any;
}

const ProtectedRoute = ({
  loading,
  hasAccess,
  children,
}: ProtectedRouteType) => {
  const isAuthenticated = useIsAuthenticated();
  if (!isAuthenticated) {
    return <AuthenticatedLayout />;
  }
  if (loading) {
    return <>LOADING PAGE...</>;
  }
  if (!hasAccess) {
    return <Forbidden />;
  }
  return children;
};

export default ProtectedRoute;
