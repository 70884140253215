/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

const over31to60Days: string[] = [
  "Organization",
  "OrganizationID",
  "InvoiceNumber",
  "InvoiceDate",
  "DueDate",
  "InvoiceAmount",
  "PaymentAmount",
  "31-60 Days",
  "Balance",
  "LastInvoicePaymentDate",
  "DO",
];
const over0to31Days: string[] = [
  "Organization",
  "OrganizationID",
  "InvoiceNumber",
  "InvoiceDate",
  "DueDate",
  "InvoiceAmount",
  "PaymentAmount",
  "1-30 Days",
  "Balance",
  "LastInvoicePaymentDate",
  "DO",
];
const current: string[] = [
  "Organization",
  "OrganizationID",
  "InvoiceNumber",
  "InvoiceDate",
  "DueDate",
  "InvoiceAmount",
  "PaymentAmount",
  "Current",
  "Balance",
  "LastInvoicePaymentDate",
  "DO",
];
const over61to90Days: string[] = [
  "Organization",
  "OrganizationID",
  "InvoiceNumber",
  "InvoiceDate",
  "DueDate",
  "InvoiceAmount",
  "PaymentAmount",
  "61-90 Days",
  "Balance",
  "LastInvoicePaymentDate",
  "DO",
];
const over90Days: string[] = [
  "Organization",
  "OrganizationID",
  "InvoiceNumber",
  "InvoiceDate",
  "DueDate",
  "InvoiceAmount",
  "PaymentAmount",
  "Over 90 Days",
  "Balance",
  "LastInvoicePaymentDate",
  "DO",
];
const araging: string[] = [
  "Organization",
  "OrganizationID",
  "InvoiceNumber",
  "InvoiceDate",
  "DueDate",
  "InvoiceAmount",
  "PaymentAmount",
  "Current",
  "1-30 Days",
  "31-60 Days",
  "61-90 Days",
  "Over 90 Days",
  "Balance",
  "LastInvoicePaymentDate",
  "DO",
];

const multiPaymentItems: string[] = [
  "Organization",
  "OrganizationID",
  "InvoiceNumber",
  "InvoiceDate",
  "DueDate",
  "InvoiceAmount",
  "PaymentAmount",
  "Current",
  "Balance",
  "LastInvoicePaymentDate",
  "DO",
];
const clientSummary: string[] = [
  "Organization",
  "OrganizationID",
  // "InvoiceNumber",
  // "InvoiceDate",
  // "DueDate",
  // "InvoiceAmount",
  // "PaymentAmount",
  "Current",
  "1-30 Days",
  "31-60 Days",
  "61-90 Days",
  "Over 90 Days",
  "Balance",
  "LastInvoicePaymentDate",
  "DO",
];

const subTotal: string[] = [
  "Organization",
  // "OrganizationID",
  // "InvoiceNumber",
  // "InvoiceDate",
  // "DueDate",
  "InvoiceAmount",
  "PaymentAmount",
  "Current",
  "1-30 Days",
  "31-60 Days",
  "61-90 Days",
  "Over 90 Days",
  "Balance",
  // "LastInvoicePaymentDate",
  // "DO",
];
export const buttons = [
  "AR Aging",
  "Client Summary",
  "Current",
  "0-30 Days",
  "31-60 Days",
  "61-90 Days",
  "Over 90 Days",
  "Multi Payment Terms",
];
export const headers = [
  { label: "Client Name", value: "Organization" },
  { label: "Client ID", value: "OrganizationID" },
  { label: "Invoice Number", value: "InvoiceNumber" },
  { label: "Invoice Date", value: "InvoiceDate" },
  { label: "Due Date", value: "DueDate" },
  { label: "Invoice Amount", value: "InvoiceAmount" },
  { label: "Payment Amount", value: "PaymentAmount" },
  { label: "Current", value: "Current" },
  { label: "0-30 days", value: "1-30 Days" },
  { label: "31-60 days", value: "31-60 Days" },
  { label: "61-90 days", value: "61-90 Days" },
  { label: "Over 90 days", value: "Over 90 Days" },
  { label: "Balance", value: "Balance" },
  { label: "Last Payment Date", value: "LastInvoicePaymentDate" },
  { label: "DO", value: "DO" },
];

export const displayColumns = (
  activeHeader: string,
  header: string,
  val: string,
  multiSelectItems = []
) => {
  switch (activeHeader) {
    case "Current":
      return current.includes(header) ? val : "";
    case "0-30 Days":
      return over0to31Days.includes(header) ? val : "";
    case "31-60 Days":
      return over31to60Days.includes(header) ? val : "";
    case "61-90 Days":
      return over61to90Days.includes(header) ? val : "";
    case "Over 90 Days":
      return over90Days.includes(header) ? val : "";
    case "Client Summary":
      return clientSummary.includes(header) ? val : "";
    case "SubTotal":
      return subTotal.includes(header) ? val : "";
    case "Multi Payment Terms":
      return [...multiPaymentItems, ...multiSelectItems].includes(header)
        ? val
        : "";
    default:
      return val;
  }
};

export const filterByActiveHeader = (
  activeHeader: string,
  arr: any,
  multiSelect = []
) => {
  switch (activeHeader) {
    case "Current":
      return arr.filter(
        (v: any) => v["Current"] && v["Current"].toString().length > 0
      );
    case "0-30 Days":
      return arr.filter((v: any) => {
        return v["1-30 Days"] && v["1-30 Days"].toString().length > 0;
      });
    case "31-60 Days":
      return arr.filter(
        (v: any) => v["31-60 Days"] && v["31-60 Days"].toString().length > 0
      );
    case "61-90 Days":
      return arr.filter(
        (v: any) => v["61-90 Days"] && v["61-90 Days"].toString().length > 0
      );
    case "Over 90 Days":
      return arr.filter((v: any) => v["Over 90 Days"]);
    case "Multi Payment Terms":
      return arr.filter((arrVal: any) =>
        [...multiSelect].some(
          (v: any) => arrVal[v] && arrVal[v].toString().length > 0
        )
      );
    default:
      return arr;
  }
};

//AR View Headers
export const arViewheaders = [
  { label: "Customer", value: "Customer" },
  { label: "Payment Terms", value: "PaymentTerm" },
  { label: "Credit Limit", value: "Creditlimit" },
  { label: "Sum of 30+", value: "30+" },
  { label: "Sum of 60+", value: "60+" },
  { label: "Sum of Zero To Thirty", value: "Zero To Thirty" },
  { label: "Sum of Thirty One To Sixty", value: "Thirty One To Sixty" },
  { label: "Sum of Sixty One To Ninety", value: "Sixty One To Ninety" },
  { label: "Sum of Over Ninety", value: "Over Ninety" },
  { label: "Sum of Balance", value: "Balance" },
];

export const AR_VIEW_SORTING_HEADERS = [
  "Customer",
  "Sum of 30+",
  "Sum of 60+",
  "Sum of Zero To Thirty",
  "Sum of Thirty One To Sixty",
  "Sum of Sixty One To Ninety",
  "Sum of Over Ninety",
  "Sum of Balance",
];

//Top Clients Headers

const commonHeaders = [
  {
    label: "QBR",
    color: null,
    GrossProfit: "QBR",
    Revenue: "QBR",
  },
  {
    label: "Client Visit",
    color: null,
    GrossProfit: "ClientVisit",
    Revenue: "ClientVisit",
  },
];

export const TopClientsHeaders = {
  Weekly: [
    {
      label: "Branch",
      color: null,
      GrossProfit: "Branch",
      Revenue: "Branch",
    },
    {
      label: "Client Id",
      color: null,
      GrossProfit: "OrganizationID",
      Revenue: "OrganizationID",
      hidden: true,
    },
    {
      label: "Client Name",
      color: null,
      GrossProfit: "clientname",
      Revenue: "clientname",
    },
    ...commonHeaders,
    {
      label: "Rank",
      color: null,
      GrossProfit: "GPThisRank",
      Revenue: "RevThisRank",
    },
    {
      label: "Average Daily Number",
      color: "#90EE90",
      GrossProfit: "avgDailyNumber",
      Revenue: "avgDailyNumber",
    },
    {
      label: "Selected Week",
      color: "#90EE90",
      GrossProfit: "GPThisWeek",
      Revenue: "RevThisWeek",
    },
    {
      label: "Prior Week",
      color: "#D3D3D3",
      GrossProfit: "GPPreviousWeek",
      Revenue: "RevPreviousWeek",
    },
    {
      label: "Selected Week Last Year",
      color: "#D4AAFF",
      GrossProfit: "GPSelectedWeekLastYear",
      Revenue: "RevSelectedWeekLastYear",
    },
    {
      label: "2 Years Ago Selected Week",
      color: "#ADD8E6",
      GrossProfit: "GPSelectedWeek2YearsAgo",
      Revenue: "RevSelectedWeek2YearsAgo",
    },
    {
      label: "Cumulative YTD",
      color: "#90EE90",
      GrossProfit: "GPCumulativeYTD",
      Revenue: "RevCumulativeYTD",
    },
    {
      label: "Cumulative YTD GP Margin",
      color: "#90EE90",
      GrossProfit: "CumulativeYTDGPMargin",
      Revenue: "CumulativeYTDGPMargin",
    },
    {
      label: "Last Year YTD",
      color: "#D4AAFF",
      GrossProfit: "GPLastYearYTD",
      Revenue: "RevLastYearYTD",
    },
    {
      label: "Last Year YTD GP Margin",
      color: "#D4AAFF",
      GrossProfit: "LastYearYTDGPMargin",
      Revenue: "LastYearYTDGPMargin",
    },
    {
      label: "2 Years Ago YTD",
      color: "#ADD8E6",
      GrossProfit: "GPTwoYearsAgoYTD",
      Revenue: "RevTwoYearsAgoYTD",
    },
    {
      label: "2 Years Ago YTD GP Margin",
      color: "#ADD8E6",
      GrossProfit: "TwoYearsAgoYTDGPMargin",
      Revenue: "TwoYearsAgoYTDGPMargin",
    },
    {
      label: "Rank Slot",
      color: "#D3D3D3",
      GrossProfit: "GPRankSlot",
      Revenue: "RevRankSlot",
    },
  ],
  Monthly: [
    {
      label: "Branch",
      color: null,
      GrossProfit: "branch",
      Revenue: "branch",
    },
    {
      label: "Client Id",
      color: null,
      GrossProfit: "OrganizationID",
      Revenue: "OrganizationID",
      hidden: true,
    },
    {
      label: "Client Name",
      color: null,
      GrossProfit: "clientname",
      Revenue: "clientname",
    },
    ...commonHeaders,
    // {
    //   label: "Month Caption",
    //   color: null,
    //   GrossProfit: "MonthCaption",
    //   Revenue: "MonthCaption",
    // },
    {
      label: "Rank",
      color: null,
      GrossProfit: "GPThisRank",
      Revenue: "RevThisRank",
    },
    {
      label: "Average Daily Number",
      color: "#90EE90",
      GrossProfit: "avgDailyNumber",
      Revenue: "avgDailyNumber",
    },
    {
      label: "Selected Month",
      color: "#90EE90",
      GrossProfit: "GPThisMonth",
      Revenue: "RevThisMonth",
    },
    {
      label: "Prior Month",
      color: "#D3D3D3",
      GrossProfit: "GPPreviousMonth",
      Revenue: "RevPreviousMonth",
    },
    {
      label: "Selected Month Last Year",
      color: "#D4AAFF",
      GrossProfit: "GPSelectedMonthLastYear",
      Revenue: "RevSelectedMonthLastYear",
    },
    {
      label: "2 Years Ago Selected Month",
      color: "#ADD8E6",
      GrossProfit: "GPSelectedMonth2YearsAgo",
      Revenue: "RevSelectedMonth2YearsAgo",
    },
    {
      label: "Cumulative YTD",
      color: "#90EE90",
      GrossProfit: "GPCumulativeYTD",
      Revenue: "RevCumulativeYTD",
    },
    {
      label: "Cumulative YTD GP Margin",
      color: "#90EE90",
      GrossProfit: "CumulativeYTDGPMargin",
      Revenue: "CumulativeYTDGPMargin",
    },
    {
      label: "Last Year YTD",
      color: "#D4AAFF",
      GrossProfit: "GPLastYearYTD",
      Revenue: "RevLastYearYTD",
    },
    {
      label: "Last Year YTD GP Margin",
      color: "#D4AAFF",
      GrossProfit: "LastYearYTDGPMargin",
      Revenue: "LastYearYTDGPMargin",
    },
    {
      label: "2 Years Ago YTD",
      color: "#ADD8E6",
      GrossProfit: "GPTwoYearsAgoYTD",
      Revenue: "RevTwoYearsAgoYTD",
    },
    {
      label: "2 Years Ago YTD GP Margin",
      color: "#ADD8E6",
      GrossProfit: "TwoYearsAgoYTDGPMargin",
      Revenue: "TwoYearsAgoYTDGPMargin",
    },
    {
      label: "Rank Slot",
      color: "#D3D3D3",
      GrossProfit: "GPRankSlot",
      Revenue: "RevRankSlot",
    },
    // {
    //   label: "Quad",
    //   color: "#94d2bd",
    //   GrossProfit: "SegmentQuadrant",
    //   Revenue: "SegmentQuadrant",
    // },
  ],
  Quarterly: [
    {
      label: "Branch",
      color: null,
      GrossProfit: "branch",
      Revenue: "branch",
    },
    {
      label: "Client Id",
      color: null,
      GrossProfit: "OrganizationID",
      Revenue: "OrganizationID",
      hidden: true,
    },
    {
      label: "Client Name",
      color: null,
      GrossProfit: "clientname",
      Revenue: "clientname",
    },
    ...commonHeaders,
    // {
    //   label: "Quarter",
    //   color: null,
    //   GrossProfit: "QuarterCaption",
    //   Revenue: "QuarterCaption",
    // },
    {
      label: "Rank",
      color: null,
      GrossProfit: "GPThisRank",
      Revenue: "RevThisRank",
    },
    {
      label: "Average Daily Number",
      color: "#90EE90",
      GrossProfit: "avgDailyNumber",
      Revenue: "avgDailyNumber",
    },
    {
      label: "Selected Quarter",
      color: "#90EE90",
      GrossProfit: "GPThisQuarter",
      Revenue: "RevThisQuarter",
    },
    {
      label: "Prior Quarter",
      color: "#D3D3D3",
      GrossProfit: "GPPreviousQuarter",
      Revenue: "RevPreviousQuarter",
    },
    {
      label: "Selected Quarter Last Year",
      color: "#D4AAFF",
      GrossProfit: "GPSelectedQuarterLastYear",
      Revenue: "RevSelectedQuarterLastYear",
    },
    {
      label: "2 Years Ago Selected Quarter",
      color: "#ADD8E6",
      GrossProfit: "GPSelectedQuarter2YearsAgo",
      Revenue: "RevSelectedQuarter2YearsAgo",
    },
    {
      label: "Cumulative YTD",
      color: "#90EE90",
      GrossProfit: "GPCumulativeYTD",
      Revenue: "RevCumulativeYTD",
    },
    {
      label: "Cumulative YTD GP Margin",
      color: "#90EE90",
      GrossProfit: "CumulativeYTDGPMargin",
      Revenue: "CumulativeYTDGPMargin",
    },
    {
      label: "Last Year YTD",
      color: "#D4AAFF",
      GrossProfit: "GPLastYearYTD",
      Revenue: "RevLastYearYTD",
    },
    {
      label: "Last Year YTD GP Margin",
      color: "#D4AAFF",
      GrossProfit: "LastYearYTDGPMargin",
      Revenue: "LastYearYTDGPMargin",
    },
    {
      label: "2 Years Ago YTD",
      color: "#ADD8E6",
      GrossProfit: "GPTwoYearsAgoYTD",
      Revenue: "RevTwoYearsAgoYTD",
    },
    {
      label: "2 Years Ago YTD GP Margin",
      color: "#ADD8E6",
      GrossProfit: "TwoYearsAgoYTDGPMargin",
      Revenue: "TwoYearsAgoYTDGPMargin",
    },
    {
      label: "Rank Slot",
      color: "#D3D3D3",
      GrossProfit: "GPRankSlot",
      Revenue: "RevRankSlot",
    },
    // {
    //   label: "Quad",
    //   color: "#94d2bd",
    //   GrossProfit: "SegmentQuadrant",
    //   Revenue: "SegmentQuadrant",
    // },
  ],
  Rolling30Days: [
    {
      label: "Branch",
      color: null,
      GrossProfit: "Branch",
      Revenue: "Branch",
    },
    {
      label: "Client Id",
      color: null,
      GrossProfit: "OrganizationID",
      Revenue: "OrganizationID",
      hidden: true,
    },
    {
      label: "Client Name",
      color: null,
      GrossProfit: "Client Name",
      Revenue: "Client Name",
    },
    ...commonHeaders,
    {
      label: "Rank",
      color: null,
      GrossProfit: "GPThisRank",
      Revenue: "RevThisRank",
    },
    {
      label: "Average Daily Number",
      color: "#90EE90",
      GrossProfit: "avgDailyNumber",
      Revenue: "avgDailyNumber",
    },
    {
      label: "Rolling 30 days",
      color: "#90EE90",
      GrossProfit: "SelectedRolling30DaysGrossProfit",
      Revenue: "SelectedRolling30DaysRevenue",
    },
    {
      label: "Prior Rolling 30 days",
      color: "#D3D3D3",
      GrossProfit: "PriorRolling30DaysGrossProfit",
      Revenue: "PriorRolling30DaysRevenue",
    },
    {
      label: "Last Year Rolling 30 days",
      color: "#D4AAFF",
      GrossProfit: "SelectedLastYearRolling30DaysGrossProfit",
      Revenue: "SelectedLastYearRolling30DaysRevenue",
    },
    {
      label: "2 years ago Rolling 30 days",
      color: "#ADD8E6",
      GrossProfit: "TwoYearsAgoSelectedRolling30DaysGrossProfit",
      Revenue: "TwoYearsAgoSelectedRolling30DaysRevenue",
    },
    {
      label: "Cumulative YTD",
      color: "#90EE90",
      GrossProfit: "GPCumulativeYTD",
      Revenue: "RevCumulativeYTD",
    },
    {
      label: "Cumulative YTD GP Margin",
      color: "#90EE90",
      GrossProfit: "CumulativeYTDGPMargin",
      Revenue: "CumulativeYTDGPMargin",
    },
    {
      label: "Last Year YTD",
      color: "#D4AAFF",
      GrossProfit: "GPLastYearYTD",
      Revenue: "RevLastYearYTD",
    },
    {
      label: "Last Year YTD GP Margin",
      color: "#D4AAFF",
      GrossProfit: "LastYearYTDGPMargin",
      Revenue: "LastYearYTDGPMargin",
    },
    {
      label: "2 Years Ago YTD",
      color: "#ADD8E6",
      GrossProfit: "GPTwoYearsAgoYTD",
      Revenue: "RevTwoYearsAgoYTD",
    },
    {
      label: "2 Years Ago YTD GP Margin",
      color: "#ADD8E6",
      GrossProfit: "TwoYearsAgoYTDGPMargin",
      Revenue: "TwoYearsAgoYTDGPMargin",
    },
    {
      label: "Rank Slot",
      color: "#D3D3D3",
      GrossProfit: "GPRankSlot",
      Revenue: "RevRankSlot",
    },
  ],
  Rolling90Days: [
    {
      label: "Branch",
      color: null,
      GrossProfit: "Branch",
      Revenue: "Branch",
    },
    {
      label: "Client Id",
      color: null,
      GrossProfit: "OrganizationID",
      Revenue: "OrganizationID",
      hidden: true,
    },
    {
      label: "Client Name",
      color: null,
      GrossProfit: "Client Name",
      Revenue: "Client Name",
    },
    ...commonHeaders,
    {
      label: "Rank",
      color: null,
      GrossProfit: "GPThisRank",
      Revenue: "RevThisRank",
    },
    {
      label: "Average Daily Number",
      color: "#90EE90",
      GrossProfit: "avgDailyNumber",
      Revenue: "avgDailyNumber",
    },
    {
      label: "Rolling 90 days",
      color: "#90EE90",
      GrossProfit: "SelectedRolling90DaysGrossProfit",
      Revenue: "SelectedRolling90DaysRevenue",
    },
    {
      label: "Prior Rolling 90 days",
      color: "#D3D3D3",
      GrossProfit: "PriorRolling90DaysGrossProfit",
      Revenue: "PriorRolling90DaysRevenue",
    },
    {
      label: "Last Year Rolling 90 days",
      color: "#D4AAFF",
      GrossProfit: "SelectedLastYearRolling90DaysGrossProfit",
      Revenue: "SelectedLastYearRolling90DaysRevenue",
    },
    {
      label: "2 years ago Rolling 90 days",
      color: "#ADD8E6",
      GrossProfit: "TwoYearsAgoSelectedRolling90DaysGrossProfit",
      Revenue: "TwoYearsAgoSelectedRolling90DaysRevenue",
    },
    {
      label: "Cumulative YTD",
      color: "#90EE90",
      GrossProfit: "GPCumulativeYTD",
      Revenue: "RevCumulativeYTD",
    },
    {
      label: "Cumulative YTD GP Margin",
      color: "#90EE90",
      GrossProfit: "CumulativeYTDGPMargin",
      Revenue: "CumulativeYTDGPMargin",
    },
    {
      label: "Last Year YTD",
      color: "#D4AAFF",
      GrossProfit: "GPLastYearYTD",
      Revenue: "RevLastYearYTD",
    },
    {
      label: "Last Year YTD GP Margin",
      color: "#D4AAFF",
      GrossProfit: "LastYearYTDGPMargin",
      Revenue: "LastYearYTDGPMargin",
    },
    {
      label: "2 Years Ago YTD",
      color: "#ADD8E6",
      GrossProfit: "GPTwoYearsAgoYTD",
      Revenue: "RevTwoYearsAgoYTD",
    },
    {
      label: "2 Years Ago YTD GP Margin",
      color: "#ADD8E6",
      GrossProfit: "TwoYearsAgoYTDGPMargin",
      Revenue: "TwoYearsAgoYTDGPMargin",
    },
    {
      label: "Rank Slot",
      color: "#D3D3D3",
      GrossProfit: "GPRankSlot",
      Revenue: "RevRankSlot",
    },
  ],
  YTD: [
    {
      label: "Branch",
      color: null,
      GrossProfit: "Branch",
      Revenue: "Branch",
    },
    {
      label: "Client Id",
      color: null,
      GrossProfit: "OrganizationID",
      Revenue: "OrganizationID",
      hidden: true,
    },
    {
      label: "Client Name",
      color: null,
      GrossProfit: "clientname",
      Revenue: "clientname",
    },
    ...commonHeaders,
    {
      label: "Rank",
      color: null,
      GrossProfit: "GPThisRank",
      Revenue: "RevThisRank",
    },
    {
      label: "Average Daily Number",
      color: "#90EE90",
      GrossProfit: "avgDailyNumber",
      Revenue: "avgDailyNumber",
    },
    {
      label: "Selected Week YTD",
      color: "#90EE90",
      GrossProfit: "GPSelectedWeekYTD",
      Revenue: "RevSelectedWeekYTD",
    },
    {
      label: "Prior Week YTD",
      color: "#D3D3D3",
      GrossProfit: "GPPriorWeekYTD",
      Revenue: "RevPriorWeekYTD",
    },
    {
      label: "Selected Week Last Year YTD",
      color: "#D4AAFF",
      GrossProfit: "GPSelectedWeekLastYearYTD",
      Revenue: "RevSelectedWeekLastYearYTD",
    },
    {
      label: "2 Years Ago Selected Week YTD",
      color: "#ADD8E6",
      GrossProfit: "GPSelectedWeek2YearsAgoYTD",
      Revenue: "RevSelectedWeek2YearsAgoYTD",
    },
    {
      label: "Cumulative YTD",
      color: "#90EE90",
      GrossProfit: "GPCumulativeYTD",
      Revenue: "RevCumulativeYTD",
    },
    {
      label: "Cumulative YTD GP Margin",
      color: "#90EE90",
      GrossProfit: "CumulativeYTDGPMargin",
      Revenue: "CumulativeYTDGPMargin",
    },
    {
      label: "Last Year YTD",
      color: "#D4AAFF",
      GrossProfit: "GPLastYearYTD",
      Revenue: "RevLastYearYTD",
    },
    {
      label: "Last Year YTD GP Margin",
      color: "#D4AAFF",
      GrossProfit: "LastYearYTDGPMargin",
      Revenue: "LastYearYTDGPMargin",
    },
    {
      label: "2 Years Ago YTD",
      color: "#ADD8E6",
      GrossProfit: "GPTwoYearsAgoYTD",
      Revenue: "RevTwoYearsAgoYTD",
    },
    {
      label: "2 Years Ago YTD GP Margin",
      color: "#ADD8E6",
      GrossProfit: "TwoYearsAgoYTDGPMargin",
      Revenue: "TwoYearsAgoYTDGPMargin",
    },
    {
      label: "Rank Slot",
      color: "#D3D3D3",
      GrossProfit: "GPRankSlot",
      Revenue: "RevRankSlot",
    },
  ],
  YTDLastYear: [
    {
      label: "Branch",
      color: null,
      GrossProfit: "Branch",
      Revenue: "Branch",
    },
    {
      label: "Client Id",
      color: null,
      GrossProfit: "OrganizationID",
      Revenue: "OrganizationID",
      hidden: true,
    },
    {
      label: "Client Name",
      color: null,
      GrossProfit: "clientname",
      Revenue: "clientname",
    },
    ...commonHeaders,
    {
      label: "Rank",
      color: null,
      GrossProfit: "GPThisRank",
      Revenue: "RevThisRank",
    },
    {
      label: "Average Daily Number",
      color: "#90EE90",
      GrossProfit: "avgDailyNumber",
      Revenue: "avgDailyNumber",
    },
    {
      label: "Selected Week YTD",
      color: "#90EE90",
      GrossProfit: "GPSelectedWeekYTD",
      Revenue: "RevSelectedWeekYTD",
    },
    {
      label: "Prior Week YTD",
      color: "#D3D3D3",
      GrossProfit: "GPPriorWeekYTD",
      Revenue: "RevPriorWeekYTD",
    },
    {
      label: "Selected Week Last Year YTD",
      color: "#D4AAFF",
      GrossProfit: "GPSelectedWeekLastYearYTD",
      Revenue: "RevSelectedWeekLastYearYTD",
    },
    {
      label: "2 Years Ago Selected Week YTD",
      color: "#ADD8E6",
      GrossProfit: "GPSelectedWeek2YearsAgoYTD",
      Revenue: "RevSelectedWeek2YearsAgoYTD",
    },
    {
      label: "Cumulative YTD",
      color: "#90EE90",
      GrossProfit: "GPCumulativeYTD",
      Revenue: "RevCumulativeYTD",
    },
    {
      label: "Cumulative YTD GP Margin",
      color: "#90EE90",
      GrossProfit: "CumulativeYTDGPMargin",
      Revenue: "CumulativeYTDGPMargin",
    },
    {
      label: "Last Year YTD",
      color: "#D4AAFF",
      GrossProfit: "GPLastYearYTD",
      Revenue: "RevLastYearYTD",
    },
    {
      label: "Last Year YTD GP Margin",
      color: "#D4AAFF",
      GrossProfit: "LastYearYTDGPMargin",
      Revenue: "LastYearYTDGPMargin",
    },
    {
      label: "2 Years Ago YTD",
      color: "#ADD8E6",
      GrossProfit: "GPTwoYearsAgoYTD",
      Revenue: "RevTwoYearsAgoYTD",
    },
    {
      label: "2 Years Ago YTD GP Margin",
      color: "#ADD8E6",
      GrossProfit: "TwoYearsAgoYTDGPMargin",
      Revenue: "TwoYearsAgoYTDGPMargin",
    },
    {
      label: "Rank Slot",
      color: "#D3D3D3",
      GrossProfit: "GPRankSlot",
      Revenue: "RevRankSlot",
    },
  ],
  LastYearRolling30Days: [
    {
      label: "Branch",
      color: null,
      GrossProfit: "Branch",
      Revenue: "Branch",
    },
    {
      label: "Client Id",
      color: null,
      GrossProfit: "OrganizationID",
      Revenue: "OrganizationID",
      hidden: true,
    },
    {
      label: "Client Name",
      color: null,
      GrossProfit: "Client Name",
      Revenue: "Client Name",
    },
    ...commonHeaders,
    {
      label: "Rank",
      color: null,
      GrossProfit: "GPThisRank",
      Revenue: "RevThisRank",
    },
    {
      label: "Average Daily Number",
      color: "#90EE90",
      GrossProfit: "avgDailyNumber",
      Revenue: "avgDailyNumber",
    },
    {
      label: "Rolling 30 days",
      color: "#90EE90",
      GrossProfit: "SelectedRolling30DaysGrossProfit",
      Revenue: "SelectedRolling30DaysRevenue",
    },
    {
      label: "Prior Rolling 30 days",
      color: "#D3D3D3",
      GrossProfit: "PriorRolling30DaysGrossProfit",
      Revenue: "PriorRolling30DaysRevenue",
    },
    {
      label: "Last Year Rolling 30 days",
      color: "#D4AAFF",
      GrossProfit: "SelectedLastYearRolling30DaysGrossProfit",
      Revenue: "SelectedLastYearRolling30DaysRevenue",
    },
    {
      label: "2 years ago Rolling 30 days",
      color: "#ADD8E6",
      GrossProfit: "TwoYearsAgoSelectedRolling30DaysGrossProfit",
      Revenue: "TwoYearsAgoSelectedRolling30DaysRevenue",
    },
    {
      label: "Cumulative YTD",
      color: "#90EE90",
      GrossProfit: "GPCumulativeYTD",
      Revenue: "RevCumulativeYTD",
    },
    {
      label: "Cumulative YTD GP Margin",
      color: "#90EE90",
      GrossProfit: "CumulativeYTDGPMargin",
      Revenue: "CumulativeYTDGPMargin",
    },
    {
      label: "Last Year YTD",
      color: "#D4AAFF",
      GrossProfit: "GPLastYearYTD",
      Revenue: "RevLastYearYTD",
    },
    {
      label: "Last Year YTD GP Margin",
      color: "#D4AAFF",
      GrossProfit: "LastYearYTDGPMargin",
      Revenue: "LastYearYTDGPMargin",
    },
    {
      label: "2 Years Ago YTD",
      color: "#ADD8E6",
      GrossProfit: "GPTwoYearsAgoYTD",
      Revenue: "RevTwoYearsAgoYTD",
    },
    {
      label: "2 Years Ago YTD GP Margin",
      color: "#ADD8E6",
      GrossProfit: "TwoYearsAgoYTDGPMargin",
      Revenue: "TwoYearsAgoYTDGPMargin",
    },
    {
      label: "Rank Slot",
      color: "#D3D3D3",
      GrossProfit: "GPRankSlot",
      Revenue: "RevRankSlot",
    },
  ],
  LastYearRolling90Days: [
    {
      label: "Branch",
      color: null,
      GrossProfit: "Branch",
      Revenue: "Branch",
    },
    {
      label: "Client Id",
      color: null,
      GrossProfit: "OrganizationID",
      Revenue: "OrganizationID",
      hidden: true,
    },
    {
      label: "Client Name",
      color: null,
      GrossProfit: "Client Name",
      Revenue: "Client Name",
    },
    ...commonHeaders,
    {
      label: "Rank",
      color: null,
      GrossProfit: "GPThisRank",
      Revenue: "RevThisRank",
    },
    {
      label: "Average Daily Number",
      color: "#90EE90",
      GrossProfit: "avgDailyNumber",
      Revenue: "avgDailyNumber",
    },
    {
      label: "Rolling 90 days",
      color: "#90EE90",
      GrossProfit: "SelectedRolling90DaysGrossProfit",
      Revenue: "SelectedRolling90DaysRevenue",
    },
    {
      label: "Prior Rolling 90 days",
      color: "#D3D3D3",
      GrossProfit: "PriorRolling90DaysGrossProfit",
      Revenue: "PriorRolling90DaysRevenue",
    },
    {
      label: "Last Year Rolling 90 days",
      color: "#D4AAFF",
      GrossProfit: "SelectedLastYearRolling90DaysGrossProfit",
      Revenue: "SelectedLastYearRolling90DaysRevenue",
    },
    {
      label: "2 years ago Rolling 90 days",
      color: "#ADD8E6",
      GrossProfit: "TwoYearsAgoSelectedRolling90DaysGrossProfit",
      Revenue: "TwoYearsAgoSelectedRolling90DaysRevenue",
    },
    {
      label: "Cumulative YTD",
      color: "#90EE90",
      GrossProfit: "GPCumulativeYTD",
      Revenue: "RevCumulativeYTD",
    },
    {
      label: "Cumulative YTD GP Margin",
      color: "#90EE90",
      GrossProfit: "CumulativeYTDGPMargin",
      Revenue: "CumulativeYTDGPMargin",
    },
    {
      label: "Last Year YTD",
      color: "#D4AAFF",
      GrossProfit: "GPLastYearYTD",
      Revenue: "RevLastYearYTD",
    },
    {
      label: "Last Year YTD GP Margin",
      color: "#D4AAFF",
      GrossProfit: "LastYearYTDGPMargin",
      Revenue: "LastYearYTDGPMargin",
    },
    {
      label: "2 Years Ago YTD",
      color: "#ADD8E6",
      GrossProfit: "GPTwoYearsAgoYTD",
      Revenue: "RevTwoYearsAgoYTD",
    },
    {
      label: "2 Years Ago YTD GP Margin",
      color: "#ADD8E6",
      GrossProfit: "TwoYearsAgoYTDGPMargin",
      Revenue: "TwoYearsAgoYTDGPMargin",
    },
    {
      label: "Rank Slot",
      color: "#D3D3D3",
      GrossProfit: "GPRankSlot",
      Revenue: "RevRankSlot",
    },
  ],
  Rolling3Months: [
    {
      label: "Branch",
      color: null,
      GrossProfit: "Branch",
      Revenue: "Branch",
    },
    {
      label: "Client Id",
      color: null,
      GrossProfit: "OrganizationID",
      Revenue: "OrganizationID",
      hidden: true,
    },
    {
      label: "Client Name",
      color: null,
      GrossProfit: "Client Name",
      Revenue: "Client Name",
    },
    ...commonHeaders,
    // {
    //   label: "Rolling 3 Month Caption",
    //   color: "#D3D3D3",
    //   GrossProfit: "Rolling3MonthCaption",
    //   Revenue: "Rolling3MonthCaption",
    // },
    {
      label: "Rank",
      color: null,
      GrossProfit: "GPThisRank",
      Revenue: "RevThisRank",
    },
    {
      label: "Average Daily Number",
      color: "#90EE90",
      GrossProfit: "AvgDailyNumber",
      Revenue: "AvgDailyNumber",
    },
    {
      label: "Rolling 3 Months",
      color: "#90EE90",
      GrossProfit: "SelectedRolling3MonthsGrossProfit",
      Revenue: "SelectedRolling3MonthsRevenue",
    },
    {
      label: "Prior Rolling 3 Months",
      color: "#D3D3D3",
      GrossProfit: "PriorRolling3monthsGrossProfit",
      Revenue: "PriorRolling3monthsRevenue",
    },
    {
      label: "Last Year Rolling 3 Months",
      color: "#D4AAFF",
      GrossProfit: "SelectedLastYearRolling3MonthsGrossProfit",
      Revenue: "SelectedLastYearRolling3MonthsRevenue",
    },
    {
      label: "2 Years Ago Rolling 3 Months",
      color: "#ADD8E6",
      GrossProfit: "TwoYearsAgoSelectedRolling3MonthsGrossProfit",
      Revenue: "TwoYearsAgoSelectedRolling3MonthsRevenue",
    },
    // {
    //   label: "Rolling 3 Month GP Margin",
    //   color: "#ADD8E6",
    //   GrossProfit: "Rolling3MonthGPMargin",
    //   Revenue: "Rolling3MonthGPMargin",
    // },
    // {
    //   label: "Previous Rolling 3 Month GP Margin",
    //   color: "#90EE90",
    //   GrossProfit: "PreviousRolling3MonthGPMargin",
    //   Revenue: "PreviousRolling3MonthGPMargin",
    // },
    {
      label: "Cumulative YTD",
      color: "#90EE90",
      GrossProfit: "GPCumulativeYTD",
      Revenue: "RevCumulativeYTD",
    },
    {
      label: "Cumulative YTD GP Margin",
      color: "#90EE90",
      GrossProfit: "CumulativeYTDGPMargin",
      Revenue: "CumulativeYTDGPMargin",
    },
    {
      label: "Last Year YTD",
      color: "#D4AAFF",
      GrossProfit: "GPLastYearYTD",
      Revenue: "RevLastYearYTD",
    },
    {
      label: "Last Year YTD GP Margin",
      color: "#D4AAFF",
      GrossProfit: "LastYearYTDGPMargin",
      Revenue: "LastYearYTDGPMargin",
    },
    {
      label: "2 Years Ago YTD",
      color: "#ADD8E6",
      GrossProfit: "GPTwoYearsAgoYTD",
      Revenue: "RevTwoYearsAgoYTD",
    },
    {
      label: "2 Years Ago YTD GP Margin",
      color: "#ADD8E6",
      GrossProfit: "TwoYearsAgoYTDGPMargin",
      Revenue: "TwoYearsAgoYTDGPMargin",
    },
    {
      label: "Rank Slot",
      color: "#D3D3D3",
      GrossProfit: "GPRankSlot",
      Revenue: "RevRankSlot",
    },
    // {
    //   label: "Quad",
    //   color: "#94d2bd",
    //   GrossProfit: "SegmentQuadrant",
    //   Revenue: "SegmentQuadrant",
    // },
  ],
  Rolling12Months: [
    {
      label: "Branch",
      color: null,
      GrossProfit: "Branch",
      Revenue: "Branch",
    },
    {
      label: "Client Id",
      color: null,
      GrossProfit: "OrganizationID",
      Revenue: "OrganizationID",
      hidden: true,
    },
    {
      label: "Client Name",
      color: null,
      GrossProfit: "Client Name",
      Revenue: "Client Name",
    },
    ...commonHeaders,
    {
      label: "Rank",
      color: null,
      GrossProfit: "GPThisRank",
      Revenue: "RevThisRank",
    },
    {
      label: "Average Daily Number",
      color: "#90EE90",
      GrossProfit: "AvgDailyNumber",
      Revenue: "AvgDailyNumber",
    },
    {
      label: "Rolling 12 Months",
      color: "#90EE90",
      GrossProfit: "SelectedRolling12MonthsGrossProfit",
      Revenue: "SelectedRolling12MonthsRevenue",
    },
    {
      label: "Last Year Rolling 12 Months",
      color: "#D4AAFF",
      GrossProfit: "SelectedLastYearRolling12MonthsGrossProfit",
      Revenue: "SelectedLastYearRolling12MonthsRevenue",
    },
    {
      label: "2 Years Ago Rolling 12 Months",
      color: "#ADD8E6",
      GrossProfit: "TwoYearsAgoSelectedRolling12MonthsGrossProfit",
      Revenue: "TwoYearsAgoSelectedRolling12MonthsRevenue",
    },

    {
      label: "Cumulative YTD",
      color: "#90EE90",
      GrossProfit: "GPCumulativeYTD",
      Revenue: "RevCumulativeYTD",
    },
    {
      label: "Cumulative YTD GP Margin",
      color: "#90EE90",
      GrossProfit: "CumulativeYTDGPMargin",
      Revenue: "CumulativeYTDGPMargin",
    },
    {
      label: "Last Year YTD",
      color: "#D4AAFF",
      GrossProfit: "GPLastYearYTD",
      Revenue: "RevLastYearYTD",
    },
    {
      label: "Last Year YTD GP Margin",
      color: "#D4AAFF",
      GrossProfit: "LastYearYTDGPMargin",
      Revenue: "LastYearYTDGPMargin",
    },
    {
      label: "2 Years Ago YTD",
      color: "#ADD8E6",
      GrossProfit: "GPTwoYearsAgoYTD",
      Revenue: "RevTwoYearsAgoYTD",
    },
    {
      label: "2 Years Ago YTD GP Margin",
      color: "#ADD8E6",
      GrossProfit: "TwoYearsAgoYTDGPMargin",
      Revenue: "TwoYearsAgoYTDGPMargin",
    },
    {
      label: "Rank Slot",
      color: "#D3D3D3",
      GrossProfit: "GPRankSlot",
      Revenue: "RevRankSlot",
    },
    // {
    //   label: "Quad",
    //   color: "#94d2bd",
    //   GrossProfit: "SegmentQuadrant",
    //   Revenue: "SegmentQuadrant",
    // },
  ],
  PreviousRolling3Months: [
    {
      label: "Branch",
      color: null,
      GrossProfit: "Branch",
      Revenue: "Branch",
    },
    {
      label: "Client Id",
      color: null,
      GrossProfit: "OrganizationID",
      Revenue: "OrganizationID",
      hidden: true,
    },
    {
      label: "Client Name",
      color: null,
      GrossProfit: "Client Name",
      Revenue: "Client Name",
    },
    ...commonHeaders,

    {
      label: "Rank",
      color: null,
      GrossProfit: "GPThisRank",
      Revenue: "RevThisRank",
    },
    {
      label: "Average Daily Number",
      color: "#90EE90",
      GrossProfit: "AvgDailyNumber",
      Revenue: "AvgDailyNumber",
    },
    {
      label: "Rolling 3 Months",
      color: "#90EE90",
      GrossProfit: "SelectedRolling3MonthsGrossProfit",
      Revenue: "SelectedRolling3MonthsRevenue",
    },
    {
      label: "Prior Rolling 3 Months",
      color: "#D3D3D3",
      GrossProfit: "PriorRolling3monthsGrossProfit",
      Revenue: "PriorRolling3monthsRevenue",
    },
    {
      label: "Last Year Rolling 3 Months",
      color: "#D4AAFF",
      GrossProfit: "SelectedLastYearRolling3MonthsGrossProfit",
      Revenue: "SelectedLastYearRolling3MonthsRevenue",
    },
    {
      label: "2 Years Ago Rolling 3 Months",
      color: "#ADD8E6",
      GrossProfit: "TwoYearsAgoSelectedRolling3MonthsGrossProfit",
      Revenue: "TwoYearsAgoSelectedRolling3MonthsRevenue",
    },

    {
      label: "Cumulative YTD",
      color: "#90EE90",
      GrossProfit: "GPCumulativeYTD",
      Revenue: "RevCumulativeYTD",
    },
    {
      label: "Cumulative YTD GP Margin",
      color: "#90EE90",
      GrossProfit: "CumulativeYTDGPMargin",
      Revenue: "CumulativeYTDGPMargin",
    },
    {
      label: "Last Year YTD",
      color: "#D4AAFF",
      GrossProfit: "GPLastYearYTD",
      Revenue: "RevLastYearYTD",
    },
    {
      label: "Last Year YTD GP Margin",
      color: "#D4AAFF",
      GrossProfit: "LastYearYTDGPMargin",
      Revenue: "LastYearYTDGPMargin",
    },
    {
      label: "2 Years Ago YTD",
      color: "#ADD8E6",
      GrossProfit: "GPTwoYearsAgoYTD",
      Revenue: "RevTwoYearsAgoYTD",
    },
    {
      label: "2 Years Ago YTD GP Margin",
      color: "#ADD8E6",
      GrossProfit: "TwoYearsAgoYTDGPMargin",
      Revenue: "TwoYearsAgoYTDGPMargin",
    },
    {
      label: "Rank Slot",
      color: "#D3D3D3",
      GrossProfit: "GPRankSlot",
      Revenue: "RevRankSlot",
    },
    // {
    //   label: "Quad",
    //   color: "#94d2bd",
    //   GrossProfit: "SegmentQuadrant",
    //   Revenue: "SegmentQuadrant",
    // },
  ],

  PreviousRolling12Months: [
    {
      label: "Branch",
      color: null,
      GrossProfit: "Branch",
      Revenue: "Branch",
    },
    {
      label: "Client Id",
      color: null,
      GrossProfit: "OrganizationID",
      Revenue: "OrganizationID",
      hidden: true,
    },
    {
      label: "Client Name",
      color: null,
      GrossProfit: "Client Name",
      Revenue: "Client Name",
    },
    ...commonHeaders,

    {
      label: "Rank",
      color: null,
      GrossProfit: "GPThisRank",
      Revenue: "RevThisRank",
    },
    {
      label: "Average Daily Number",
      color: "#90EE90",
      GrossProfit: "AvgDailyNumber",
      Revenue: "AvgDailyNumber",
    },
    {
      label: "Rolling 12 Months",
      color: "#90EE90",
      GrossProfit: "SelectedRolling12MonthsGrossProfit",
      Revenue: "SelectedRolling12MonthsRevenue",
    },
    {
      label: "Last Year Rolling 12 Months",
      color: "#D4AAFF",
      GrossProfit: "SelectedLastYearRolling12MonthsGrossProfit",
      Revenue: "SelectedLastYearRolling12MonthsRevenue",
    },
    {
      label: "2 Years Ago Rolling 12 Months",
      color: "#ADD8E6",
      GrossProfit: "TwoYearsAgoSelectedRolling12MonthsGrossProfit",
      Revenue: "TwoYearsAgoSelectedRolling12MonthsRevenue",
    },

    {
      label: "Cumulative YTD",
      color: "#90EE90",
      GrossProfit: "GPCumulativeYTD",
      Revenue: "RevCumulativeYTD",
    },
    {
      label: "Cumulative YTD GP Margin",
      color: "#90EE90",
      GrossProfit: "CumulativeYTDGPMargin",
      Revenue: "CumulativeYTDGPMargin",
    },
    {
      label: "Last Year YTD",
      color: "#D4AAFF",
      GrossProfit: "GPLastYearYTD",
      Revenue: "RevLastYearYTD",
    },
    {
      label: "Last Year YTD GP Margin",
      color: "#D4AAFF",
      GrossProfit: "LastYearYTDGPMargin",
      Revenue: "LastYearYTDGPMargin",
    },
    {
      label: "2 Years Ago YTD",
      color: "#ADD8E6",
      GrossProfit: "GPTwoYearsAgoYTD",
      Revenue: "RevTwoYearsAgoYTD",
    },
    {
      label: "2 Years Ago YTD GP Margin",
      color: "#ADD8E6",
      GrossProfit: "TwoYearsAgoYTDGPMargin",
      Revenue: "TwoYearsAgoYTDGPMargin",
    },
    {
      label: "Rank Slot",
      color: "#D3D3D3",
      GrossProfit: "GPRankSlot",
      Revenue: "RevRankSlot",
    },
    // {
    //   label: "Quad",
    //   color: "#94d2bd",
    //   GrossProfit: "SegmentQuadrant",
    //   Revenue: "SegmentQuadrant",
    // },
  ],
  PreviousRolling24Months: [
    {
      label: "Branch",
      color: null,
      GrossProfit: "Branch",
      Revenue: "Branch",
    },
    {
      label: "Client Id",
      color: null,
      GrossProfit: "OrganizationID",
      Revenue: "OrganizationID",
      hidden: true,
    },
    {
      label: "Client Name",
      color: null,
      GrossProfit: "Client Name",
      Revenue: "Client Name",
    },
    ...commonHeaders,

    {
      label: "Rank",
      color: null,
      GrossProfit: "GPThisRank",
      Revenue: "RevThisRank",
    },
    {
      label: "Average Daily Number",
      color: "#90EE90",
      GrossProfit: "AvgDailyNumber",
      Revenue: "AvgDailyNumber",
    },
    {
      label: "Rolling 12 Months",
      color: "#90EE90",
      GrossProfit: "SelectedRolling12MonthsGrossProfit",
      Revenue: "SelectedRolling12MonthsRevenue",
    },
    {
      label: "Last Year Rolling 12 Months",
      color: "#D4AAFF",
      GrossProfit: "SelectedLastYearRolling12MonthsGrossProfit",
      Revenue: "SelectedLastYearRolling12MonthsRevenue",
    },
    {
      label: "2 Years Ago Rolling 12 Months",
      color: "#ADD8E6",
      GrossProfit: "TwoYearsAgoSelectedRolling12MonthsGrossProfit",
      Revenue: "TwoYearsAgoSelectedRolling12MonthsRevenue",
    },

    {
      label: "Cumulative YTD",
      color: "#90EE90",
      GrossProfit: "GPCumulativeYTD",
      Revenue: "RevCumulativeYTD",
    },
    {
      label: "Cumulative YTD GP Margin",
      color: "#90EE90",
      GrossProfit: "CumulativeYTDGPMargin",
      Revenue: "CumulativeYTDGPMargin",
    },
    {
      label: "Last Year YTD",
      color: "#D4AAFF",
      GrossProfit: "GPLastYearYTD",
      Revenue: "RevLastYearYTD",
    },
    {
      label: "Last Year YTD GP Margin",
      color: "#D4AAFF",
      GrossProfit: "LastYearYTDGPMargin",
      Revenue: "LastYearYTDGPMargin",
    },
    {
      label: "2 Years Ago YTD",
      color: "#ADD8E6",
      GrossProfit: "GPTwoYearsAgoYTD",
      Revenue: "RevTwoYearsAgoYTD",
    },
    {
      label: "2 Years Ago YTD GP Margin",
      color: "#ADD8E6",
      GrossProfit: "TwoYearsAgoYTDGPMargin",
      Revenue: "TwoYearsAgoYTDGPMargin",
    },
    {
      label: "Rank Slot",
      color: "#D3D3D3",
      GrossProfit: "GPRankSlot",
      Revenue: "RevRankSlot",
    },
    // {
    //   label: "Quad",
    //   color: "#94d2bd",
    //   GrossProfit: "SegmentQuadrant",
    //   Revenue: "SegmentQuadrant",
    // },
  ],
};
