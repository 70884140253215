import { RangeSlider, Select, Table } from "@mantine/core";
import { CustomDatePicker } from "../../common/CustomDatePicker";
import { useDisclosure } from "@mantine/hooks";
import { useEffect, useState } from "react";
import moment from "moment";
import { APIFetchSalesTable } from "../../../api/sales-manager";
import { ContentLoader } from "../../common/Loader";
import { getIcon } from "../../../utils/helper/sortIcon";
import { sortByKeyDailyNumbers } from "../../../utils/helper/cellFormatter";

const SalesTable = (props: any) => {
  const {
    branchesList,
    areaSelected,
    subAreaSelected,
    regionSelected,
    districtSelected,
    selectedBranch,
    setSelectedBranch,
  } = props;
  const [data, setData] = useState<any>([]);
  const [sortedData, setSortedData] = useState<any>(data);
  const [totalData, setTotalData] = useState<any>([]);
  const [opened, { open, close }] = useDisclosure();
  const [selectedDateRange, setSelectedDateRange] = useState({
    startdate: moment(new Date()).add("-29", "days").format("yyyy-MM-DD"),
    enddate: moment(new Date()).format("yyyy-MM-DD"),
  });
  const [step, setStep] = useState<[number, number]>([1, 8]);
  const [stepEnd, setStepEnd] = useState<[number, number]>([1, 8]);
  const [totalStepPerc, setTotalStepPerc] = useState<number>(0);
  const [table3Loading, setTable3Loading] = useState<boolean>(false);
  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: string;
  }>({ key: "Total Comments", direction: "desc" });

  const tableHeaders = [
    "Branch",
    "Sales Manager",
    "Sales Cold Call",
    "Sales Prospect",
    "Sales Appointment",
    "Sales Tour",
    "Sales Risk",
    "Sales Proposal",
    "Sales Credit",
    "Sales Won",
    "Step %",
    "Total Comments",
  ];
  const salesHeader: any = {
    1: { label: "Sales Cold Call", color: "bg-[#7ee3a5]" },
    2: { label: "Sales Prospect", color: "bg-[#d2d1d1]" },
    3: { label: "Sales Appointment", color: "bg-[#7ee3a5]" },
    4: { label: "Sales Tour", color: "bg-[#7ee3a5]" },
    5: { label: "Sales Risk", color: "bg-[#d2d1d1]" },
    6: { label: "Sales Proposal", color: "bg-[#7ee3a5]" },
    7: { label: "Sales Credit", color: "bg-[#d2d1d1]" },
    8: { label: "Sales Won", color: "bg-[#d2d1d1]" },
  };

  const fetchData = async () => {
    // dispatch(setLoading(true));
    setTable3Loading(true);
    await APIFetchSalesTable(
      selectedDateRange.startdate,
      selectedDateRange.enddate,
      stepEnd[0],
      stepEnd[1],
      selectedBranch,
      districtSelected
        ? districtSelected
        : regionSelected
        ? regionSelected
        : subAreaSelected
        ? subAreaSelected
        : areaSelected
        ? areaSelected
        : "All"
    ).then((res: any) => {
      setData(res.data);
      setTotalData(
        res.data.reduce(
          (acc: any, curr: any) => {
            acc.Branch = "";
            acc["Sales Manager"] = "";
            acc["Sales Cold Call"] += curr["Sales Cold Call"] ?? 0;
            acc["Sales Prospect"] += curr["Sales Prospect"] ?? 0;
            acc["Sales Appointment"] += curr["Sales Appointment"] ?? 0;
            acc["Sales Tour"] += curr["Sales Tour"] ?? 0;
            acc["Sales Risk"] += curr["Sales Risk"] ?? 0;
            acc["Sales Proposal"] += curr["Sales Proposal"] ?? 0;
            acc["Sales Credit"] += curr["Sales Credit"] ?? 0;
            acc["Sales Won"] += curr["Sales Won"] ?? 0;
            acc["Total Comments"] += curr["Total Comments"] ?? 0;

            return { ...curr, ...acc };
          },
          {
            "Sales Cold Call": 0,
            "Sales Prospect": 0,
            "Sales Appointment": 0,
            "Sales Tour": 0,
            "Sales Risk": 0,
            "Sales Proposal": 0,
            "Sales Credit": 0,
            "Sales Won": 0,
            "Total Comments": 0,
          }
        )
      );
    });
    // dispatch(setLoading(false));
    setTable3Loading(false);
  };

  useEffect(() => {
    fetchData();
  }, [
    areaSelected,
    subAreaSelected,
    regionSelected,
    districtSelected,
    selectedBranch,
    stepEnd,
    selectedDateRange,
  ]);

  useEffect(() => {
    const stepPercentageCalc = () => {
      return (
        (totalData[salesHeader[stepEnd[1]]?.label] /
          totalData[salesHeader[stepEnd[0]]?.label]) *
        100
      );
    };
    setTotalStepPerc(stepPercentageCalc());
  }, [stepEnd, totalData]);

  const maxValue = (key: string) => {
    return Math.max(...data.map((d: any) => d[key]));
  };

  const requestSort = (key: any, direction: any) => {
    if (direction === "asc") {
      const sorted = sortByKeyDailyNumbers(data, key, false);
      setSortedData(sorted);
    } else {
      const sorted = sortByKeyDailyNumbers(data, key, true);
      setSortedData(sorted);
    }
  };

  useEffect(() => {
    requestSort(sortConfig?.key, sortConfig?.direction);
  }, [sortConfig, data]);

  return (
    <div className="flex flex-col gap-2">
      <div className="flex items-center justify-end gap-4 ">
        <div className="flex flex-col text-[0.7rem] font-semibold ">
          <div className="flex items-center gap-1">
            {salesHeader[stepEnd[0]]?.label}
            <i className="material-icons text-blue-400">remove</i>
            {salesHeader[stepEnd[1]]?.label}
          </div>
          <div className="flex items-center justify-center w-full ">
            <div className="border-t-[1px] border-r-[1px] w-5 h-5 border-stone-500 rotate-45 translate-x-[0.7rem] bg-[#e6f1f1]"></div>
            <div className="bg-white text-center p-1 border-y-[1px] border-stone-500 w-20">
              {isNaN(totalStepPerc) ? "0.00" : totalStepPerc.toFixed(2)}%{" "}
            </div>
            <div className="border-t-[1px] border-r-[1px] w-5 h-5 border-stone-500 rotate-45 -translate-x-[0.7rem] bg-white"></div>
          </div>
        </div>
        <div className="flex flex-col gap-1 bg-white shadow-md px-2 py-[0.4rem] rounded-md w-[200px]">
          <div className="text-[0.7rem] font-semibold flex">
            <span className="w-1/2">Step Selection</span>
            <div className="flex flex-grow text-[0.7rem] w-1/2">
              <span className="border-[1px] w-1/2 px-1">{step[0]}</span>
              <span className="border-[1px] w-1/2 px-1">{step[1]}</span>
            </div>
          </div>
          <RangeSlider
            styles={{
              thumb: { borderWidth: "0.1rem", padding: "0.1rem" },
              bar: { height: "4px" },
              track: { height: "4px" },
            }}
            style={{ width: "100%" }}
            minRange={1}
            min={1}
            max={8}
            step={1}
            label={null}
            value={step}
            onChange={setStep}
            onChangeEnd={setStepEnd}
          />
        </div>

        <div className="relative">
          <div className="my-1 ">
            <Select
              id="redeployment-date"
              className="w-[10rem] 2xl:w-[16rem]"
              size="xs"
              data={[]}
              label="Date"
              onClick={() => {
                opened ? close() : open();
              }}
              placeholder={
                selectedDateRange.startdate +
                "  to  " +
                selectedDateRange.enddate
              }
            />
          </div>
          {opened && (
            <div className="absolute z-40 right-0 bottom-10">
              <CustomDatePicker
                branchTopFocus={true}
                close={close}
                selectedDate={selectedDateRange}
                setSelectedDate={setSelectedDateRange}
              />
            </div>
          )}
        </div>
        <Select
          label="Branch"
          data={["All", ...branchesList.sort()]}
          size="xs"
          value={selectedBranch}
          onChange={(value: string) => {
            setSelectedBranch(value);
          }}
          searchable
        />
      </div>
      <div className="h-fit min-h-[220px] overflow-y-auto bg-white w-full relative">
        <Table
          verticalSpacing="1"
          horizontalSpacing={2}
          fontSize={10}
          withColumnBorders
        >
          <thead className="sticky top-0 bg-[#2c90c3] z-20">
            <tr className="border-b-2  ">
              {tableHeaders.map((header: any) => (
                <th
                  style={{
                    color: "white",
                    textAlign:
                      header === "Branch" || header === "Sales Manager"
                        ? "left"
                        : "center",
                    padding: 4,
                  }}
                >
                  <div className="flex items-center">
                    <span className="flex-grow">{header}</span>
                    {header !== "Branch" && header !== "Sales Manager" && (
                      <i
                        className="material-icons cursor-pointer self-end"
                        style={{ fontSize: "14px" }}
                        onClick={() =>
                          setSortConfig({
                            key: header,
                            direction:
                              sortConfig === null ||
                              sortConfig.direction === "desc"
                                ? sortConfig?.key === header
                                  ? "asc"
                                  : "desc"
                                : "desc",
                          })
                        }
                      >
                        {getIcon(header, sortConfig)}
                      </i>
                    )}
                  </div>
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            {sortedData &&
              sortedData.map((d: any) => {
                return (
                  <tr className="font-semibold">
                    <td style={{ minWidth: "80px" }}>{d.Branch}</td>
                    <td style={{ minWidth: "100px" }}>{d["Sales Manager"]}</td>
                    {Object.keys(salesHeader).map((key: any) => {
                      const { label, color } = salesHeader[key];
                      return (
                        <td
                          className="relative"
                          key={key}
                          style={{
                            width: "140px",
                            minWidth: "100px",
                          }}
                        >
                          <div
                            className={`${color} h-4`}
                            style={{
                              width:
                                maxValue(label) === 0
                                  ? 0
                                  : (d[label] / maxValue(label)) * 140,
                            }}
                          >
                            <div className="absolute w-full text-center">
                              {d[label]}
                            </div>
                          </div>
                        </td>
                      );
                    })}
                    <td className="text-center" style={{ minWidth: "80px" }}>
                      {d["Step %"].toFixed(2)}%
                    </td>
                    <td
                      style={{ minWidth: "100px" }}
                      className={`text-center ${
                        d["Highlight"] === 1 ? "bg-[#B7D5B4]" : "bg-[#E7B5B2]"
                      }`}
                    >
                      {d["Total Comments"]}
                    </td>
                  </tr>
                );
              })}
            {totalData && (
              <tr className="bg-stone-100 sticky bottom-0 font-bold border-b-[1px]">
                <td>Total</td>
                <td></td>
                {Object.values(salesHeader).map((d: any) => {
                  return <td className="text-center">{totalData[d.label]}</td>;
                })}
                <td className="text-center"></td>
                <td className="text-center">{totalData["Total Comments"]}</td>
              </tr>
            )}
          </tbody>
        </Table>
        <ContentLoader loading={table3Loading} />
      </div>
    </div>
  );
};

export default SalesTable;
