/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

export const SORTING_HEADERS_WITH_VALUES: any = {
  "Client Name": "Organization",
  Organization: "Organization",
  "Client ID": "OrganizationID",
  "Invoice Number": "InvoiceNumber",
  "Invoice Date": "InvoiceDate",
  "Due Date": "DueDate",
  "Invoice Amount": "InvoiceAmount",
  "Payment Amount": "PaymentAmount",
  Current: "Current",
  "0-30 days": "1-30 Days",
  "31-60 days": "31-60 Days",
  "61-90 days": "61-90 Days",
  "Over 90 days": "Over 90 Days",
  Balance: "Balance",
  "Last Payment Date": "LastInvoicePaymentDate",
  DO: "DO",
  Branch: "Branch",
  AssignedTotal: "AssignedTotal",
  ReassignedTotal: "ReassignedTotal",
};

export const formatCell = (key: number, val: string) => {
  if (!isNaN(+val)) {
    if ([0].includes(key)) {
      val = val === "" ? "" : parseInt(val).toLocaleString();
    }
    if ([1, 2, 5, 6].includes(key)) {
      val =
        +val !== 0 ? "$" + parseFloat((+val).toFixed(0)).toLocaleString() : "";
    }
    if ([3, 7, 8].includes(key)) {
      val = val === "" ? "" : (+val * 100).toFixed(0) + "%";
    }
  }
  return val;
};

export const formatCellFinancial = (val: string, key: number) => {
  if (!isNaN(+val)) {
    if ([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].includes(key)) {
      val =
        +val !== 0
          ? "$" +
            replaceHyphenWithBrackets(
              parseFloat((+val).toFixed(0)).toLocaleString()
            )
          : "$0";
    }
    if ([13, 14, 15].includes(key)) {
      val =
        val === ""
          ? ""
          : replaceHyphenWithBrackets((+val * 100).toFixed(2)) + "%";
    }
  }
  return val;
};

export const formatPercentage = (val: string) => {
  if (!isNaN(+val)) {
    val =
      val === ""
        ? ""
        : val === "Infinity" || val === "-Infinity"
        ? 0 + "%"
        : (+val * 100).toFixed(2) + "%";
  } else {
    val = 0 + "%";
  }
  return val;
};

export const formatPercentValue = (val: string) => {
  if (val === null || val === undefined) {
    return "0%";
  } else if (!isNaN(+val)) {
    val =
      val === ""
        ? ""
        : val === "Infinity" || val === "-Infinity" || !isFinite(+val)
        ? 0 + "%"
        : (parseFloat(val) + Number.EPSILON * 100).toFixed(2) + "%";
  } else {
    val = 0 + "%";
  }
  return val;
};

export const formatGoalPercentValue = (val: string) => {
  if (!isNaN(+val)) {
    val =
      val === ""
        ? ""
        : val === "Infinity" || val === "-Infinity" || !isFinite(+val)
        ? 0 + "%"
        : replaceHyphenWithBrackets(
            (parseFloat(val) + Number.EPSILON * 100).toFixed(2)
          ) + "%";
  } else {
    val = 0 + "%";
  }
  return val;
};

export const formatArAGingCell = (key: number, val: string) => {
  let formattedVal = val;
  if (!isNaN(+formattedVal)) {
    if ([0].includes(key)) {
      formattedVal =
        formattedVal === "" ? "" : parseFloat(formattedVal).toLocaleString();
    }
    if ([5, 6, 7, 8, 9, 10, 11, 12].includes(key)) {
      formattedVal = formatAmount(formattedVal);
    }
  }
  return formattedVal;
};

export const formatAmount = (formattedVal: any, round: boolean = true) =>
  isNaN(formattedVal)
    ? "$0"
    : +formattedVal !== 0
    ? "$" +
      replaceHyphenWithBrackets(
        round
          ? Math.round(parseFloat((+formattedVal).toString())).toLocaleString()
          : formattedVal.toLocaleString()
      )
    : formattedVal === 0 || formattedVal === "0"
    ? "$0"
    : "";

export const replaceHyphenWithBrackets = (val: string) => {
  if (val.includes("-")) {
    val = "(" + val.replace(/-/g, "") + ")";
  }
  return val;
};

export const sortByKey = (array: any, sortVal: any, type?: any) => {
  if (sortVal.type === "Client Name" || sortVal.type === "Organization") {
    return array;
  }
  const keyVal = SORTING_HEADERS_WITH_VALUES[sortVal.type] ?? sortVal.type;

  return array.sort((a: any, b: any) => {
    const x = a[keyVal] ?? 0;
    const y = b[keyVal] ?? 0;
    if (sortVal.isReverse) {
      return x < y ? 1 : x > y ? -1 : 0;
    } else {
      return x < y ? -1 : x > y ? 1 : 0;
    }
  });
};

export const millionConverter = (val: any) =>
  "$" +
  (val > 1000000 ? val / 1000000 : val / 1000).toFixed(2) +
  (val > 1000000 ? "M" : "k");
export const percentConverter = (val: any) => (val * 100).toFixed(2) + "%";
export const percentConverterFixed = (val: any) => (val * 100).toFixed(0) + "%";

export const sortByKeyDailyNumbers = (
  array: any,
  sortVal: any,
  isReverse = false
) => {
  return [...array].sort((a: any, b: any) => {
    const x =
      typeof a[sortVal] === "string" ? a[sortVal].toLowerCase() : a[sortVal];
    const y =
      typeof b[sortVal] === "string" ? b[sortVal].toLowerCase() : b[sortVal];
    if (isReverse) {
      return x < y ? 1 : x > y ? -1 : 0;
    } else {
      return x < y ? -1 : x > y ? 1 : 0;
    }
  });
};

export const sortByKeyCompanySummary = (
  array: any,
  sortVal: any,
  isReverse = false
) => {
  return [...array].sort((a: any, b: any) => {
    const x = +a[sortVal];
    const y = +b[sortVal];
    if (isReverse) {
      return x < y ? 1 : x > y ? -1 : 0;
    } else {
      return x < y ? -1 : x > y ? 1 : 0;
    }
  });
};

export const sortNumberData = (data: any, sortDesc: any) => {
  data.sort((a: any, b: any) =>
    sortDesc === null
      ? 0
      : sortDesc === null
      ? b.total - a.total
      : a.total - b.total
  );

  return data;
};
export const sortStringData = (data: any, sortDesc: any) => {
  data.sort((a: any, b: any) =>
    sortDesc === null
      ? 0
      : sortDesc
      ? b.branch.localeCompare(a.branch)
      : a.branch.localeCompare(b.branch)
  );

  return data;
};
