/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import SummaryChart from "./SummaryChart";
import { sortByKeyCompanySummary } from "../../../utils/helper/cellFormatter";
import ClientSummaryChart from "./ClientSummaryChart";
import { convertDaysIntoDuration } from "./utils/weekdata";
import SummaryChartBranch from "./SummaryChartBranch";
import { useEffect, useState } from "react";
import { APIGetCalendarWeeks3Months } from "../../../api/filter";
import RegionComparisonChart from "./RegionComparisonChart";
import { DATAMODE } from "./utils/constants";

export const CompanySummaryCharts = (props: any) => {
  const [weeksClientData, setWeeksClientData] = useState([]);

  const getRevenue = (val: any) => {
    return val?.length > 0 ? val[0] : null;
  };

  useEffect(() => {
    APIGetCalendarWeeks3Months().then((res: any) =>
      setWeeksClientData(res.data)
    );
  }, []);

  let selectedBranchData = {};
  let selectedBranchManagers: any = [];
  let selectedBranchSalesManagerDays = "";
  let selectedBranchManagerDays = "";
  if (props.showBranchClientsRanking) {
    selectedBranchData =
      props.level === "ADA"
        ? props.adaBranchData[props.selectedBranch]
        : props.branchesData[props.selectedBranch];
    selectedBranchManagers =
      Object.values(selectedBranchData).length > 0
        ? Object.values(selectedBranchData)[0]
        : [];
    selectedBranchSalesManagerDays = convertDaysIntoDuration(
      selectedBranchManagers[0]?.BranchManagerDays ?? ""
    );
    selectedBranchManagerDays = convertDaysIntoDuration(
      selectedBranchManagers[0]?.SalesManagerDays ?? ""
    );
  }

  const getMilestonesData = (location: any) => {
    return (
      [
        ...props.pipMilestonesData.region,
        ...props.pipMilestonesData.district,
        ...props.pipMilestonesData.legacy_branch,
        ...props.pipMilestonesData.new_branch,
      ].find((d) => d.Location === location) ?? null
    );
  };

  const getBranchChartInfo = (branch: string | null) => {
    const branchChartInfo = {
      totalRev:
        props.chartInfo.totalRev?.find((d: any) => d.Branch === branch)?.[
          "2024 Total"
        ] ?? 0,
      goal:
        props.chartInfo.growthPerc?.find((d: any) => d.Branch === branch)?.[
          "2025 Goal"
        ] ?? 0,
      growthPerc:
        props.chartInfo.growthPerc?.find((d: any) => d.Branch === branch)?.[
          "Expected Growth %"
        ] ?? 0,
    };
    return branchChartInfo;
  };

  return (
    <section className="cs-summary-charts">
      {/*Company*/}
      <div className="cs-company-charts">
        {!props.showBranchClientsRanking &&
          props.selectedBranch === "Company" && (
            <div className={"summary-chart-box "} key={"Company0"}>
              <SummaryChart
                sortValue={props.sortValue}
                labels={props.labels}
                dataMode={props.dataMode}
                name={{ label: "Company" }}
                data={props.totalDataValues.Company}
                manager={""}
                revenue={getRevenue([props.totalRevenues?.company])}
                setSelectedBranch={props.setSelectedBranch}
                setLevel={props.setLevel}
                setTotalType={props.setTotalType}
                location={{
                  type: "Company",
                  name: "Company",
                  level: "Company",
                }}
                executive={props.executive}
                wip={props.wip}
                showGoalData={props.showGoalData}
                branchChartInfo={getBranchChartInfo("All")}
              />
            </div>
          )}

        {/*Company without PDQ*/}
        {!(
          props.showBranchClientsRanking ||
          props.showClientsRanking ||
          props.dataMode === DATAMODE.NETINCOME ||
          props.dataMode === DATAMODE.OPERATINGINCOME
        ) &&
          props.selectedBranch === "Company" && (
            <div className={"summary-chart-box "} key={"Company1"}>
              <SummaryChart
                sortValue={props.sortValue}
                labels={props.labels}
                dataMode={props.dataMode}
                name={{ label: "Organic Growth" }}
                data={props.totalDataValues.CompanyWithoutPDQ}
                manager={""}
                revenue={getRevenue([props.totalRevenues?.companyWithoutPDQ])}
                setSelectedBranch={props.setSelectedBranch}
                setLevel={props.setLevel}
                setTotalType={props.setTotalType}
                location={{
                  type: "Company",
                  name: "Company without PDQ",
                  level: "Company",
                }}
                executive={props.executive}
                wip={props.wip}
                showGoalData={props.showGoalData}
                branchChartInfo={getBranchChartInfo("Company without PDQ")}
              />
            </div>
          )}

        {/*Level wise Total*/}
        {!props.showBranchClientsRanking && props.level !== "Company" && (
          <div
            className={`summary-chart-box flex align-end total-chart ${
              props.level === "ADA" ? "ada-style" : ""
            }`}
            key={props.totalType + "total"}
          >
            <SummaryChart
              sortValue={props.sortValue}
              labels={props.labels}
              dataMode={props.dataMode}
              name={{ label: props.totalType + " Total" }}
              data={
                props.totalDataValues?.[props.level]?.[props.selectedBranch] ??
                {}
              }
              manager={
                props.totalDataValues?.[props.level]?.[props.selectedBranch]?.[
                  props.labels[2]
                ]?.length > 0
                  ? props.totalDataValues?.[props.level]?.[
                      props.selectedBranch
                    ]?.[props.labels[2]]?.[0]?.ManagerName
                  : props.totalDataValues?.[props.level]?.[
                      props.selectedBranch
                    ]?.[props.labels[1]]?.[0]?.ManagerName
              }
              revenue={getRevenue(
                props.level === "ADA"
                  ? props.totalRevenuesBox?.[props.level.toLowerCase()]
                  : props.totalRevenuesBox?.[props.level.toLowerCase()]?.[
                      props.selectedBranch
                    ]
              )}
              setSelectedBranch={props.setSelectedBranch}
              setLevel={props.setLevel}
              setTotalType={props.setTotalType}
              location={{
                type: props.totalType,
                name: props.selectedBranch,
                level: props.level,
              }}
              pipMilestonesData={getMilestonesData(props.selectedBranch)}
              pipData={
                props.pipData?.[props.level.toLowerCase()]?.[
                  props.selectedBranch
                ]
              }
              executive={props.executive}
              wip={props.wip}
              showGoalData={props.showGoalData}
              branchChartInfo={getBranchChartInfo(props.selectedBranch)}
            />
          </div>
        )}

        {/*Area level*/}
        {!(props.showClientsRanking || props.showBranchClientsRanking) &&
        Object.keys(props.areaTotalData).length > 0 &&
        props.selectedBranch === "Company"
          ? sortByKeyCompanySummary(
              Object.values(props.totalRevenues.area as any[]).flat(),
              props.sortValue
            ).map((v: any, key: number) => {
              const data: any = props.areaTotalData[v?.AreaName];
              if (!!data) {
                const labelTemp = Object.keys(data);
                return (
                  <div className={"summary-chart-box"} key={"area" + key}>
                    <SummaryChart
                      sortValue={props.sortValue}
                      labels={props.labels}
                      dataMode={props.dataMode}
                      name={{ label: "Company with Historical" }}
                      data={data}
                      manager={data[labelTemp[0]][0].ManagerName}
                      areaManagerDays={data[labelTemp[0]][0].Areamanagerdays}
                      revenue={getRevenue(
                        props.totalRevenues?.area[v?.AreaName]
                      )}
                      setSelectedBranch={props.setSelectedBranch}
                      setLevel={props.setLevel}
                      setTotalType={props.setTotalType}
                      location={{
                        type: "Area",
                        name: v.AreaName,
                        level: "Area",
                      }}
                      sameMaxValue={props.sameMaxValue}
                      executive={props.executive}
                      wip={props.wip}
                      showGoalData={props.showGoalData}
                      branchChartInfo={getBranchChartInfo(v?.AreaName)}
                    />
                  </div>
                );
              } else {
                return "";
              }
            })
          : ""}

        {/*Sub Area level*/}
        {!(props.showClientsRanking || props.showBranchClientsRanking) &&
        Object.keys(props.subareaTotalData).length > 0 &&
        ["Area", "Company"].includes(props.level)
          ? sortByKeyCompanySummary(
              Object.values(props.totalRevenues.subarea as any[]).flat(),
              props.sortValue
            ).map((v: any, key: number) => {
              const data: any = props.subareaTotalData[v["Sub Area Name"]];
              if (!!data && props.subAreaList?.includes(v["Sub Area Name"])) {
                const labelTemp = Object.keys(data);
                return (
                  <div className={"summary-chart-box "} key={"Subarea" + key}>
                    <SummaryChart
                      sortValue={props.sortValue}
                      labels={props.labels}
                      dataMode={props.dataMode}
                      name={{ label: v["Sub Area Name"] ?? "" }}
                      data={data}
                      location={{
                        type: "VP",
                        name: v["Sub Area Name"],
                        level: "Subarea",
                      }}
                      manager={data[labelTemp[0]][0].ManagerName}
                      revenue={getRevenue(
                        props.totalRevenues?.subarea[v["Sub Area Name"]]
                      )}
                      setSelectedBranch={props.setSelectedBranch}
                      setLevel={props.setLevel}
                      setTotalType={props.setTotalType}
                      executive={props.executive}
                      wip={props.wip}
                      showGoalData={props.showGoalData}
                      branchChartInfo={getBranchChartInfo(v["Sub Area Name"])}
                    />
                  </div>
                );
              } else {
                return "";
              }
            })
          : ""}

        {/*Region Comparison*/}
        {!(props.showClientsRanking || props.showBranchClientsRanking) &&
          props.level === "Company" &&
          props.dataMode !== DATAMODE.NETINCOME &&
          props.dataMode !== DATAMODE.OPERATINGINCOME && (
            <div className="w-full mb-4">
              <RegionComparisonChart dataMode={props.dataMode} />
            </div>
          )}

        {/*Region level*/}
        {!(props.showClientsRanking || props.showBranchClientsRanking) &&
        Object.keys({ ...props.regionTotalData }).length > 0 &&
        props.level !== "Region" &&
        props.level !== "District" &&
        props.level !== "ADA"
          ? sortByKeyCompanySummary(
              Object.values({ ...props.totalRevenues.region } as any[]).flat(),
              props.sortValue
            ).map((v: any, key) => {
              const label = v?.Region;
              const data: any = props.regionTotalData[label];
              const region = props.regionsList.find((v: any) => {
                return v.Region === label;
              });
              if (!!data && region) {
                const labelTemp = Object.keys(data);
                return (
                  <div className={"summary-chart-box"} key={"region" + key}>
                    <SummaryChart
                      sortValue={props.sortValue}
                      labels={props.labels}
                      dataMode={props.dataMode}
                      name={{ label: label }}
                      data={data}
                      manager={data[labelTemp[0]][0].ManagerName}
                      regionManagerDays={
                        data[labelTemp[0]][0].Regionmanagerdays
                      }
                      revenue={getRevenue(
                        {
                          ...props.totalRevenues.region,
                          // ...props.totalRevenues.district,
                        }[label]
                      )}
                      setSelectedBranch={props.setSelectedBranch}
                      setLevel={props.setLevel}
                      setTotalType={props.setTotalType}
                      location={{
                        type: "Region",
                        name: label,
                        level: "Region",
                      }}
                      sameMaxValue={props.sameMaxValue}
                      pipMilestonesData={getMilestonesData(label)}
                      pipData={
                        {
                          ...props.pipData?.region,
                          // ...props.pipData?.district,
                        }?.[label]
                      }
                      executive={props.executive}
                      wip={props.wip}
                      showGoalData={props.showGoalData}
                      branchChartInfo={getBranchChartInfo(v?.Region)}
                    />
                  </div>
                );
              } else {
                return "";
              }
            })
          : ""}

        {/*District level*/}
        {!(props.showClientsRanking || props.showBranchClientsRanking) &&
        Object.keys({ ...props.districtTotalData }).length > 0 &&
        props.level !== "District" &&
        props.level !== "ADA"
          ? sortByKeyCompanySummary(
              Object.values({
                ...props.totalRevenues.district,
              } as any[]).flat(),
              props.sortValue
            ).map((v: any, key) => {
              const label = v?.District;
              const data: any = props.districtTotalData[label];
              const district = props.districtList.find((v: any) => {
                return v.District === label;
              });

              if (!!data && district) {
                const labelTemp = Object.keys(data);
                return (
                  <div className={"summary-chart-box"} key={"district" + key}>
                    <SummaryChart
                      sortValue={props.sortValue}
                      labels={props.labels}
                      dataMode={props.dataMode}
                      name={{ label: label }}
                      data={data}
                      manager={
                        data[labelTemp[0]]?.length > 0
                          ? data[labelTemp[0]][0].ManagerName
                          : ""
                      }
                      districtManagerDays={
                        data[labelTemp[0]][0].DistrictManagerDays
                      }
                      revenue={getRevenue(
                        {
                          // ...props.totalRevenues.district,
                          ...props.totalRevenues.district,
                        }[label]
                      )}
                      setSelectedBranch={props.setSelectedBranch}
                      setLevel={props.setLevel}
                      setTotalType={props.setTotalType}
                      location={{
                        type: "Area Branch",
                        name: label,
                        level: "District",
                      }}
                      sameMaxValue={props.sameMaxValue}
                      pipMilestonesData={getMilestonesData(label)}
                      pipData={
                        {
                          ...props.pipData?.district,
                          ...props.pipData?.district,
                        }?.[label]
                      }
                      executive={props.executive}
                      wip={props.wip}
                      showGoalData={props.showGoalData}
                      branchChartInfo={getBranchChartInfo(v?.District)}
                    />
                  </div>
                );
              } else {
                return "";
              }
            })
          : ""}

        {/*Branch level on client ranking */}
        {props.showBranchClientsRanking && (
          <div className={"summary-chart-box "}>
            <SummaryChartBranch
              name={{ label: props.selectedBranch ?? "" }}
              data={
                props.level === "ADA"
                  ? props.adaBranchData[props.selectedBranch]
                  : props.branchesData[props.selectedBranch]
              }
              manager={selectedBranchManagers[0]?.BranchManager ?? "VACANT"}
              dataMode={props.dataMode}
              branchManagerDays={selectedBranchSalesManagerDays}
              labels={props.labels}
              salesManagerDays={selectedBranchManagerDays}
              sortValue={props.sortValue}
              salesManager={selectedBranchManagers[0]?.SalesManager ?? "VACANT"}
              revenue={getRevenue(
                props.level === "ADA"
                  ? props.totalRevenues.ada_branch[props.selectedBranch]
                  : props.totalRevenues.branch[props.selectedBranch]
              )}
              location={{
                name: props.selectedBranch ?? "",
                type: props.level === "ADA" ? "ADA_Branch" : "Branch",
                level: props.level === "ADA" ? props.level : "Branch",
              }}
              pipMilestonesData={getMilestonesData(props.selectedBranch)}
              pipData={props.pipData?.branch?.[props.selectedBranch]}
              executive={props.executive}
              wip={props.wip}
              showGoalData={props.showGoalData}
              branchChartInfo={getBranchChartInfo(props.selectedBranch)}
            />
          </div>
        )}

        {/*Client level*/}
        {(props.showClientsRanking || props.showBranchClientsRanking) &&
          props.clientsData?.length > 0 &&
          props.clientsDetailData &&
          props.clientsData.map((client: any, key: any) => {
            return (
              <div className={"summary-chart-box"} key={"client" + key}>
                <ClientSummaryChart
                  weeksClientData={weeksClientData}
                  name={{ label: client.ClientName, rank: key + 1 }}
                  dataMode={props.clientsDataMode}
                  data={props.clientsDetailData[client.ClientName]}
                  setSelectedBranch={props.setSelectedBranch}
                  setLevel={props.setLevel}
                  setTotalType={props.setTotalType}
                  clientYear={props.clientYear}
                  sameClientRatio={props.sameClientRatio}
                  totalData={
                    props.level === "ADA"
                      ? props.selectedBranch === "ADA_Branch"
                        ? props.totalRevenues.ada[0]
                        : props.totalRevenues?.ada_branch?.[
                            props.selectedBranch
                          ][0]
                      : props.selectedBranch === "Company"
                      ? props.totalRevenues.company
                      : props.totalRevenues?.[props.level.toLowerCase()]?.[
                          props.selectedBranch
                        ][0]
                  }
                  isVMS={client.Label}
                />
              </div>
            );
          })}
      </div>
    </section>
  );
};
