/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import React, { useEffect, useState } from "react";
import {
  APIGetAllClerks,
  APIGetAllClients,
  APIGetAllPaymentTerms,
  APIGetARAssignmentByInvoiceDate,
  APIGetArComments,
  APIGetDSO,
} from "../../../api/ar-aging";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../../store/Filter/actions";
import { ContentLoader, Loader } from "../../common/Loader";
import { Button, Select, Table } from "@mantine/core";
import { groupByKey } from "../../../utils/group";
import {
  formatAmount,
  sortByKey,
  sortByKeyDailyNumbers,
} from "../../../utils/helper/cellFormatter";
import { handleExport } from "../../../utils/helper/dataExport";
import { setSortBy } from "../../../store/ArAging/actions";
import { useMediaQuery } from "@mantine/hooks";
import { LABEL } from "../companySummary/utils/constants";
import moment from "moment";
import { AR_VIEW_SORTING_HEADERS, arViewheaders } from "./utils/displayColumns";
import {
  IArAssignments,
  IClerks,
  IClients,
  IComments,
  IPaymentTerm,
  ISumedARAssignment,
} from "../../../types/araging";

const ArClerkAssignment = (props: { wip?: boolean }) => {
  const dispatch = useDispatch();
  const [clerks, setClerks] = useState<string[]>([]);
  const [paymentTerms, setPaymentTerms] = useState<string[]>([]);
  const [clients, setClients] = useState<string[]>([]);
  const [assignmentsData, setAssignmentsData] = useState<ISumedARAssignment[]>(
    []
  );
  const [commentsData, setCommentsData] = useState<IComments[]>([]);
  const [selectedClerk, setSelecetdClerk] = useState<string>("All");
  const [selectedPaymentTerm, setSelecetdPaymentTerm] = useState<string>("All");
  const [selectedClient, setSelectedClient] = useState<string>("All");
  const [selectedCustomerId, setSelectedCustomerId] = useState("All");
  const [expanded, setExpanded] = useState<any>({});
  const [totalData, setTotalData] = useState<ISumedARAssignment>();
  const [dsoValue, setDsoValue] = useState<number>(0);
  const [isReverseSort, setIsReverseSort] = useState<boolean>(false);
  const [sortedData, setSortedData] =
    useState<ISumedARAssignment[]>(assignmentsData);
  const [date, setDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [commentsLoading, setCommentsLoading] = useState<boolean>(false);

  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: string;
  } | null>({
    key: "Customer",
    direction: "asc",
  });
  const largeScreen = useMediaQuery("(min-width: 768px)");

  const requestSort = (key: any) => {
    let direction = "asc";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "asc"
    ) {
      direction = "desc";
    }
    if (direction === "asc") {
      const sorted = sortByKeyDailyNumbers(assignmentsData, key, false);
      setSortedData(sorted);
    } else {
      const sorted = sortByKeyDailyNumbers(assignmentsData, key, true);
      setSortedData(sorted);
    }
    setSortConfig({ key, direction });
  };

  useEffect(() => {
    loadFilters();
  }, []);

  const loadFilters = async () => {
    const promises = [
      APIGetAllClerks(),
      APIGetAllPaymentTerms(),
      APIGetAllClients(selectedClerk, selectedPaymentTerm),
    ];
    const [clerks, paymentTerms, clients]: any = await Promise.all(promises);
    setClerks(clerks.data.map((c: IClerks) => c.ARClerk ?? "N/A"));
    setPaymentTerms(
      paymentTerms.data.map((p: IPaymentTerm) => p.PaymentTerm ?? "N/A")
    );
    setClients(clients.data.map((c: IClients) => c.customer ?? "N/A"));
  };

  const loadClientsFilter = async () => {
    const clients: any = await APIGetAllClients(
      selectedClerk,
      selectedPaymentTerm
    );
    setClients(clients.data?.map((c: IClients) => c.customer ?? "N/A"));
  };

  useEffect(() => {
    loadClientsFilter();
  }, [selectedClerk, selectedPaymentTerm]);

  const loadAllData = async () => {
    const promises = [
      APIGetARAssignmentByInvoiceDate(
        date,
        selectedClerk,
        selectedPaymentTerm,
        selectedClient
      ),
      APIGetDSO(date, selectedClerk, selectedPaymentTerm, selectedClient),
    ];
    dispatch(setLoading(true));
    const [assignments, dso]: any = await Promise.all(promises);
    const assignmentsGroup = groupByKey(assignments.data, "CustomerKey");
    const aData: ISumedARAssignment[] = sortByKey(
      Object.values(assignmentsGroup).map((value: any) => {
        return {
          ...value.reduce(
            (acc: any, curr: any) => {
              acc["InvoiceDate/Number"] = "Customer Total";
              acc["30+"] += parseFloat(curr["30+"]) ?? 0;
              acc["60+"] += parseFloat(curr["60+"]) ?? 0;
              acc["Zero To Thirty"] += parseFloat(curr["Zero To Thirty"]) ?? 0;
              acc["Thirty One To Sixty"] +=
                parseFloat(curr["Thirty One To Sixty"]) ?? 0;
              acc["Sixty One To Ninety"] +=
                parseFloat(curr["Sixty One To Ninety"]) ?? 0;
              acc["Over Ninety"] += parseFloat(curr["Over Ninety"]) ?? 0;
              acc.Balance += parseFloat(curr.Balance) ?? 0;
              return { ...curr, ...acc };
            },
            {
              "30+": 0,
              "60+": 0,
              "Zero To Thirty": 0,
              "Thirty One To Sixty": 0,
              "Sixty One To Ninety": 0,
              "Over Ninety": 0,
              Balance: 0,
            }
          ),
          data: value,
        };
      }),
      { type: "60+", isReverse: true }
    );

    setTotalData(
      aData.reduce(
        (acc: any, curr: any) => {
          acc.CustomerId = "";
          acc.Customer = "";
          acc["InvoiceDate/Number"] = "Grand Total";
          acc["30+"] += parseFloat(curr["30+"]) ?? 0;
          acc["60+"] += parseFloat(curr["60+"]) ?? 0;
          acc["Zero To Thirty"] += parseFloat(curr["Zero To Thirty"]) ?? 0;
          acc["Thirty One To Sixty"] +=
            parseFloat(curr["Thirty One To Sixty"]) ?? 0;
          acc["Sixty One To Ninety"] +=
            parseFloat(curr["Sixty One To Ninety"]) ?? 0;
          acc["Over Ninety"] += parseFloat(curr["Over Ninety"]) ?? 0;
          acc.Balance += parseFloat(curr.Balance) ?? 0;
          acc.Creditlimit += parseFloat(curr.Creditlimit) ?? 0;
          return { ...curr, ...acc };
        },
        {
          "30+": 0,
          "60+": 0,
          "Zero To Thirty": 0,
          "Thirty One To Sixty": 0,
          "Sixty One To Ninety": 0,
          "Over Ninety": 0,
          Balance: 0,
          Creditlimit: 0,
        }
      )
    );
    setAssignmentsData(aData);
    setSortedData(aData);
    setDsoValue(dso?.data[0]?.DSO ?? 0);
    dispatch(setLoading(false));
  };

  const loadCommentsData = async () => {
    setCommentsLoading(true);
    const comments = await APIGetArComments(
      date,
      selectedClerk,
      selectedPaymentTerm,
      selectedClient
    );
    setCommentsData(comments.data);
    setCommentsLoading(false);
  };

  useEffect(() => {
    loadAllData();
    loadCommentsData();
  }, [selectedClerk, selectedPaymentTerm, selectedClient, date]);

  const changeCollapseState = (customerId: any) => {
    const expand = { ...expanded, [customerId]: !expanded[customerId] };
    setExpanded(expand);
  };

  const selectCustomer = (customerId: any) => {
    setSelectedCustomerId(
      selectedCustomerId === customerId ? "All" : customerId
    );
  };

  const rows = sortedData.map(
    (assignment: ISumedARAssignment, index: number) => {
      return (
        <React.Fragment key={index}>
          <tr
            className={
              selectedCustomerId === assignment.CustomerId ? "bg-gray-300" : ""
            }
          >
            <th>
              <div className="flex items-center font-semibold cursor-pointer">
                <i
                  className="material-icons cursor-pointer"
                  onClick={() => changeCollapseState(assignment.CustomerId)}
                >
                  {expanded[assignment.CustomerId]
                    ? "keyboard_arrow_down"
                    : "keyboard_arrow_right"}
                </i>
                <div className="flex justify-between w-full">
                  <span onClick={() => selectCustomer(assignment.CustomerId)}>
                    {assignment.Customer}
                  </span>
                  {assignment.Label === LABEL.VMS && (
                    <i className="material-icons text-[1rem] ">star_border</i>
                  )}
                </div>
              </div>
            </th>
            <th className="font-semibold" style={{ textAlign: "right" }}>
              {assignment["PaymentTerm"]}
            </th>
            <th className="font-semibold" style={{ textAlign: "right" }}>
              {formatAmount(assignment["Creditlimit"], false)}
            </th>
            <th className="font-semibold" style={{ textAlign: "right" }}>
              {formatAmount(assignment["30+"], false)}
            </th>
            <th className="font-semibold" style={{ textAlign: "right" }}>
              {formatAmount(assignment["60+"], false)}
            </th>
            <th className="font-semibold" style={{ textAlign: "right" }}>
              {formatAmount(assignment["Zero To Thirty"], false)}
            </th>
            <th className="font-semibold" style={{ textAlign: "right" }}>
              {formatAmount(assignment["Thirty One To Sixty"], false)}
            </th>
            <th className="font-semibold" style={{ textAlign: "right" }}>
              {formatAmount(assignment["Sixty One To Ninety"], false)}
            </th>
            <th className="font-semibold" style={{ textAlign: "right" }}>
              {formatAmount(assignment["Over Ninety"], false)}
            </th>
            <th className="font-semibold" style={{ textAlign: "right" }}>
              {formatAmount(assignment["Balance"], false)}
            </th>
          </tr>
          {expanded[assignment.CustomerId] &&
            assignment.data.map((data: IArAssignments) => {
              return (
                <tr>
                  <td className="" style={{ paddingLeft: "3rem" }}>
                    {data["InvoiceDate/Number"]}
                  </td>
                  <td className="text-right"></td>
                  <td className="text-right"></td>
                  <td className="text-right">
                    {formatAmount(data["30+"], false)}
                  </td>
                  <td className="text-right">
                    {formatAmount(data["60+"], false)}
                  </td>
                  <td className="text-right">
                    {formatAmount(data["Zero To Thirty"], false)}
                  </td>
                  <td className="text-right">
                    {formatAmount(data["Thirty One To Sixty"], false)}
                  </td>
                  <td className="text-right">
                    {formatAmount(data["Sixty One To Ninety"], false)}
                  </td>
                  <td className="text-right">
                    {formatAmount(data["Over Ninety"], false)}
                  </td>
                  <td className="text-right">
                    {formatAmount(data["Balance"], false)}
                  </td>
                </tr>
              );
            })}
        </React.Fragment>
      );
    }
  );

  const exportData = () => {
    let excelData: any = [];
    assignmentsData.forEach((assignment: any) => {
      const subData = assignment.data;
      delete assignment.data;
      excelData.push(assignment);
      excelData = [...excelData, ...subData];
    });
    excelData.push(totalData);
    handleExport(
      Object.keys(excelData[0]),
      excelData,
      "xlsx",
      "AR_Clerk_Assignment"
    );
  };
  const sortVal = useSelector((state: any) => state.arAgingReducer.sortBy);
  const getIcon = (label: string) => {
    return sortConfig?.key !== label
      ? "remove"
      : sortConfig?.direction === "asc"
      ? "arrow_drop_down"
      : "arrow_drop_up";
  };
  const changeReverseOrder = (header: string) => {
    if (AR_VIEW_SORTING_HEADERS.includes(header)) {
      dispatch(
        setSortBy({
          type: header,
          isReverse: header === sortVal.type ? !sortVal.isReverse : false,
        })
      );
      if (header === "Customer") {
        setIsReverseSort(!isReverseSort);
      }
    }
  };

  return (
    <div className="h-[90dvh] text-[0.5rem]">
      <Loader />
      {props.wip && (
        <div className="ar-bg-primary flex justify-center mb-1 text-[1rem] md:text-[1.4rem] text-white py-1 font-semibold">
          AR Clerk Assignment
        </div>
      )}

      <div className="py-2 flex flex-col flex-1 gap-4 overflow-hidden">
        <div className="header px-5 flex flex-col sm:flex-row justify-between sm:items-center gap-5">
          <div className="w-full flex gap-5 items-center">
            <div className="w-1/3 sm:max-lg:w-1/5 lg:w-1/6">
              <Select
                size="xs"
                data={clerks}
                label="AR Clerk"
                value={selectedClerk}
                onChange={(v: string) => setSelecetdClerk(v)}
              />
            </div>
            <div className="w-1/3 sm:max-lg:w-1/5 lg:w-1/6">
              <Select
                size="xs"
                data={paymentTerms}
                label="Payment Term"
                value={selectedPaymentTerm}
                onChange={(v: string) => setSelecetdPaymentTerm(v)}
              />
            </div>
            <div className="w-1/3 sm:max-lg:w-1/5 lg:w-1/6">
              <Select
                size="xs"
                searchable
                data={["All", ...clients]}
                label="Client"
                value={selectedClient}
                onChange={(v: string) => setSelectedClient(v)}
              />
            </div>
            <div className="w-1/3 sm:max-lg:w-1/5 lg:w-1/6">
              <div className="flex justify-between align-center">
                <label className="text-xs font-semibold pb-[0.1rem]">
                  As of Date
                </label>
              </div>
              <input
                className="border-[1px] w-full rounded-sm h-7 border-stone-300 px-2 text-xs"
                type="date"
                value={moment(date).format("YYYY-MM-DD")}
                onChange={(e) => setDate(e.target.value)}
                max={moment(new Date()).format("YYYY-MM-DD")}
                pattern="\d{4}-\d{2}-\d{2}"
              />
            </div>
          </div>
          <div className="flex items-center gap-4 ">
            <div className="flex items-center border-[1px] h-7 border-stone-400  gap-1 py-1 px-2 w-[100px]">
              {" "}
              <i className="material-icons text-[1.1rem]">star_border</i>
              <span className="text-[0.5rem] lg:text-[0.6rem] font-semibold">
                VMS Clients
              </span>
            </div>
            <div className="font-semibold text-[0.6] md:text-[0.7rem] ">
              DSO:
              <span className="border-[1px] border-stone-300 px-4 py-[0.4rem] ml-1 rounded-md ">
                {dsoValue?.toFixed(2)}
              </span>
            </div>
            <Button onClick={exportData} size="xs">
              Export Data
            </Button>
          </div>
        </div>
        <div className="assignments h-[58dvh] overflow-y-auto px-2 lg:px-5 text-xs">
          <Table verticalSpacing="1" fontSize={largeScreen ? 12 : 10}>
            <thead className="sticky top-0 bg-white">
              <tr className="bg-blue-50">
                {arViewheaders.map((header, index: number) => (
                  <th
                    className={`${
                      AR_VIEW_SORTING_HEADERS.includes(header.label)
                        ? "pointer"
                        : ""
                    } ${header.label === "Customer" ? "p-1 w-1/6" : ""}
            `}
                    key={index}
                    onClick={() => {
                      changeReverseOrder(header.label);
                      requestSort(header.value);
                    }}
                  >
                    <div
                      className={`flex gap-1 items-center min-w-[100px] ${
                        header.label === "Customer"
                          ? "justify-start"
                          : "justify-end"
                      }`}
                    >
                      {AR_VIEW_SORTING_HEADERS.includes(header.label) && (
                        <i className="material-icons">
                          {getIcon(header.value)}
                        </i>
                      )}
                      {header.label}
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>{rows}</tbody>
            {totalData && (
              <tfoot className="sticky bottom-0 bg-white">
                <tr className="bg-blue-50">
                  <th className="p-1">Total</th>
                  <th style={{ textAlign: "right" }}></th>
                  <th style={{ textAlign: "right" }}></th>
                  <th style={{ textAlign: "right" }}>
                    {formatAmount(totalData["30+"] ?? 0, false)}
                  </th>
                  <th style={{ textAlign: "right" }}>
                    {formatAmount(totalData["60+"] ?? 0, false)}
                  </th>
                  <th style={{ textAlign: "right" }}>
                    {formatAmount(totalData["Zero To Thirty"] ?? 0, false)}
                  </th>
                  <th style={{ textAlign: "right" }}>
                    {formatAmount(totalData["Thirty One To Sixty"] ?? 0, false)}
                  </th>
                  <th style={{ textAlign: "right" }}>
                    {formatAmount(totalData["Sixty One To Ninety"] ?? 0, false)}
                  </th>
                  <th style={{ textAlign: "right" }}>
                    {formatAmount(totalData["Over Ninety"] ?? 0, false)}
                  </th>
                  <th style={{ textAlign: "right" }}>
                    {formatAmount(totalData["Balance"] ?? 0, false)}
                  </th>
                </tr>
              </tfoot>
            )}
          </Table>
        </div>
        <div className="comments flex-1  px-1 lg:px-2 max-h-[26vh] overflow-auto min-h-[20vh] relative mx-4 border-[1px]  rounded-lg shadow-md">
          <ContentLoader loading={commentsLoading} />
          <Table verticalSpacing="1" fontSize={largeScreen ? 12 : 10}>
            <thead className="sticky top-0 bg-white">
              <tr className="border-b-2 ">
                <th className="py-1">Customer</th>
                <th>Date</th>
                <th>Comment</th>
              </tr>
            </thead>
            {commentsData.length > 0 ? (
              <tbody>
                {commentsData.map((comment: IComments, index: number) => {
                  return (
                    <tr key={index}>
                      <td className=" min-w-[240px]">
                        <div className="flex items-center justify-between mr-1">
                          <span>{comment.Customer}</span>
                          {comment.Label === LABEL.VMS && (
                            <i className="material-icons text-[1rem]">
                              star_border
                            </i>
                          )}
                        </div>
                      </td>
                      <td className="min-w-[120px]">{comment.CommentDate}</td>
                      <td>{comment.Comment}</td>
                    </tr>
                  );
                })}
              </tbody>
            ) : (
              <div className="py-5 text-sm">No Comments data available</div>
            )}
          </Table>
        </div>
      </div>
    </div>
  );
};
export default ArClerkAssignment;
