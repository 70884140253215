export const NON_SORTING_HEADERS = [
  "Branch",
  "Client Name",
  "Rank Slot",
  "Month Caption",
  "Quarter",
  "Rolling 3 Month Caption",
  "This Rolling 3 Month Rank",
  "Rolling 12 Month Caption",
  "This Rolling 12 Month Rank",
  "Previous Rolling 3 Month Caption",
  "Previous Rolling 3 Month Rank",
  "Previous Rolling 12 Month Caption",
  "Previous Rolling 12 Month Rank",
  "Previous Rolling 24 Month Caption",
  "Previous Rolling 24 Month Rank",
  "Quad",
  "QBR",
  "Client Visit",
];

export const timelineOptions = [
  { value: "Weekly", label: "Weekly" },
  { value: "Rolling12Months", label: "Rolling 12 Months" },
  { value: "YTD", label: "This Year YTD" },
  { value: "Rolling30Days", label: "Rolling 30 Days" },
  { value: "Rolling90Days", label: "Rolling 90 Days" },
  { value: "Rolling3Months", label: "Rolling 3 Months" },
  { value: "Monthly", label: "Monthly" },
  { value: "PreviousRolling12Months", label: "Last Year Rolling 12 Months" },
  { value: "YTDLastYear", label: "Last Year YTD" },
  { value: "LastYearRolling30Days", label: "Last Year Rolling 30 Days" },
  { value: "LastYearRolling90Days", label: "Last Year Rolling 90 Days" },
  { value: "PreviousRolling3Months", label: "Last Year Rolling 3 Months" },
  { value: "Quarterly", label: "Quarterly" },
  { value: "PreviousRolling24Months", label: "Last Year Rolling 24 Months" },
];
