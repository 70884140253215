/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { GetRequest } from "../plugins/https";

export const APIFetchWeeklyTopClientsWip = (
  selectedWeek: any,
  referenceNote: any,
  location: any,
  selectedLevel: string,
  withoutBranch: boolean
) => {
  return GetRequest(`wip/top-clients/weekly`, {
    params: {
      selectedWeek,
      location,
      referenceNote,
      selectedLevel,
      withoutBranch,
    },
  });
};

export const APIFetchMonthlyTopClientsWip = (
  selectedMonth: any,
  referenceNote: any,
  location: any,
  selectedLevel: string,
  withoutBranch: boolean
) => {
  return GetRequest(`wip/top-clients/monthly`, {
    params: {
      selectedMonth,
      location,
      referenceNote,
      selectedLevel,
      withoutBranch,
    },
  });
};

export const APIFetchQuarterlyTopClientsWip = (
  selectedQuarter: any,
  referenceNote: any,
  location: any,
  selectedLevel: string,
  withoutBranch: boolean
) => {
  return GetRequest(`wip/top-clients/quarterly`, {
    params: {
      selectedQuarter,
      location,
      referenceNote,
      selectedLevel,
      withoutBranch,
    },
  });
};

export const APIFetchRolling30DaysTopClientsWip = async (
  selectedWeek: any,
  referenceNote: any,
  location: any,
  selectedLevel: string,
  withoutBranch: boolean
) => {
  return await GetRequest(`wip/top-clients/rolling30days`, {
    params: {
      selectedWeek,
      location,
      referenceNote,
      selectedLevel,
      withoutBranch,
    },
  });
};

export const APIFetchRolling90DaysTopClientsWip = (
  selectedWeek: any,
  referenceNote: any,
  location: any,
  selectedLevel: string,
  withoutBranch: boolean
) => {
  return GetRequest(`wip/top-clients/rolling90days`, {
    params: {
      selectedWeek,
      location,
      referenceNote,
      selectedLevel,
      withoutBranch,
    },
  });
};

export const APIFetchYTDThisYearTopClientsWip = (
  selectedWeek: any,
  referenceNote: any,
  location: any,
  selectedLevel: string,
  withoutBranch: boolean
) => {
  return GetRequest(`wip/top-clients/ytd`, {
    params: {
      selectedWeek,
      location,
      referenceNote,
      selectedLevel,
      withoutBranch,
    },
  });
};

export const APIFetchRolling3MonthsTopClientsWip = (
  selectedYear: any,
  selectedMonth: any,
  referenceNote: any,
  location: any,
  selectedLevel: string,
  withoutBranch: boolean
) => {
  return GetRequest(`wip/top-clients/rolling3months`, {
    params: {
      selectedYear,
      selectedMonth,
      location,
      referenceNote,
      selectedLevel,
      withoutBranch,
    },
  });
};

export const APIFetchRolling12MonthsTopClientsWip = (
  selectedYear: any,
  selectedMonth: any,
  referenceNote: any,
  location: any,
  selectedLevel: string,
  withoutBranch: boolean
) => {
  return GetRequest(`wip/top-clients/rolling12months`, {
    params: {
      selectedYear,
      selectedMonth,
      location,
      referenceNote,
      selectedLevel,
      withoutBranch,
    },
  });
};

//Line chart data
export const APIFetchWeeklyTopClientsChartWip = (
  selectedClient: string,
  selectedBranch: string,
  withoutBranch: boolean
) => {
  return GetRequest(`wip/top-clients/weekly-chart`, {
    params: { selectedClient, selectedBranch, withoutBranch },
  });
};

export const APIFetchMonthlyTopClientsChartWip = (
  selectedClient: string,
  selectedBranch: string,
  withoutBranch: boolean
) => {
  return GetRequest(`wip/top-clients/monthly-chart`, {
    params: { selectedClient, selectedBranch, withoutBranch },
  });
};

export const APIFetchQuarterlyTopClientsChartWip = (
  selectedClient: string,
  selectedBranch: string,
  withoutBranch: boolean
) => {
  return GetRequest(`wip/top-clients/quarterly-chart`, {
    params: { selectedClient, selectedBranch, withoutBranch },
  });
};
export const APIFetchWeeklyTopClientsChartADA = (
  selectedClient: string,
  selectedBranch: string
) => {
  return GetRequest(`wip/top-clients/weekly-chart-ada`, {
    params: { selectedClient, selectedBranch },
  });
};

export const APIFetchMonthlyTopClientsChartADA = (
  selectedClient: string,
  selectedBranch: string
) => {
  return GetRequest(`wip/top-clients/monthly-chart-ada`, {
    params: { selectedClient, selectedBranch },
  });
};

export const APIFetchQuarterlyTopClientsChartADA = (
  selectedClient: string,
  selectedBranch: string
) => {
  return GetRequest(`wip/top-clients/quarterly-chart-ada`, {
    params: { selectedClient, selectedBranch },
  });
};
